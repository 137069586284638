define("account-berry-frontend/components/select-account/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selectId: 'accountSelect',
    selectClass: "",
    cashAccountSelect: false,
    createAccount: false,
    onlyOneCashAccount: false,
    // didInsertElement() {
    //   $(`#${this.selectId}`).select2();
    // },
    didRender: function didRender() {
      (0, _jquery.default)("#".concat(this.selectId)).select2();
      (0, _jquery.default)(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        (0, _jquery.default)(this).closest(".select2-container").siblings('select:enabled').select2('open');
      }); // steal focus during close - only capture once and stop propogation

      (0, _jquery.default)('select.select2').on('select2:closing', function (e) {
        (0, _jquery.default)(e.target).data("select2").$selection.one('focus focusin', function (e) {
          e.stopPropagation();
        });
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.get('inlineTransaction')) {
        (0, _jquery.default)("#".concat(this.selectId, " option"))[0].selected = true;
        this.verifyCashAccount();
      }
    },
    setDefaultCashAccount: Ember.observer('accounts', function () {
      this.verifyCashAccount();
    }),
    verifyCashAccount: function verifyCashAccount() {
      var accounts = this.accounts;

      if (this.cashAccountSelect && accounts.length === 1) {
        this.send('setAccount', accounts[0].get('id')); // let selectOptions = Ember.$(`#${this.selectId} option`);
        // selectOptions[0].text = accounts[0].get('name');
        // selectOptions[0].value = accounts[0].get('id');
        // selectOptions[0].selected = true;

        this.set('onlyOneCashAccount', true);
      }
    },
    accountId: Ember.computed('account', 'accounts', function () {
      if (this.get('account') && (this.get('account.id') || this.get('account.account_id'))) {
        return this.get('account.account_id') ? this.get('account.account_id').toString() : this.get('account.id').toString();
      }
    }),
    allAccounts: Ember.computed('accounts', function () {
      if (this.get('accounts')) {
        return this.get('accounts');
      }
    }),
    actions: {
      setAccount: function setAccount(value, newAccount) {
        var selectOptions = (0, _jquery.default)("#".concat(this.selectId, " option"));

        switch (value) {
          // Triggers create account modal
          case "new":
            this.send('toggleCreateAccount');
            break;
          // If canceled, return dropdown to default value

          case "cancel":
            selectOptions[0].selected = true;
            break;
          // Set the value option to selected

          default:
            if (newAccount) {
              this.set('accounts', this.store.peekAll('account').toArray());
              this.set('accountId', value.toString());
            } // Send account as third parameter only for journal entry logic


            this.sendAction('setAccount', value, this.index, this.account);
        }
      },
      toggleCreateAccount: function toggleCreateAccount() {
        // Toggle create payee modal
        this.toggleProperty('createAccount');
      },
      cancelCreateAccount: function cancelCreateAccount() {
        this.toggleProperty('createAccount');
        this.send('setAccount', 'cancel');
      }
    }
  });

  _exports.default = _default;
});