define("account-berry-frontend/companies/show/customers/sales-orders/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newSalesOrder: false,
    salesOrderForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedSalesOrders: (0, _pagedArray.default)('filteredSalesOrders', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredSalesOrders', this.store.peekAll('sales-order').filter(function (salesOrder) {
          return !salesOrder.get('isDeleted');
        }));
        this.set('pagedSalesOrders', (0, _pagedArray.default)('filteredSalesOrders', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalSalesOrders: Ember.computed('filteredSalesOrders', function () {
      return this.get('filteredSalesOrders.length');
    }),
    filter: 'so_number',
    filters: [{
      name: "NUMBER",
      value: "so_number"
    }, {
      name: "SALES ORDER DATE",
      value: "so_date"
    }, {
      name: "DUE DATE",
      value: "due_date"
    }, {
      name: "CUSTOMER",
      value: "customer"
    }],
    filteredSalesOrders: Ember.computed('model', function () {
      return this.model;
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("sales-order-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateSalesOrderList(data.sales_order);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var salesOrderId = data.sales_order.id;
          delete data.sales_order.id;

          var salesOrder = _this.store.peekRecord('sales-order', salesOrderId);

          salesOrder.setProperties(data.sales_order);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var salesOrder = _this.store.peekRecord('sales-order', data.sales_order_id);

          salesOrder.deleteRecord();

          _this.set('filteredSalesOrders', _this.store.peekAll('salesOrder').filter(function (salesOrder) {
            return !salesOrder.get('isDeleted');
          }));

          _this.set('pagedSalesOrders', (0, _pagedArray.default)('filteredSalesOrders', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateSalesOrderList: function updateSalesOrderList(salesOrder) {
      this.store.push({
        data: {
          id: salesOrder.id,
          type: 'sales-order',
          attributes: salesOrder
        }
      });
      this.set('filteredSalesOrders', this.store.peekAll('salesOrder').filter(function (salesOrder) {
        return !salesOrder.get('isDeleted');
      }));
      this.set('pagedSalesOrders', (0, _pagedArray.default)('filteredSalesOrders', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filterSalesOrders: Ember.observer('search', 'filterLabelIds', function () {
      var _this2 = this;

      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredSalesOrders', this.model);
      } else {
        this.set('filteredSalesOrders', this.model.filter(function (salesOrder) {
          var salesOrderValue = salesOrder.get(filter);

          if (salesOrderValue) {
            if (isNaN(salesOrderValue)) {
              salesOrderValue = salesOrderValue.toLowerCase();
            }

            if (salesOrderValue.includes(search)) {
              return salesOrder;
            }
          }
        }));
      }

      if (this.get('filterLabelIds') && this.get('filterLabelIds').length > 0) {
        var hasLabel;
        this.set('filteredSalesOrders', this.get('filteredSalesOrders').filter(function (so) {
          hasLabel = false;

          var _iterator = _createForOfIteratorHelper(_this2.get('filterLabelIds')),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var label = _step.value;
              hasLabel = so.get('labels').find(function (l) {
                return l.id.toString() === label.toString();
              });

              if (!hasLabel) {
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (hasLabel) {
            return so;
          }
        }));
      }

      this.set('pagedSalesOrders', (0, _pagedArray.default)('filteredSalesOrders', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Sales Orders</h3>");
      table += "<table id='salesOrdersTable' style='width:100%; font-size: 1vw;'><thead><tr><th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>SALES ORDER DATE</th>";
      table += "<th style='text-align: left;'>DUE DATE</th>";
      table += "<th style='text-align: left;'>CUSTOMER</th>";
      table += "<th style='text-align: center;'>CLOSE</th>";
      table += "<th style='text-align: left;'>BACK ORDERED</th>";
      table += "<th style='text-align: left;'>SOLD</th>";
      table += "<th style='text-align: right'>NET AMOUNT</th>";
      table += "<th style='text-align: right'>SALES TAX AMOUNT</th>";
      table += "<th style='text-align: right'>FREIGHT AMOUNT</th>";
      table += "<th style='text-align: right'>TOTAL AMOUNT</th></tr></thead><tbody>";
      this.get('filteredSalesOrders').forEach(function (salesOrder) {
        table += "<td>".concat(salesOrder.get('so_number'), "</td>");
        table += "<td>".concat(salesOrder.get('so_date'), "</td>");
        table += "<td>".concat(salesOrder.get('due_date'), "</td>");
        table += "<td>".concat(salesOrder.get('customer'), "</td>");
        table += "<td style='text-align: center;'>".concat(salesOrder.get('closed') ? "YES" : "NO", "</td>");
        table += "<td>".concat(salesOrder.get('back_ordered_items'), "</td>");
        table += "<td>".concat(salesOrder.get('sold_items'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(salesOrder.get('net_so_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(salesOrder.get('tax_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(salesOrder.get('freight_amount') ? parseFloat(salesOrder.get('freight_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0.00", "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(salesOrder.get('total_so_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewSalesOrder: function toggleNewSalesOrder() {
        this.set('salesOrder', {});
        this.toggleProperty('newSalesOrder');
        this.toggleProperty('salesOrderForm');
      },
      toggleEditSalesOrder: function toggleEditSalesOrder(salesOrder) {
        this.set('salesOrder', salesOrder);
        this.toggleProperty('salesOrderForm');
      },
      cancelEdit: function cancelEdit() {
        this.salesOrder.rollbackAttributes();
        this.toggleProperty('salesOrderForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('sales_order', id, {
            backgroundReload: false
          }).then(function (salesOrder) {
            self.set('salesOrder', salesOrder);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      close: function close(so) {
        var _this3 = this;

        var orderedItems = so.get('ordered_items');
        so.setProperties({
          'company_id': this.get('companyId'),
          'closed': !so.get('closed')
        });
        this.store.findRecord('salesOrder', so.id, {
          backgroundReload: false
        }).then(function (salesOrder) {
          salesOrder.setProperties(so);
          salesOrder.set('items', orderedItems);
          salesOrder.save().then(function () {
            _this3.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div><strong>Success:</strong>&nbsp<span>Sales Order ".concat(salesOrder.get('closed') ? "Closed" : "Opened", "</span></div>")
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      deleteSalesOrder: function deleteSalesOrder() {
        var _this4 = this;

        this.salesOrder.destroyRecord().then(function () {
          _this4.set('filteredSalesOrders', _this4.store.peekAll('salesOrder').filter(function (salesOrder) {
            return !salesOrder.get('isDeleted');
          }));

          _this4.setProperties({
            pagedSalesOrders: (0, _pagedArray.default)('filteredSalesOrders', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            salesOrder: {},
            confirmDelete: false
          });

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Sales Order Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this4.salesOrder.rollbackAttributes();

          _this4.set('confirmDelete', false);

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedSalesOrders').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredSalesOrders', this.get('filteredSalesOrders').sortBy(property));
        } else {
          this.set('filteredSalesOrders', this.get('filteredSalesOrders').sortBy(property).reverse());
        }

        this.set('pagedSalesOrders', (0, _pagedArray.default)('filteredSalesOrders', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#salesOrdersTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_sales_order_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#salesOrdersTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});