define("account-berry-frontend/components/company-form/component", ["exports", "account-berry-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    newPaymentMethod: false,
    showWhatsIncluded: false,
    showPricing: false,
    stepOne: true,
    imagePreview: null,
    init: function init() {
      this._super();

      if (!this.get('company')) {
        this.set('company', {});
      }
    },
    user: Ember.computed('session.data.authenticated.id', function () {
      return this.store.peekRecord('user', this.get('session.data.authenticated.id'));
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.store.findAll('subscription-plan').then(function (subscriptionPlans) {
        _this2.set('subscriptionPlans', subscriptionPlans);
      });
      (0, _jquery.default)(function () {
        (0, _jquery.default)('[data-toggle="popover"]').popover({
          html: true
        });
      });
    },
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    plans: Ember.computed('subscriptionPlans', 'company.counts', function () {
      var _this3 = this;

      var subscriptionPlans = this.get('subscriptionPlans');
      var plans = [];

      if (subscriptionPlans) {
        subscriptionPlans.forEach(function (plan) {
          switch (plan.get('stripe_plan_id')) {
            case _environment.default.stripe_plan_id.bronze_plan:
              plans.push({
                id: plan.id,
                selected: _this3.get('company.subscription_plan_id') ? _this3.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Bronze Register",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: true
                }, {
                  name: "Up to 2 Users",
                  included: true
                }, {
                  name: "1 Cash/Bank Account, Additional Cash/Bank Account $5",
                  included: true
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: false
                }, {
                  name: "Unlimited Users",
                  included: false
                }, {
                  name: "Unlimited Cash Accounts",
                  included: false
                }, {
                  name: "Label/Job Costing Feature",
                  included: false
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: false
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: false
                }, {
                  name: "Inventory Features",
                  included: false
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: false
                }, {
                  name: "Payroll Features",
                  included: false
                }, {
                  name: "Tax Return features",
                  included: false
                }, {
                  name: "Bank Sync",
                  included: false
                }],
                info: "",
                disabled: _this3.get('company.counts.active_employees_count') > 0,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/bronze_plan.svg")
              });
              break;

            case _environment.default.stripe_plan_id.silver_plan:
              plans.push({
                id: plan.id,
                selected: _this3.get('company.subscription_plan_id') ? _this3.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Silver Business",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: false
                }, {
                  name: "Up to 2 Users",
                  included: false
                }, {
                  name: "1 Cash/Bank Account, Additional Cash/Bank Account $5",
                  included: false
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: true
                }, {
                  name: "Unlimited Users",
                  included: true
                }, {
                  name: "Unlimited Cash Accounts",
                  included: true
                }, {
                  name: "Label/Job Costing Feature",
                  included: false
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: true
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: true
                }, {
                  name: "Inventory Features",
                  included: true
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: false
                }, {
                  name: "Payroll Features",
                  included: false
                }, {
                  name: "Tax Return features",
                  included: false
                }, {
                  name: "Bank Sync",
                  included: false
                }],
                info: "",
                disabled: _this3.get('company.counts.active_employees_count') > 0,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/silver_plan.svg")
              });
              break;

            case _environment.default.stripe_plan_id.gold_plan:
              plans.push({
                id: plan.id,
                selected: _this3.get('company.subscription_plan_id') ? _this3.get('company.subscription_plan_id').toString() === plan.id : false,
                stripePlanId: plan.get('stripe_plan_id'),
                amount: plan.get('amount'),
                title: "Gold Enterprise",
                whatsIncluded: [{
                  name: "Error Prevention Validator",
                  included: true
                }, {
                  name: "Export your data",
                  included: true
                }, {
                  name: "100 Transactions per month",
                  included: false
                }, {
                  name: "Up to 2 Users",
                  included: false
                }, {
                  name: "1 Cash/Bank Account, Additional Cash/Bank Account $5",
                  included: false
                }, {
                  name: "Balance Sheet, Profit & Loss, Statement of Cash Flows, General Ledger & other reports",
                  included: true
                }, {
                  name: "Batch Data Importing",
                  included: true
                }, {
                  name: "Unlimited Transactions",
                  included: true
                }, {
                  name: "Unlimited Users",
                  included: true
                }, {
                  name: "Unlimited Cash Accounts",
                  included: true
                }, {
                  name: "Label/Job Costing Feature",
                  included: true
                }, {
                  name: "Purchase Orders, Bills & Credits, Pay Vendors, Returns & keep track of Accounts Payable aging reports",
                  included: true
                }, {
                  name: "Sales Orders, Invoices, Sales Representatives, Receive Payments & Returns",
                  included: true
                }, {
                  name: "Inventory Features",
                  included: true
                }, {
                  name: "Automatic Vendor Withholding Tracking",
                  included: true
                }, {
                  name: "Payroll Features",
                  included: true
                }, {
                  name: "Tax Return features",
                  included: true
                }, {
                  name: "Bank Sync",
                  included: true
                }],
                info: "+ $1 per Employee**",
                disabled: false,
                img: "".concat(_environment.default.rootURL, "assets/images/logos/gold_plan.svg")
              });
              break;

            default:
              break;
          }
        });
      }

      return plans;
    }),
    actions: {
      setCompanyImage: function setCompanyImage(file) {
        var reader = new FileReader();

        var _this = this;

        reader.onload = function (e) {
          _this.set('imagePreview', e.target.result);
        };

        reader.readAsDataURL(file);
        this.set('company.picture', file);
      },
      setAccountingMethod: function setAccountingMethod(val) {
        this.set('company.accounting_method', val);
      },
      setFirstMonthAccountingYear: function setFirstMonthAccountingYear(val) {
        this.set('company.first_month_accounting_year', val);
      },
      verifyNewCompany: function verifyNewCompany() {
        var _this4 = this;

        var companies = this.store.peekAll('company');
        var existingCompany = companies.find(function (company) {
          return company.get('name') === _this4.get('company.name') || company.get('ein') === _this4.get('company.ein');
        });

        if (existingCompany) {
          this.set('confirmCreate', true);
        } else {
          this.send('create');
        }
      },
      toggleConfirmCreate: function toggleConfirmCreate() {
        this.toggleProperty('confirmCreate');
      },
      toggleShowWhatsIncluded: function toggleShowWhatsIncluded() {
        if (this.get('showWhatsIncluded')) {
          this.$('.pricing-chart').slideUp();
        } else {
          this.$('.pricing-chart').slideDown();
        }

        this.toggleProperty('showWhatsIncluded');
      },
      create: function create() {
        var _this5 = this;

        // If no subscription plan is selected, gold plan is automatically used. 4 is the gold plan id
        var subscriptionPlanId = this.get('company.subscription_plan_id') || this.get('plans').find(function (plan) {
          return plan.stripePlanId === _environment.default.stripe_plan_id.gold_plan;
        }).id;
        this.set('company.subscription_plan_id', subscriptionPlanId);

        if (this.get('company.subscription_plan_id')) {
          var newCompany = this.store.createRecord('company', this.company);
          newCompany.save().then(function (company) {
            _this5.get('applicationController').set('showLoading', true);

            var channel = _this5.get('session.pusher').subscribe("central-channel-".concat(_this5.get('session.data.authenticated.id')));

            channel.bind('new_company', function (response) {
              _this5.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
                _this5.get('applicationController').set('showLoading', false);

                _this5.get('notify').success({
                  html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Company Created</span></div>'
                });

                _this5.sendAction('reloadRoute');

                _this5.get('router').transitionTo('companies.index');
              });
            });

            _this5.set('company', {});
          }, function (response) {
            var errors = "An Error Occured";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            }

            newCompany.rollbackAttributes();

            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }); // this.get('ajaxCall').request(ajaxOptions);
        } else {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>A Subscription Plan needs to be selected to create a company.</span></div>"
          });
        }
      },
      update: function update() {
        var _this = this;

        this.store.findRecord('company', this.company.id, {
          backgroundReload: true
        }).then(function (company) {
          company.setProperties(_this.company);
          company.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Company Updated</span></div>'
            }); // _this.get('router').transitionTo('companies.index');

          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteCompany: function deleteCompany() {
        var _this6 = this;

        this.company.destroyRecord().then(function () {
          _this6.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Company Deleted</span></div>"
          });

          _this6.sendAction('reloadRoute');

          _this6.get('router').transitionTo('companies.index');
        });
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('company')) === "instance") {
          this.get('company').rollbackAttributes();
        }

        this.get('router').transitionTo('companies.index');
      },
      setWhatsIncluded: function setWhatsIncluded(index) {
        var whatsIncluded = this.get('plans')[index].whatsIncluded;
        this.set('whatsIncluded', whatsIncluded);
        this.set('showPlanInfo', true);
      },
      closePlanInfo: function closePlanInfo() {
        this.set('showPlanInfo', false);
      },
      selectPlan: function selectPlan(plan) {
        var _this7 = this;

        if (!plan.disabled) {
          this.get('plans').forEach(function (p) {
            if (p === plan) {
              _this7.set('company.subscription_plan_id', plan.id);

              Ember.set(p, 'selected', true);
            } else {
              Ember.set(p, 'selected', false);
            }
          });
        }
      },
      toggleNewPaymentMethod: function toggleNewPaymentMethod() {
        this.toggleProperty('newPaymentMethod');
      },
      togglePricing: function togglePricing() {
        this.toggleProperty('showPricing');

        if (this.get('showPricing')) {
          (0, _jquery.default)(function () {
            (0, _jquery.default)('[data-toggle="popover"]').popover({
              html: true
            });
          });
        }
      },
      reloadRoute: function reloadRoute() {// this.sendAction('reloadRoute');
      },
      updateCompanyData: function updateCompanyData(company, currentStep, nextStep) {
        for (var k in company) {
          this.set("company.".concat(k), company[k]);
        } // this.set('company', company)


        this.set(currentStep, false);
        this.set(nextStep, true);
      }
    }
  });

  _exports.default = _default;
});