define("account-berry-frontend/components/resizable-columns/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['resizable-column', 'unselectable', 'col-md-8', 'col-md-push-2'],
    didInsertElement: function didInsertElement() {
      var pressed = false;
      var start, startX, startWidth;
      (0, _jquery.default)("table tr").mousedown(function (e) {
        start = (0, _jquery.default)("table th");
        pressed = true;
        startX = e.pageX;
        startWidth = (0, _jquery.default)("table th").width();
        (0, _jquery.default)(start).addClass("resizing");
        (0, _jquery.default)('table').addClass("resize");
      });
      (0, _jquery.default)(document).mousemove(function (e) {
        if (pressed) {
          (0, _jquery.default)(start).width(startWidth + (e.pageX - startX));
        }
      });
      (0, _jquery.default)(document).mouseup(function () {
        if (pressed) {
          (0, _jquery.default)(start).removeClass("resizing");
          pressed = false;
        }
      });
    }
  });

  _exports.default = _default;
});