define("account-berry-frontend/companies/show/items/transactions/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _jquery, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    sortAscending: true,
    pagedTransactions: (0, _pagedArray.default)('filteredTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', 'transactions', function () {
      var _this = this;

      if (this.get('model')) {
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.get('companyId'), "/items/").concat(this.get('model.id'), "/transactions"),
          method: 'GET',
          contentType: 'application/json',
          successCallback: function successCallback(response) {
            _this.set('filteredTransactions', response.transactions);
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }),
    totalTransactions: Ember.computed.alias('filteredTransactions.length'),
    filteredTransactionsObserver: Ember.observer('filteredTransactions', function () {
      if (this.get('filteredTransactions')) {
        try {
          this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        } catch (err) {
          Ember.Logger.error(err);
        }
      }
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center'>".concat(this.model.get('name'), " Transactions</h3>");
      table += "<table id='itemTransactionsTable' style='width:100%; font-size: 14px'>";
      table += "<thead><tr><th style='text-align: left'>DATE</th>";
      table += "<th style='text-align: left'>TYPE</th>";
      table += "<th style='text-align: left'>NUMBER</th>";
      table += "<th style='text-align: left'>NAME</th>";
      table += "<th style='text-align: center'>ON HAND</th>";
      table += "<th style='text-align: center'>ORDERED</th>";
      table += "<th style='text-align: center'>AVAILABLE</th></tr></thead><tbody>";
      this.get('filteredTransactions').forEach(function (transaction) {
        table += "<tr><td style='text-align: left'>".concat(moment(transaction.date).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: left'>".concat(transaction.type, "</td>");
        table += "<td style='text-align: left'>".concat(transaction.number ? transaction.number : '', "</td>");
        table += "<td style='text-align: left'>".concat(transaction.name, "</td>");
        table += "<td style='text-align: center'>".concat(transaction.on_hand !== null ? transaction.on_hand : '', "</td>");
        table += "<td style='text-align: center'>".concat(transaction.ordered !== null ? transaction.ordered : '', "</td>");
        table += "<td style='text-align: center'>".concat(transaction.available !== null ? transaction.available : '', "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property));
        } else {
          this.set('filteredTransactions', this.get('filteredTransactions').sortBy(property).reverse());
        }

        this.set('pagedTransactions', (0, _pagedArray.default)('filteredTransactions', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      loadNext: function loadNext() {
        this.get('pagedTransactions').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#itemTransactionsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('model.company_id')).get('name'), "_").concat(Ember.String.underscore(this.get('model.name')), "_transactions_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#itemTransactionsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});