define("account-berry-frontend/companies/show/reports/ar-aging-summary/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    daysRange: 30,
    dueDate: true,
    showFullDetail: false,
    hideCleanBalance: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getArAgingSummary: function getArAgingSummary() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date,
            due_date: this.dueDate,
            days_range: this.daysRange
          },
          report_type: 'ar_aging_summary'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ArAgingSummary" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ArAgingSummary');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('arAgingSummaryData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ArAgingSummary disconnected");
          }
        });
      },
      getFullDetail: function getFullDetail(customerId, daysStart, daysEnd) {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            customer_id: customerId,
            date: this.get('date'),
            due_date: this.get('dueDate'),
            days_start: daysStart,
            days_end: daysEnd
          },
          report_type: 'ar_aging_summary_detail'
        });

        var _this$get2 = this.get('session.data.authenticated'),
            email = _this$get2.email,
            token = _this$get2.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ArAgingSummaryDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ArAgingSummaryDetail');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('arAgingSummaryDetailData', data.report);

              _this3.set('showFullDetail', true);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ArAgingSummaryDetail disconnected");
          }
        });
      },
      setDaysRange: function setDaysRange(daysRange) {
        this.set('daysRange', daysRange);

        if (this.date) {
          this.send('getArAgingSummary');
        }
      },
      setDateType: function setDateType(dueDate) {
        this.set('dueDate', dueDate.length > 0 ? true : false);

        if (this.date) {
          this.send('getArAgingSummary');
        }
      },
      print: function print() {
        (0, _jquery.default)('#arAgingSummary').printThis();
      },
      csvExport: function csvExport() {
        var table = "<table id='arAgingSummaryTable' style='width:100%; font-size: 14px'><thead>";
        table += "<tr><th class='small-report-column'></th>";
        table += "<th class='table-amount text-center'>CURRENT</th>";
        table += "<th class='table-amount text-center'>0 - ".concat(this.daysRange, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange + 1, " - ").concat(this.daysRange * 2, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange * 2 + 1, " - ").concat(this.daysRange * 3, "</th>");
        table += "<th class='table-amount text-center'>".concat(this.daysRange * 3 + 1, " ></th>");
        table += "<th class='table-amount text-center'>TOTAL</th></tr></thead><tbody>";
        this.get('arAgingSummaryData.customers').forEach(function (customer) {
          table += "<tr><td class='small-report-column'>".concat(customer.name, "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.current).toFixed(2), "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.first_column).toFixed(2), "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.second_column).toFixed(2), "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.third_column).toFixed(2), "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.fourth_column).toFixed(2), "</td>");
          table += "<td class='small-report-column'>".concat(parseFloat(customer.total).toFixed(2), "</td></tr>");
        });
        table += "<tr><td class='small-report-column'>TOTAL</td>";
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.current')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.first_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.second_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.third_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.fourth_column')).toFixed(2), "</td>");
        table += "<td class='table-amount'>".concat(parseFloat(this.get('arAgingSummaryData.totals.total')).toFixed(2), "</td></tr></tbody></table>");
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#arAgingSummaryTable').table2CSV({
          delivery: 'download',
          filename: 'ArAgingSummaryTable.csv'
        });
        (0, _jquery.default)('#arAgingSummaryTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      },
      back: function back() {
        this.set('showFullDetail', false);
      }
    }
  });

  _exports.default = _default;
});