define("account-berry-frontend/companies/show/checks/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "ember-cli-pagination/remote/paged-remote-array"], function (_exports, _jquery, _pagedArray, _pagedRemoteArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedChecks: (0, _pagedArray.default)("checks", {
      infinite: true
    }),
    filter: "check_number",
    filters: Ember.computed(function () {
      return [{
        name: "NUMBER",
        value: "check_number"
      }, {
        name: "PAYEE NAME",
        value: "payee_name"
      }, {
        name: "AMOUNT",
        value: "amount"
      }];
    }),
    checks: Ember.computed('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      return this.get('model');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("check-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          if (_this2.get('checks')) {
            _this2.send('addCheckToList', data.check);
          }
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var checkId = data.check.id;
          delete data.check.id;

          var check = _this2.store.peekRecord('check', checkId);

          check.setProperties(data.check);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          if (_this2.get('checks')) {
            var check = _this2.store.peekRecord('check', data.check.id);

            check.deleteRecord();

            _this2.get('pagedChecks.content').removeObject(data.check);

            _this2.setProperties({
              'checks.meta.total_checks': _this2.get('checks.meta.total_checks') - 1,
              'checks.meta.total_amount': parseFloat(_this2.get('checks.meta.total_amount')) - check.get('amount')
            });
          }
        }
      });
      this.set('subscribedToChannel', true);
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    paymentPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    generateTablePrint: function generateTablePrint(checks) {
      var tableDraw = "<div style=\"font-family: Verdana\"><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>Checks</h3>");
      tableDraw = "<table id='checksTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>NUMBER</th>";
      tableDraw += "<th style='text-align: left;'>DATE</th>";
      tableDraw += "<th style='text-align: left;'>ACCOUNT</th>";
      tableDraw += "<th style='text-align: left;'>PAYEE NAME</th>";
      tableDraw += "<th style='text-align: left;'>MEMO</th>";
      tableDraw += "<th style='text-align: left;'>BANK ACCOUNT</th>";
      tableDraw += "<th style='text-align: right'>AMOUNT</th></tr></thead>";
      tableDraw += "<tbody>";
      checks.forEach(function (check) {
        tableDraw += "<tr>";
        var isInstance = Ember.typeOf(check) === "instance";
        var number = isInstance ? check.get('check_number') : check.check_number;
        var date = isInstance ? moment(check.get('check_date')).format('MM/DD/YYYY') : moment(check.check_date).format('MM/DD/YYYY');
        var accountName = isInstance ? check.get('transaction_account.name') : check.account_checks.find(function (account) {
          return !account.is_cash_account;
        }).name;
        var payeeName = isInstance ? check.get('payee_name') : check.payee_name;
        var memo = isInstance ? check.get('memo') : check.memo;
        var cashAccountName = isInstance ? check.get('cash_account.name') : check.cash_account.name;
        var amount = isInstance ? check.get('amount') : check.amount;
        tableDraw += "<td>".concat(number || "", "</td>");
        tableDraw += "<td>".concat(date, "</td>");
        tableDraw += "<td>".concat(accountName, "</td>");
        tableDraw += "<td>".concat(payeeName, "</td>");
        tableDraw += "<td>".concat(memo || "", "</td>");
        tableDraw += "<td>".concat(cashAccountName, "</td>");
        tableDraw += "<td style='text-align: right'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      tableDraw += "</tbody></table></div>";
      return tableDraw;
    },
    generateTableCSV: function generateTableCSV(checks) {
      var _this3 = this;

      var tableDraw = "<div style=\"font-family: Verdana\"><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>Checks</h3>");
      tableDraw = "<table id='checksTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>NUMBER</th>";
      tableDraw += "<th style='text-align: left;'>DATE</th>";
      tableDraw += "<th style='text-align: left;'>NEW TRANSACTION</th>";
      tableDraw += "<th style='text-align: left;'>TYPE</th>";
      tableDraw += "<th style='text-align: left;'>PAYEE NAME</th>";
      tableDraw += "<th style='text-align: left;'>PAYEE TYPE</th>";
      tableDraw += "<th style='text-align: left;'>ACCOUNT</th>";
      tableDraw += "<th style='text-align: left;'>DESCRIPTION</th>"; // tableDraw +="<th style='text-align: left;'>BANK ACCOUNT</th>";

      tableDraw += "<th style='text-align: right'>AMOUNT</th></tr></thead>";
      tableDraw += "<tbody>";
      checks.forEach(function (check) {
        var isInstance = Ember.typeOf(check) === "instance";
        var transactionAccount = isInstance ? check.get('transaction_account.name') : check.transaction_account.name;
        var accountChecks = isInstance ? check.get('account_checks') : check.account_checks;
        var number = isInstance ? check.get('check_number') : check.check_number;
        var date = isInstance ? moment(check.get('check_date')).format('MM/DD/YYYY') : moment(check.check_date).format('MM/DD/YYYY'); // let accountName = isInstance ? check.get('transaction_account.name') : check.account_checks.find((account) => { return !account.is_cash_account; }).name;

        var payeeName = isInstance ? check.get('payee_name') : check.payee_name;
        var payeeType = isInstance ? check.get('payee_type') : check.payee_type;
        var memo = isInstance ? check.get('memo') : check.memo;
        var cashAccountName = isInstance ? check.get('cash_account.name') : check.cash_account.name;
        var amount = isInstance ? check.get('amount') : check.amount;

        if (transactionAccount === "-Payroll-" || transactionAccount === "-Payment-") {
          tableDraw += "<tr>";
          tableDraw += "<td>".concat(number || "", "</td>");
          tableDraw += "<td>".concat(date, "</td>");
          tableDraw += "<td>Yes</td>";
          tableDraw += "<td>".concat(transactionAccount, "</td>");
          tableDraw += "<td>".concat(payeeName, "</td>");
          tableDraw += "<td>".concat(Ember.String.capitalize(payeeType), "</td>");
          tableDraw += "<td>".concat(cashAccountName, "</td>");
          tableDraw += "<td>".concat(memo || "", "</td>");
          tableDraw += "<td style='text-align: right'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
        } else {
          var cashAccount = accountChecks.find(function (ac) {
            return ac.is_cash_account;
          });
          tableDraw += "<tr>";
          tableDraw += "<td>".concat(number || "", "</td>");
          tableDraw += "<td>".concat(date, "</td>");
          tableDraw += "<td>Yes</td>";
          tableDraw += "<td>Check</td>";
          tableDraw += "<td>".concat(payeeName, "</td>");
          tableDraw += "<td>".concat(Ember.String.capitalize(payeeType), "</td>");
          tableDraw += "<td>".concat(cashAccountName, "</td>");
          tableDraw += "<td>".concat(memo || "", "</td>");
          tableDraw += "<td style='text-align: right'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
          tableDraw += "</tr>";
          accountChecks.forEach(function (ac) {
            if (ac !== cashAccount) {
              var account = _this3.store.peekRecord('account', ac.account_id);

              tableDraw += "<tr>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td>".concat(account.get('name'), "</td>");
              tableDraw += "<td>".concat(ac.description || "", "</td>");
              tableDraw += "<td style='text-align: right'>".concat(ac.account_action === "credit" ? "-" : "").concat(parseFloat(ac.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
              tableDraw += "</tr>";
            }
          });
        }
      });
      tableDraw += "</tbody></table></div>";
      return tableDraw;
    },
    checkParams: function checkParams() {
      return {
        company_id: this.companyId,
        check: {
          company_id: this.companyId
        },
        sort_by: this.get('sortBy') ? this.get('sortBy') : "",
        sort_ascending: this.get('sortAscending') ? this.get('sortAscending') : "",
        from_date: this.get('fromDate') ? this.get('fromDate') : "",
        to_date: this.get('toDate') ? this.get('toDate') : "",
        cash_account_id: this.get('cashAccountId') ? this.get('cashAccountId') : "",
        filter: this.get('filter') ? this.get('filter') : "",
        search: this.get('search') ? this.get('search') : "",
        label_ids: this.get('filterLabelIds') ? this.get('filterLabelIds') : []
      };
    },
    sortFilterChecks: function sortFilterChecks() {
      var params = this.checkParams();

      var pagedChecks = _pagedRemoteArray.default.create({
        modelName: 'check',
        store: this.store,
        page: 1,
        otherParams: params
      });

      this.set('checks', pagedChecks);

      try {
        this.set('pagedChecks', (0, _pagedArray.default)("checks", {
          infinite: true
        }));
      } catch (e) {// do nothing
      }
    },
    actions: {
      getChecks: function getChecks() {
        this.sortFilterChecks();
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      addCheckToList: function addCheckToList(check) {
        if (this.get('checks') && this.get('checks.content.length') > 0) {
          this.get('pagedChecks.content').unshiftObject(check);
          this.set('checks.meta.total_checks', this.get('checks.meta.total_checks') + 1);
          this.set('checks.meta.total_amount', parseFloat(this.get('checks.meta.total_amount')) + check.get('amount'));
        } else {
          var pagedChecks = _pagedRemoteArray.default.create({
            modelName: 'check',
            store: this.store,
            page: 1,
            otherParams: {
              company_id: this.companyId,
              check: {
                company_id: this.companyId
              }
            }
          });

          this.set('checks', pagedChecks);
          this.set('pagedChecks', (0, _pagedArray.default)("checks", {
            infinite: true
          }));
        }
      },
      updateCheckTransactionAccount: function updateCheckTransactionAccount(check) {
        var accountId = (0, _jquery.default)("#selectCheckTransactionAccount-".concat(check.id))[0].value;
        var newTransactionAccount = {
          account_id: accountId,
          name: check.get('transaction_account.name'),
          clear: check.get('transaction_account.clear'),
          description: check.get('transaction_account.description'),
          amount: check.get('transaction_account.amount'),
          marked: check.get('transaction_account.marked')
        };
        check.get('transaction_account').removed = true;
        var accountData = [check.get('transaction_account'), newTransactionAccount];
        check.set('accounts', accountData);

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function (data) {
            data.get('account_checks').map(function (ac) {
              if (!ac.is_cash_account) {
                check.set('transaction_account', ac);
                check.set('transaction_account.name', _this.store.peekRecord('account', ac.account_id).get('name'));
              }
            });

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Account Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.get('transaction_account').removed = null;
          });
        });
      },
      // Update check payee via inline procedure
      // type param is only included because select-payee component returns it.
      updateCheckPayee: function updateCheckPayee(payee, type, check) {
        check.setProperties({
          'accounts': [check.get('transaction_account')],
          'payee_name': payee.get('name')
        }); // Need to assign this to a variable to be accessible inside the `.then()`

        var _this = this; // Update check server side procedure


        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Payee Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      updateCheckBankAccount: function updateCheckBankAccount(check) {
        var cashAccountId = (0, _jquery.default)("#selectCheckBankAccount-".concat(check.id))[0].value;
        check.setProperties({
          'accounts': check.get('account_checks'),
          'cash_account_id': cashAccountId
        });

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Bank Account Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      updateCheckDate: function updateCheckDate(check) {
        // let check = this.get('checkForDate');
        check.setProperties({
          'accounts': check.get('account_checks'),
          'check_date': check.get('check_date')
        });

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Date Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      updateCheckNumberMemo: function updateCheckNumberMemo(check) {
        check.set('accounts', check.get('account_checks'));

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      updateCheckAmount: function updateCheckAmount(check) {
        var originalAmount = check.get('amount');
        var amount = (0, _jquery.default)("#checkAmountInput-".concat(check.id))[0].value.replace(/,/g, '');
        check.get('account_checks').forEach(function (ac) {
          ac.amount = amount;
        });
        check.setProperties({
          'accounts': check.get('account_checks'),
          'amount': amount
        });

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            if (check.get('transaction_account')) {
              check.set('transaction_account.amount', amount);
            }

            _this.set('checks.meta.total_amount', parseFloat(_this.get('checks.meta.total_amount')) + (parseFloat(amount) - originalAmount));

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Amount Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      updateCheckLabels: function updateCheckLabels(index, labels, check) {
        check.get('account_checks').forEach(function (ac) {
          if (!ac.is_cash_account) {
            ac.label_ids = labels ? labels.map(function (label) {
              return label.id;
            }) : [];
          }
        });
        check.setProperties({
          'accounts': check.get('account_checks'),
          'labels': labels
        });

        var _this = this;

        this.store.findRecord('check', check.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(check);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Check Labels Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            check.rollbackAttributes();
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var _this = this;

          this.store.findRecord('check', id, {
            backgroundReload: false
          }).then(function (check) {
            _this.set('check', check);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteCheck: function deleteCheck() {
        var _this4 = this;

        this.check.destroyRecord().then(function () {
          try {
            _this4.get('pagedChecks.content').removeObject(_this4.check);
          } catch (e) {
            var destroyedCheck = _this4.check;

            var checks = _this4.checks.map(function (check, index) {
              if (check && check.id.toString() === destroyedCheck.id.toString()) {
                delete check[index];
              }
            }).filter(Boolean);

            _this4.setProperties({
              'checks': checks,
              'filteredChecks': checks
            });
          }

          _this4.setProperties({
            check: {},
            confirmDelete: false,
            'checks.meta.total_checks': _this4.get('checks.meta.total_checks') - 1,
            'checks.meta.total_amount': parseFloat(_this4.get('checks.meta.total_amount')) - _this4.get('check.amount')
          });

          _this4.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Check Deleted</span></div>'
          });
        }).catch(function (response) {
          var errors = response.errors.map(function (error) {
            return error;
          });

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        if (this.get('checks.page') < this.get('checks.totalPages')) {
          this.get('pagedChecks').loadNextPage();
        }
      },
      setSortBy: function setSortBy(property) {
        if (this.get('sortBy') === property) {
          this.toggleProperty('sortAscending');
        } else {
          this.set('sortBy', property);
          this.toggleProperty('sortAscending', true);
        }

        this.send('getChecks');
      },
      setCashAccountId: function setCashAccountId(id) {
        this.set('cashAccountId', id);
      },
      editRow: function editRow(index) {
        this.set('editingRow', index);
      },
      reloadPayees: function reloadPayees() {
        var _this5 = this;

        this.store.findAll('payee').then(function (payees) {
          _this5.set('payees', payees);
        });
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      print: function print() {
        var _this6 = this;

        var params = this.checkParams();
        this.store.query('check', params).then(function (checks) {
          var table = _this6.generateTablePrint(checks);

          (0, _jquery.default)('.print-container').empty();
          (0, _jquery.default)('.print-container').append(table);
          (0, _jquery.default)('.print-container').printThis({
            importCSS: false
          }); // let w = window.open();
          // w.document.write("<div id='print-container'></div>");
          // w.document.getElementById('print-container').innerHTML += table;
          // w.print();
          // w.close();
        });
      },
      csvExport: function csvExport() {
        var _this7 = this;

        var params = this.checkParams();
        this.store.query('check', params).then(function (checks) {
          var table = _this7.generateTableCSV(checks);

          (0, _jquery.default)('#csvExport').show();
          document.getElementById('csvExport').innerHTML += table;
          (0, _jquery.default)('#checksTable').table2CSV({
            delivery: 'download',
            filename: "".concat(_this7.store.peekRecord('company', _this7.get('companyId')).get('name'), "_checks_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
          }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

          (0, _jquery.default)('#checksTable').remove();
          (0, _jquery.default)('#csvExport').hide();
        });
      }
    }
  });

  _exports.default = _default;
});