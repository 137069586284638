define("account-berry-frontend/companies/show/accounts/transactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EqlSlwqs",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"account-transactions\",null,[[\"store\",\"model\",\"reloadRoute\",\"applicationController\",\"companyId\"],[[20,[\"store\"]],[20,[\"model\"]],[25,\"action\",[[19,0,[]],\"reloadRoute\"],null],[20,[\"applicationController\"]],[20,[\"companyId\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/companies/show/accounts/transactions/template.hbs"
    }
  });

  _exports.default = _default;
});