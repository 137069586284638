define("account-berry-frontend/companies/show/deposits/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "ember-cli-pagination/remote/paged-remote-array"], function (_exports, _jquery, _pagedArray, _pagedRemoteArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedDeposits: (0, _pagedArray.default)('deposits', {
      infinite: true
    }),
    filter: 'deposit_number',
    filters: [{
      name: "NUMBER",
      value: "deposit_number"
    }, {
      name: "CUSTOMER",
      value: "customer"
    }, {
      name: "AMOUNT",
      value: "amount"
    }, {
      name: "PAYMENT METHOD",
      value: "payment_method"
    }],
    deposits: Ember.computed('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      return this.get('model');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("deposit-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          if (_this2.get('deposits')) {
            _this2.updateDepositList(data.deposit);
          }
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var depositId = data.deposit.id;
          delete data.deposit.id;

          var deposit = _this2.store.peekRecord('deposit', depositId);

          deposit.setProperties(data.deposit);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          if (_this2.get('deposits')) {
            var deposit = _this2.store.peekRecord('deposit', data.deposit_id);

            deposit.deleteRecord();

            _this2.get('pagedDeposits.content').removeObject(data.deposit);

            _this2.setProperties({
              'deposits.meta.total_deposits': _this2.get('deposits.meta.total_deposits') - 1,
              'deposits.meta.total_amount': parseFloat(_this2.get('deposits.meta.total_amount')) - deposit.get('amount')
            });
          }
        }
      });
      this.set('subscribedToChannel', true);
    },
    // cashAccounts: Ember.computed('accounts', function() {
    //   if (this.accounts) {
    //     return this.accounts.filter((account) => {
    //       if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
    //         return account;
    //       }
    //     });
    //   }
    // }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    paymentPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable(deposits) {
      var tableDraw = "<div style=\"font-family: Verdana\"><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>Deposits</h3><table id='depositsTable' style='width:100%; font-size: 14px'><thead><tr>");
      tableDraw += "<th style='text-align: left;'>NUMBER</th>";
      tableDraw += "<th style='text-align: left;'>DATE</th>";
      tableDraw += "<th style='text-align: left;'>ACCOUNT</th>";
      tableDraw += "<th style='text-align: left;'>MEMO</th>";
      tableDraw += "<th style='text-align: left;'>CUSTOMER</th>";
      tableDraw += "<th style='text-align: left;'>BANK ACCOUNT</th>";
      tableDraw += "<th style='text-align: right'>AMOUNT</th>";
      tableDraw += "<th style='text-align: center'>PAYMENT METHOD</th></tr></thead>";
      tableDraw += "<tbody>";
      deposits.forEach(function (deposit) {
        tableDraw += "<tr>";
        var isInstance = Ember.typeOf(deposit) === "instance";
        var number = isInstance ? deposit.get('deposit_number') : deposit.deposit_number;
        var date = isInstance ? moment(deposit.get('deposit_date')).format('MM/DD/YYYY') : moment(deposit.deposit_date).format('MM/DD/YYYY');
        var accountName = isInstance ? deposit.get('transaction_account.name') : deposit.account_deposits.find(function (account) {
          return !account.is_cash_account;
        }).name;
        var memo = isInstance ? deposit.get('memo') : deposit.memo;
        var payeeName = isInstance ? deposit.get('customer') : deposit.customer;
        var cashAccountName = isInstance ? deposit.get('cash_account.name') : deposit.cash_account.name;
        var amount = isInstance ? deposit.get('amount') : deposit.amount;
        var paymentMethod = isInstance ? deposit.get('payment_method') : deposit.payment_method;
        tableDraw += "<td>".concat(number || "", "</td>");
        tableDraw += "<td>".concat(date, "</td>");
        tableDraw += "<td>".concat(accountName, "</td>");
        tableDraw += "<td>".concat(payeeName, "</td>");
        tableDraw += "<td>".concat(memo || "", "</td>");
        tableDraw += "<td>".concat(cashAccountName, "</td>");
        tableDraw += "<td style='text-align: right;'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        tableDraw += "<td style='text-align: center;'>".concat(paymentMethod || "", "</td></tr></tbody>");
      });
      tableDraw += "</table></div>";
      return tableDraw;
    },
    generateCSV: function generateCSV(deposits) {
      var tableDraw = "<div style=\"font-family: Verdana\"><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>Deposits</h3><table id='depositsTable' style='width:100%; font-size: 14px'><thead><tr>");
      tableDraw += "<th style='text-align: left;'>NUMBER</th>";
      tableDraw += "<th style='text-align: left;'>DATE</th>";
      tableDraw += "<th style='text-align: left;'>NEW TRANSACTION</th>";
      tableDraw += "<th style='text-align: left;'>TYPE</th>";
      tableDraw += "<th style='text-align: left;'>CUSTOMER</th>";
      tableDraw += "<th style='text-align: left;'>ACCOUNT</th>";
      tableDraw += "<th style='text-align: left;'>DESCRIPTION</th>"; // tableDraw +="<th style='text-align: left;'>BANK ACCOUNT</th>";

      tableDraw += "<th style='text-align: right'>AMOUNT</th>";
      tableDraw += "<th style='text-align: center'>PAYMENT METHOD</th></tr></thead>";
      tableDraw += "<tbody>";
      deposits.forEach(function (deposit) {
        tableDraw += "<tr>";
        var isInstance = Ember.typeOf(deposit) === "instance";
        var accountDeposits = isInstance ? deposit.get('account_deposits') : deposit.account_deposits;
        var number = isInstance ? deposit.get('deposit_number') : deposit.deposit_number;
        var date = isInstance ? moment(deposit.get('deposit_date')).format('MM/DD/YYYY') : moment(deposit.deposit_date).format('MM/DD/YYYY');
        var accountName = isInstance ? deposit.get('transaction_account.name') : deposit.account_deposits.find(function (account) {
          return !account.is_cash_account;
        }).name;
        var memo = isInstance ? deposit.get('memo') : deposit.memo;
        var payeeName = isInstance ? deposit.get('customer') : deposit.customer;
        var cashAccountName = isInstance ? deposit.get('cash_account.name') : deposit.cash_account.name;
        var amount = isInstance ? deposit.get('amount') : deposit.amount;
        var paymentMethod = isInstance ? deposit.get('payment_method') : deposit.payment_method;

        if (accountName === "-PAYMENT- ") {
          tableDraw += "<td>".concat(number || "", "</td>");
          tableDraw += "<td>".concat(date, "</td>");
          tableDraw += "<td>YES</td>";
          tableDraw += "<td>PAYMENT</td>"; // tableDraw += `<td>${accountName}</td>`;

          tableDraw += "<td>".concat(payeeName, "</td>");
          tableDraw += "<td>".concat(cashAccountName, "</td>");
          tableDraw += "<td>".concat(memo || "", "</td>");
          tableDraw += "<td style='text-align: right;'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
          tableDraw += "<td style='text-align: center;'>".concat(paymentMethod || "", "</td></tr>");
        } else {
          var cashAccount = accountDeposits.find(function (ac) {
            return ac.is_cash_account;
          });
          tableDraw += "<td>".concat(number || "", "</td>");
          tableDraw += "<td>".concat(date, "</td>");
          tableDraw += "<td>YES</td>";
          tableDraw += "<td>Deposit</td>";
          tableDraw += "<td>".concat(payeeName, "</td>");
          tableDraw += "<td>".concat(cashAccountName, "</td>");
          tableDraw += "<td>".concat(memo || "", "</td>");
          tableDraw += "<td style='text-align: right;'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
          tableDraw += "<td style='text-align: center;'>".concat(paymentMethod || "", "</td></tr>");
          accountDeposits.forEach(function (ad) {
            if (ad !== cashAccount) {
              tableDraw += "<tr>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td></td>";
              tableDraw += "<td>".concat(accountName, "</td>");
              tableDraw += "<td>".concat(memo || "", "</td>");
              tableDraw += "<td style='text-align: right;'>".concat(parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
              tableDraw += "<td></td>";
            }
          });
        }
      });
      tableDraw += "</tbody></table></div>";
      return tableDraw;
    },
    depositParams: function depositParams() {
      return {
        company_id: this.companyId,
        deposit: {
          company_id: this.companyId
        },
        sort_by: this.get('sortBy') ? this.get('sortBy') : "",
        sort_ascending: this.get('sortAscending') ? this.get('sortAscending') : "",
        from_date: this.get('fromDate') ? this.get('fromDate') : "",
        to_date: this.get('toDate') ? this.get('toDate') : "",
        cash_account_id: this.get('cashAccountId') ? this.get('cashAccountId') : "",
        filter: this.get('filter') ? this.get('filter') : "",
        search: this.get('search') ? this.get('search') : "",
        label_ids: this.get('filterLabelIds') ? this.get('filterLabelIds') : []
      };
    },
    sortFilterDeposits: function sortFilterDeposits() {
      var params = this.depositParams();

      var pagedDeposits = _pagedRemoteArray.default.create({
        modelName: 'deposit',
        store: this.store,
        page: 1,
        otherParams: params
      });

      this.set('deposits', pagedDeposits);

      try {
        this.set('pagedDeposits', (0, _pagedArray.default)("deposits", {
          infinite: true
        }));
      } catch (e) {}
    },
    actions: {
      getDeposits: function getDeposits() {
        this.sortFilterDeposits();
      },
      getDepositsByDate: function getDepositsByDate() {
        if (this.fromDate && this.toDate) {
          this.send('getDeposits', false);
        }
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      addDepositToList: function addDepositToList(deposit) {
        if (this.get('deposits') && this.get('deposits.content.length') > 0) {
          this.get('pagedDeposits.content').unshiftObject(deposit);
          this.setProperties({
            'deposits.meta.total_deposits': this.get('deposits.meta.total_deposits') + 1,
            'deposits.meta.total_amount': parseFloat(this.get('deposits.meta.total_amount')) + deposit.get('amount')
          });
        } else {
          var pagedDeposits = _pagedRemoteArray.default.create({
            modelName: 'deposit',
            store: this.store,
            page: 1,
            otherParams: {
              company_id: this.companyId,
              deposit: {
                company_id: this.companyId
              }
            }
          });

          this.set('deposits', pagedDeposits);
          this.set('pagedDeposits', (0, _pagedArray.default)("deposits", {
            infinite: true
          }));
        }
      },
      updateDepositTransactionAccount: function updateDepositTransactionAccount(deposit) {
        var accountId = (0, _jquery.default)("#selectDepositTransactionAccount-".concat(deposit.id))[0].value;
        var newTransactionAccount = {
          account_id: accountId,
          name: deposit.get('transaction_account.name'),
          clear: deposit.get('transaction_account.clear'),
          description: deposit.get('transaction_account.description'),
          amount: deposit.get('transaction_account.amount'),
          marked: deposit.get('transaction_account.marked')
        };
        deposit.get('transaction_account').removed = true;
        var accountData = [deposit.get('transaction_account'), newTransactionAccount];
        deposit.set('accounts', accountData);

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (d) {
          d.setProperties(deposit);
          d.save().then(function (data) {
            data.get('account_deposits').map(function (ad) {
              if (!ad.is_cash_account) {
                deposit.set('transaction_account', ad);
                deposit.set('transaction_account.name', _this.store.peekRecord('account', ad.account_id).get('name'));
              }
            });

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Account Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.get('transaction_account').removed = null;
          });
        });
      },
      updateDepositBankAccount: function updateDepositBankAccount(deposit) {
        var cashAccountId = (0, _jquery.default)("#selectDepositBankAccount-".concat(deposit.id))[0].value;
        deposit.setProperties({
          'accounts': deposit.get('account_deposits'),
          'cash_account_id': cashAccountId
        });

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (d) {
          d.setProperties(deposit);
          d.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Cash Account Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      // Update deposit customer via inline procedure
      // type param is only included because select-payee component returns it.
      updateDepositCustomer: function updateDepositCustomer(customer, type, deposit) {
        deposit.setProperties({
          'accounts': [deposit.get('transaction_account')],
          'customer_id': customer.get('id')
        });

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (d) {
          d.setProperties(deposit);
          d.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Customer Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      updateDepositDate: function updateDepositDate(deposit) {
        // let deposit = this.get('depositForDate');
        deposit.setProperties({
          'accounts': deposit.get('account_deposits'),
          'deposit_date': deposit.get('deposit_date')
        });

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(deposit);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Date Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      updateDepositData: function updateDepositData(deposit) {
        deposit.set('accounts', deposit.get('account_deposits'));

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (c) {
          c.setProperties(deposit);
          c.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      updateDepositAmount: function updateDepositAmount(deposit) {
        var originalAmount = deposit.get('amount');
        var amount = (0, _jquery.default)("#depositAmountInput-".concat(deposit.id))[0].value.replace(/,/g, '');
        deposit.get('account_deposits').forEach(function (ac) {
          ac.amount = amount;
        });
        deposit.setProperties({
          'accounts': deposit.get('account_deposits'),
          'amount': amount
        });

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (d) {
          d.setProperties(deposit);
          d.save().then(function () {
            if (deposit.get('transaction_account')) {
              deposit.set('transaction_account.amount', amount);
            }

            _this.set('deposits.meta.total_amount', parseFloat(_this.get('deposits.meta.total_amount')) + (parseFloat(amount) - originalAmount));

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Amount Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      updateDepositLabels: function updateDepositLabels(index, labels, deposit) {
        deposit.get('account_deposits').forEach(function (ac) {
          if (!ac.is_cash_account) {
            ac.label_ids = labels ? labels.map(function (label) {
              return label.id;
            }) : [];
          }
        });
        deposit.setProperties({
          'accounts': deposit.get('account_deposits'),
          'labels': labels
        });

        var _this = this;

        this.store.findRecord('deposit', deposit.id, {
          backgroundReload: false
        }).then(function (d) {
          d.setProperties(deposit);
          d.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Deposit Labels Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });

            deposit.rollbackAttributes();
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('deposit', id, {
            backgroundReload: false
          }).then(function (deposit) {
            self.set('deposit', deposit);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteDeposit: function deleteDeposit() {
        var _this3 = this;

        this.deposit.destroyRecord().then(function () {
          try {
            _this3.get('pagedDeposits.content').removeObject(_this3.deposit);
          } catch (e) {
            var destroyedDeposit = _this3.deposit;

            var deposits = _this3.deposits.map(function (deposit, index) {
              if (deposit && deposit.id.toString() === destroyedDeposit.id.toString()) {
                delete deposit[index];
              }
            }).filter(Boolean);

            _this3.setProperties({
              'deposits': deposits,
              'filteredDeposits': deposits
            });
          }

          _this3.setProperties({
            deposit: {},
            confirmDelete: false,
            'deposits.meta.total_deposits': _this3.get('deposits.meta.total_deposits') - 1,
            'deposits.meta.total_amount': parseFloat(_this3.get('deposits.meta.total_amount')) - _this3.get('deposit.amount')
          });

          _this3.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Deposit Deleted</span></div>'
          });
        }).catch(function (response) {
          var errors = response.errors.map(function (error) {
            return error;
          });

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        if (this.get('deposits.page') < this.get('deposits.totalPages')) {
          this.get('pagedDeposits').loadNextPage();
        }
      },
      setSortBy: function setSortBy(property) {
        if (this.get('sortBy') === property) {
          this.toggleProperty('sortAscending');
        } else {
          this.set('sortBy', property);
          this.toggleProperty('sortAscending', true);
        }

        this.send('getDeposits');
      },
      setCashAccountId: function setCashAccountId(id) {
        this.set('cashAccountId', id);
      },
      editRow: function editRow(index) {
        this.set('editingRow', index);
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      print: function print() {
        var _this4 = this;

        var params = this.depositParams();
        this.store.query('deposit', params).then(function (deposits) {
          var table = _this4.generateTable(deposits);

          (0, _jquery.default)('.print-container').empty();
          (0, _jquery.default)('.print-container').append(table);
          (0, _jquery.default)('.print-container').printThis({
            importCSS: false
          }); // let w = window.open();
          // w.document.write("<div id='print-container'></div>");
          // w.document.getElementById('print-container').innerHTML += table;
          // w.print();
          // w.close();
        });
      },
      csvExport: function csvExport() {
        var _this5 = this;

        var params = this.depositParams();
        this.store.query('deposit', params).then(function (deposits) {
          var table = _this5.generateCSV(deposits);

          (0, _jquery.default)('#csvExport').show();
          document.getElementById('csvExport').innerHTML += table;
          (0, _jquery.default)('#depositsTable').table2CSV({
            delivery: 'download',
            filename: "".concat(_this5.store.peekRecord('company', _this5.get('companyId')).get('name'), "_deposits_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
          }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

          (0, _jquery.default)('#depositsTable').remove();
          (0, _jquery.default)('#csvExport').hide();
        });
      }
    }
  });

  _exports.default = _default;
});