define("account-berry-frontend/companies/show/employees/payrolls/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _jquery, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newPayroll: false,
    payrollForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    ajaxCall: Ember.inject.service(),
    filter: "pay_date",
    filters: [{
      name: "PAY DATE",
      value: "pay_date"
    }, {
      name: "BEGIN DATE",
      value: "begin_date"
    }, {
      name: "END DATE",
      value: "end_date"
    }],
    pagedPayrolls: (0, _pagedArray.default)('payrolls', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('payrolls', this.store.peekAll('payroll').filter(function (payroll) {
          return !payroll.get('isDeleted');
        }));
        this.set('pagedPayrolls', (0, _pagedArray.default)('payrolls', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalPayrolls: Ember.computed('payrolls', function () {
      return this.get('payrolls.length');
    }),
    setPayrolls: function setPayrolls() {
      this.set('payrolls', this.store.peekAll('payroll').filter(function (payroll) {
        return !payroll.get('isDeleted');
      }));
      this.set('pagedPayrolls', (0, _pagedArray.default)('payrolls', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("payroll-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          _this2.updateRepresentativeList(data.payroll);
        }
      });
      channel.bind('updated', function (data) {
        // I'm not using the session id conditional here because we call the `pusher_updated` method on the backend
        // inside the payroll model where the curren_user is not accessible.
        // It works fine when updating
        var payrollId = data.payroll.id;
        delete data.payroll.id;

        var payroll = _this2.store.peekRecord('payroll', payrollId);

        try {
          payroll.setProperties(data.payroll);
        } catch (error) {}
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var payroll = _this2.store.peekRecord('payroll', data.payroll_id);

          payroll.deleteRecord();

          _this2.setPayrolls();
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateRepresentativeList: function updateRepresentativeList(payroll) {
      this.store.push({
        data: {
          id: payroll.id,
          type: 'payroll',
          attributes: payroll
        }
      });
      this.setPayrolls();
    },
    payrolls: Ember.computed('model', function () {
      return this.model;
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center'>Payrolls</h3>";
      table += "<table id='payrollsTable' style='width:100%; font-size: 1vw'>";
      table += "<thead><tr><th style='text-align: left'>PAY DATE</th>";
      table += "<th style='text-align: left'>BEGIN DATE</th>";
      table += "<th style='text-align: left'>END DATE</th>";
      table += "<th style='text-align: right'>REGULAR HOURS</th>";
      table += "<th style='text-align: right'>OVERTIME HOURS</th>";
      table += "<th style='text-align: right'>EARNINGS</th>";
      table += "<th style='text-align: right'>REIMBURSEMENTS</th>";
      table += "<th style='text-align: right'>DEDUCTIONS</th>";
      table += "<th style='text-align: right'>NET AMOUNT</th></tr></thead><tbody>";
      this.get('payrolls').forEach(function (payroll) {
        table += "<tr><td style='text-align: left'>".concat(moment(payroll.get('pay_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: left'>".concat(moment(payroll.get('begin_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: left'>".concat(moment(payroll.get('end_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('regular_hours')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('overtime_hours')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('earnings')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('reimbursements')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('deductions')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(payroll.get('net_amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      cancelEdit: function cancelEdit() {
        this.payroll.rollbackAttributes();
        this.toggleProperty('payrollForm');
      },
      duplicatePayroll: function duplicatePayroll() {
        var _this3 = this;

        var payroll = this.get('payroll');
        payroll.set('duplicate', true);

        var _this = this;

        var data = JSON.stringify({
          id: payroll.id,
          payroll: payroll
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/payrolls/"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            _this3.send('refreshModel');

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Payroll Duplicated</span></div>'
            });

            payroll.set('duplicate', false);

            _this.set('confirmDuplicate', false);
          },
          errorCallback: function errorCallback(response) {
            payroll.set('duplicate', false);

            _this.set('confirmDuplicate', false);

            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(response.payload.errors || errors, "</span></div>")
            });

            payroll.rollbackAttributes();
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this4 = this;

        if (id) {
          this.store.findRecord('payroll', id, {
            backgroundReload: false
          }).then(function (payroll) {
            _this4.set('payroll', payroll);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      toggleConfirmDuplicate: function toggleConfirmDuplicate(id) {
        if (id) {
          this.set('payroll', this.store.peekRecord('payroll', id));
        }

        this.toggleProperty('confirmDuplicate');
      },
      deletePayroll: function deletePayroll() {
        var _this5 = this;

        this.payroll.destroyRecord().then(function () {
          _this5.setPayrolls();

          _this5.setProperties({
            payroll: {},
            confirmDelete: false
          });

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Payroll Deleted</span></div>"
          });
        }, function (response) {
          if (response) {
            var errors = "An Error Occured.";

            if (response.errors && !response.errors[0].detail) {
              errors = response.errors.map(function (error) {
                return error;
              });
            }

            _this5.payroll.rollbackAttributes();

            _this5.set('confirmDelete', false);

            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        });
      },
      loadNext: function loadNext() {
        this.get('pagedPayrolls').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('payrolls', this.get('payrolls').sortBy(property));
        } else {
          this.set('payrolls', this.get('payrolls').sortBy(property).reverse());
        }

        this.set('pagedPayrolls', (0, _pagedArray.default)('payrolls', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#payrollsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_payrolls_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#payrollsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});