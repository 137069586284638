define("account-berry-frontend/components/account-groupcode/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y0QNU3VU",
    "block": "{\"symbols\":[\"taxGroupCode\"],\"statements\":[[6,\"select\"],[9,\"class\",\"btn no-background width-100\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"updateAccountGroupCode\"],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n  \"],[6,\"option\"],[9,\"value\",\"\"],[9,\"disabled\",\"\"],[9,\"selected\",\"\"],[7],[0,\"Select Group Code\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"taxGroupCodes\"]]],null,{\"statements\":[[0,\"    \"],[6,\"option\"],[10,\"value\",[19,1,[\"code\"]],null],[10,\"selected\",[25,\"eq\",[[19,1,[\"code\"]],[20,[\"account\",\"group_code\"]]],null],null],[7],[1,[19,1,[\"code\"]],false],[0,\" - \"],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/account-groupcode/template.hbs"
    }
  });

  _exports.default = _default;
});