define("account-berry-frontend/components/select-payee/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // allow custom select id
    selectId: 'payeeSelect',
    selectClass: null,
    spanClass: null,
    payeeType: null,
    payeeSelect: true,
    createPayee: false,
    creatablePayee: true,
    init: function init() {
      var _this = this;

      this._super();

      var company = this.store.peekRecord('company', this.companyId);

      if (!this.get('import')) {
        // Get payees
        if (!this.get('allPayees')) {
          this.store.findAll('payee', {
            reload: true
          }).then(function (payees) {
            _this.set('allPayees', payees);
          });
        }
      }

      var vendors = this.get('vendors') || this.store.peekAll('vendor');
      var customers = this.get('customers') || this.store.peekAll('customer');
      var employees = this.get('employees') || this.store.peekAll('employee');
      this.set('originalSelectType', this.get('selectType'));

      if (!this.get('import')) {
        // Depending on specific select type, find all of type
        // If none specified, find all of all types
        var selectType = this.selectType ? this.selectType.toLowerCase().trim() : this.selectType;

        switch (selectType) {
          case "vendor":
            if (vendors.content.length === 0 && company.get('counts.vendors_count') !== 0) {
              this.store.findAll('vendor');
            }

            break;

          case "customer":
            if (customers.content.length === 0 && company.get('counts.customers_count') !== 0) {
              this.store.findAll('customer');
            }

            break;

          case "employee":
            if (employees.content.length === 0 && company.get('counts.active_employees_count') !== 0) {
              this.store.findAll('employee');
            }

            break;

          default:
            if (vendors.content.length === 0 && company.get('counts.vendors_count') !== 0) {
              this.store.findAll('vendor');
            }

            if (customers.content.length === 0 && company.get('counts.customers_count') !== 0) {
              this.store.findAll('customer');
            }

            if (employees.content.length === 0 && company.get('counts.active_employees_count') !== 0) {
              this.store.findAll('employee');
            }

        }
      }
    },
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)("#".concat(this.selectId)).select2();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (this.inlineTransaction) {
        (0, _jquery.default)("#".concat(this.selectId, " option"))[0].selected = true;
      }
    },
    payees: Ember.computed('allPayees', function () {
      var _this2 = this;

      if (this.get('allPayees')) {
        return this.get('allPayees').map(function (payee) {
          if (!!_this2.selectType && payee.get('type').toLowerCase() === _this2.selectType.trim()) {
            return payee;
          } else if (!_this2.selectType) {
            return payee;
          }
        }).filter(Boolean).sort(function (a, b) {
          return a.get('name').localeCompare(b.get('name'));
        });
      }
    }),
    existingPayee: Ember.computed('payeeId', 'payeeName', function () {
      return this.payeeId && this.payeeName;
    }),
    actions: {
      setPayee: function setPayee(value, newPayee) {
        // Gets payee dropdown option elements
        var selectOptions = (0, _jquery.default)("#".concat(this.selectId, " option"));

        switch (value) {
          case '':
            selectOptions[0].selected = true;
            break;
          // Triggers create payee modal

          case "new":
            this.send('toggleCreatePayee');
            break;
          // If canceled, return dropdown to default value

          case "cancel":
            selectOptions[0].selected = true;
            break;
          // Set the value option to selected

          default:
            // Value is a string which contains the payee id and type separated by a comma
            // We use the `.split` method to extract the values to it's corresponding variable
            var payeeId = value.split(",")[0];
            var payeeType = value.split(",")[1].toLowerCase();
            var payee = this.store.peekRecord(payeeType, payeeId);

            if (newPayee) {
              // Add new payee to dropdown options before `create payee` option
              (0, _jquery.default)("#".concat(this.selectId, " option")).eq(selectOptions.length - 1).before((0, _jquery.default)("<option></option>").val("".concat(payeeId, ",").concat(payeeType)).text(payee.get('name')));
              selectOptions = (0, _jquery.default)("#".concat(this.selectId, " option")); // Set new payee as selected on dropdown

              selectOptions[selectOptions.length - 2].selected = true;
            } // Trigger setPayee action with payee record, it's type and includes
            // the transaction for inline updating procedures.
            // Most of the time that value is falsey.


            this.sendAction('setPayee', payee, payeeType, this.transaction, this.index);
        }
      },
      toggleCreatePayee: function toggleCreatePayee() {
        // Toggle create payee modal
        this.toggleProperty('createPayee');
      },
      cancelCreatePayee: function cancelCreatePayee() {
        this.toggleProperty('createPayee');
        this.send('setPayee', 'cancel');
      }
    }
  });

  _exports.default = _default;
});