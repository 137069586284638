define("account-berry-frontend/components/user-role-form/component", ["exports", "account-berry-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    newUser: false,
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    confirmTransferOwnership: false,
    confirmCancelTransferOwnership: false,
    init: function init() {
      this._super();

      if (!this.get('user')) {
        this.set('user', {});
      }
    },
    didInsertElement: function didInsertElement() {
      this.setToolTip();
    },
    roles: Ember.computed(function () {
      return this.get('session.data.authenticated.roles');
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    permissions: Ember.computed('roleId', 'userRole.role_id', function () {
      var roleId = this.get('roleId') ? this.get('roleId') : this.get('userRole.role_id');

      if (this.get('roleId')) {
        var role = this.get('session.data.authenticated.roles').find(function (role) {
          return role.id.toString() === roleId.toString();
        });
        return this.setupPermissions(role.name);
      } else if (this.get('userRole.role_id')) {
        this.set('roleId', this.get('userRole.role_id'));

        var _role = this.get('session.data.authenticated.roles').find(function (role) {
          return role.id.toString() === roleId.toString();
        });

        return this.setupPermissions(_role.name, this.get('userRole.permissions'));
      }
    }),
    setupPermissions: function setupPermissions(roleName, permissions) {
      this.resetSelectedRole();
      var permissions_data;

      switch (roleName) {
        case "Creator":
          this.set('creatorRole', true);
          this.set('roleDescription', "Users with this role have all permissions.");
          permissions_data = {
            company: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            customers: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            vendors: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            employees: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            reports: {
              read: true
            }
          };
          break;

        case "Manager":
          this.set('managerRole', true);
          this.set('roleDescription', "Users with this role cannot delete the company nor assign users with a creator role.");
          permissions_data = {
            company: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            customers: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            vendors: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            employees: {
              read: true,
              write: true,
              edit: true,
              delete: true
            },
            reports: {
              read: true
            }
          };
          break;

        case "Data Entry":
          this.set('dataEntryRole', true);
          this.set('roleDescription', "Users with this role only have the assigned custom permissions.");
          permissions_data = {
            company: {
              read: true,
              write: true,
              edit: true,
              delete: false
            },
            customers: {
              read: true,
              write: true,
              edit: true,
              delete: false
            },
            vendors: {
              read: true,
              write: true,
              edit: true,
              delete: false
            },
            employees: {
              read: true,
              write: true,
              edit: true,
              delete: false
            },
            reports: {
              read: true
            }
          };
          break;

        case "Guest":
          this.set('guestRole', true);
          this.set('roleDescription', "Users with this role only have viewing permissions.");
          permissions_data = {
            company: {
              read: true,
              write: false,
              edit: false,
              delete: false
            },
            customers: {
              read: true,
              write: false,
              edit: false,
              delete: false
            },
            vendors: {
              read: true,
              write: false,
              edit: false,
              delete: false
            },
            employees: {
              read: true,
              write: false,
              edit: false,
              delete: false
            },
            reports: {
              read: true
            }
          };
          break;
      }

      this.setToolTip();
      return permissions ? permissions : permissions_data;
    },
    permissionsObserver: Ember.observer('permissions.company.read', 'permissions.customers.read', 'permissions.vendors.read', 'permissions.employees.read', function () {
      var _this = this;

      var locations = ['company', 'customers', 'vendors', 'employees'];
      locations.forEach(function (location) {
        if (!_this.get("permissions.".concat(location, ".read"))) {
          _this.set("permissions.".concat(location, ".write"), false);

          _this.set("permissions.".concat(location, ".create"), false);

          _this.set("permissions.".concat(location, ".edit"), false);

          _this.set("permissions.".concat(location, ".delete"), false);
        }
      });
    }),
    resetSelectedRole: function resetSelectedRole() {
      this.setProperties({
        creatorRole: false,
        managerRole: false,
        dataEntryRole: false,
        guestRole: false
      });
    },
    setToolTip: function setToolTip() {
      (0, _jquery.default)('#selectRole').tooltip({
        placement: "right",
        trigger: "hover focus",
        title: this.get('roleDescription')
      });
      (0, _jquery.default)('#selectRole').attr('title', this.get('roleDescription')).attr('data-placement', 'right').tooltip('fixTitle').tooltip('show');
    },
    actions: {
      updateRoleValue: function updateRoleValue(value) {
        this.set('roleId', value);
      },
      togglePermission: function togglePermission(permission, disabled) {
        if (!disabled) {
          this.toggleProperty(permission);
        }
      },
      updateRole: function updateRole() {
        var _this2 = this;

        var data = {
          user_role: {
            role_id: this.get('roleId'),
            permissions: this.get('permissions')
          }
        };
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/user_roles/").concat(this.get('userRole.id')),
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify(data),
          successCallback: function successCallback() {
            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>User Updated</span></div>'
            });

            _this2.sendAction('reloadRoute');

            _this2.get('router').transitionTo('companies.show.users', _this2.companyId);
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      inviteUser: function inviteUser() {
        var _this3 = this;

        var invite = {
          email: this.get('email'),
          company_id: this.get('companyId'),
          role_id: this.get('roleId'),
          permissions: this.get('permissions')
        };
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/invites"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            invite: invite,
            company_id: this.get('companyId')
          }),
          successCallback: function successCallback(response) {
            if (response.user) {
              _this3.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>User Added</span></div>'
              });

              _this3.store.createRecord('user-role', response.user_role);
            } else {
              _this3.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>User Invited</span></div>'
              });
            }

            _this3.sendAction('reloadRoute');

            _this3.get('router').transitionTo('companies.show.users', _this3.companyId);
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      cancel: function cancel() {
        this.get('router').transitionTo('companies.show.users', this.companyId);
      },
      toggleConfirmTransferOwnership: function toggleConfirmTransferOwnership() {
        this.toggleProperty('confirmTransferOwnership');
      },
      toggleCancelTransferOwnership: function toggleCancelTransferOwnership() {
        this.toggleProperty('confirmCancelTransferOwnership');
      },
      transferOwnership: function transferOwnership() {
        var _this4 = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/transfer_ownerships"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            ownership_transfer: {
              new_owner_id: this.get('user.id'),
              current_owner_id: this.get('session.data.authenticated.id'),
              company_id: this.get('company.id')
            }
          }),
          successCallback: function successCallback() {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>An email has been sent to the user to accept the ownership transfer.</span></div>'
            });

            _this4.set('userRole.ownership_transfer', true);

            _this4.toggleProperty('confirmTransferOwnership');
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      cancelTransferOwnership: function cancelTransferOwnership() {
        var _this5 = this;

        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/transfer_ownerships/").concat(this.companyId),
          method: 'DELETE',
          contentType: 'application/json',
          data: JSON.stringify({
            ownership_transfer: {
              new_owner_id: this.get('user.id'),
              current_owner_id: this.get('session.data.authenticated.id'),
              company_id: this.get('company.id')
            }
          }),
          successCallback: function successCallback() {
            _this5.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Ownership Transfer canceled</span></div>'
            });

            _this5.set('userRole.ownership_transfer', false);

            _this5.toggleProperty('confirmCancelTransferOwnership');
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this5.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});