define("account-berry-frontend/companies/show/closing-entries/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _jquery, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedClosingEntries: (0, _pagedArray.default)('filteredClosingEntries', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredClosingEntries', this.store.peekAll('closing-entry').filter(function (closingEntry) {
          return !closingEntry.get('isDeleted');
        }));
        this.set('pagedClosingEntries', (0, _pagedArray.default)('filteredClosingEntries', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalClosingEntries: Ember.computed('filteredClosingEntries', function () {
      return this.get('filteredClosingEntries.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("closing-entry-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateClosingEntryList(data.closing_entry);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var closingEntryId = data.closing_entry.id;
          delete data.closing_entry.id;

          var closingEntry = _this.store.peekRecord('closing-entry', closingEntryId);

          closingEntry.setProperties(data.closing_entry);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var closingEntry = _this.store.peekRecord('closing-entry', data.closing_entry_id);

          closingEntry.deleteRecord();

          try {
            _this.filteredClosingEntries.set('lastObject.is_last_closing_entry', true);
          } catch (e) {
            Ember.Logger.error(e);
          }

          _this.set('filteredClosingEntries', _this.store.peekAll('closing-entry').filter(function (closingEntry) {
            return !closingEntry.get('isDeleted');
          }));

          _this.set('pagedClosingEntries', (0, _pagedArray.default)('filteredClosingEntries', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateClosingEntryList: function updateClosingEntryList(closingEntry) {
      this.set('model.lastObject.is_last_closing_entry', false);
      this.store.push({
        data: {
          id: closingEntry.id,
          type: 'closing-entry',
          attributes: closingEntry
        }
      });
      this.set('filteredClosingEntries', this.store.peekAll('closing-entry').filter(function (closingEntry) {
        return !closingEntry.get('isDeleted');
      }));
      this.set('pagedClosingEntries', (0, _pagedArray.default)('filteredClosingEntries', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredClosingEntries: Ember.computed('model', function () {
      return this.model;
    }),
    filter: "date",
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    generateTable: function generateTable() {
      var table = "<h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center'>Year-end Closings</h3><table id='closingEntriesTable' style='width:100%; font-size: 14px'><thead><tr style='text-align: left;'><th>DATE</th>");
      table += "<th style='text-align: right;'>ASSETS</th>";
      table += "<th style='text-align: right;'>LIABILITIES</th>";
      table += "<th style='text-align: right;'>NET INCOME</th>";
      table += "<th style='text-align: right;'>RETAINED EARNIGS BEGIN</th>";
      table += "<th style='text-align: right;'>ADJUSTMENTS</th>";
      table += "<th style='text-align: right;'>RETAINED EARNINGS END</th></tr></thead>";
      this.get('filteredClosingEntries').forEach(function (closingEntry) {
        table += "<tbody><td style='text-align: left;'>".concat(closingEntry.get('date'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('assets')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('liabilities')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('net_income')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('retained_earnings_begin')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('adjustments')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(closingEntry.get('retained_earnings_end')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr></tbody>");
      });
      table += "</table>";
      return table;
    },
    actions: {
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('closing-entry', id, {
            backgroundReload: false
          }).then(function (closingEntry) {
            self.set('closingEntry', closingEntry);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      setAccount: function setAccount(id) {
        Ember.set(this.get('closingEntryData'), 'account_id', id);
      },
      addClosingEntry: function addClosingEntry() {
        var _this2 = this;

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/closing_entries/number"),
          method: 'GET',
          contentType: 'application/json',
          successCallback: function successCallback() {
            _this2.get('applicationController').set('showLoading', true);

            var channel = _this2.get('session.pusher').subscribe("closing-entry-channel-".concat(_this2.get('session.data.authenticated.id')));

            channel.bind('closing-entry', function (closingEntry) {
              _this2.get('applicationController').set('showLoading', false);

              _this2.set('closingEntryData', closingEntry);

              if (_this2.get('closingEntryData.inventory_difference') !== 0 || Number(_this2.get('closingEntryData.inventory_difference')) !== 0) {
                _this2.set('inventoryDifference', true);

                _this2.store.findAll('account').then(function (accounts) {
                  var filteredAccounts = accounts.filter(function (account) {
                    return account.get('account_type.name') !== "Cash" && account.get('account_type.name') !== "Inventory";
                  });

                  _this2.set('accounts', filteredAccounts);
                });
              }

              _this2.set('closingEntryWizard', true);
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      closeTheBook: function closeTheBook() {
        var _this3 = this;

        if (Number(this.get('closingEntryData.inventory_difference')) !== 0 && !this.get('closingEntryData.account_id')) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>You need to select an account</span></div>"
          });
        } else {
          var newClosingEntry = this.store.createRecord('closing-entry', this.closingEntryData);
          newClosingEntry.save().then(function () {
            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Closing Entry Created</span></div>'
            });

            _this3.get('filteredClosingEntries').forEach(function (closingEntry) {
              try {
                closingEntry.is_last_closing_entry = false;
              } catch (e) {
                closingEntry.set('is_last_closing_entry', false);
              }
            });

            _this3.send('refreshModel');
          }, function (response) {
            newClosingEntry.rollbackAttributes();
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
          this.set('closingEntryData', null);
          this.set('inventoryDifference', false);
          this.set('closingEntryWizard', false);
        }
      },
      cancelClosing: function cancelClosing() {
        this.set('closingEntryData', null);
        this.set('inventoryDifference', false);
        this.set('closingEntryWizard', false);
      },
      deleteClosingEntry: function deleteClosingEntry() {
        var _this4 = this;

        this.closingEntry.destroyRecord().then(function () {
          _this4.set('filteredClosingEntries', _this4.store.peekAll('closing-entry').filter(function (closingEntry) {
            return !closingEntry.get('isDeleted');
          }));

          _this4.setProperties({
            pagedClosingEntries: (0, _pagedArray.default)('filteredClosingEntries', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            closingEntry: {},
            confirmDelete: false
          });

          _this4.get('notify').alert({
            html: '<i class="icon-warning lg"></i> <div class="message-text"><span>Closing Entry Deleted</span></div>'
          });

          try {
            _this4.filteredClosingEntries.set('lastObject.is_last_closing_entry', true);
          } catch (e) {
            Ember.Logger.error(e);
          }
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedClosingEntries').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#closingEntriesTable').table2CSV({
          delivery: 'download',
          filename: 'ClosingEntriesTable.csv'
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#closingEntriesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});