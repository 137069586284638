define("account-berry-frontend/companies/show/batches/controller", ["exports", "ember-cli-pagination/computed/paged-array", "jquery"], function (_exports, _pagedArray, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newBatch: false,
    batchForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    applicationController: Ember.inject.controller('application'),
    pagedBatches: (0, _pagedArray.default)('filteredBatches', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      // if(!this.get('subscribedToChannel')) {
      //   this.subscribeToChannels();
      // }
      this.set('applicationController.showLoading', false);

      try {
        this.set('filteredBatches', this.store.peekAll('batch').filter(function (batch) {
          return !batch.get('isDeleted');
        }));

        if (!this.get('filteredBatches')) {
          this.set('filteredBatches', []);
        }

        this.set('pagedBatches', (0, _pagedArray.default)('filteredBatches', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalBatches: Ember.computed('filteredBatches', function () {
      return this.get('filteredBatches.length');
    }),
    filteredBatches: Ember.computed('model', function () {
      return this.model;
    }),
    filterBatches: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredBatches', this.model);
      } else {
        this.set('filteredBatches', this.model.filter(function (batch) {
          var batchValue = batch.get(filter);

          if (batchValue) {
            if (isNaN(batchValue)) {
              batchValue = batchValue.toLowerCase();
            }

            if (batchValue.includes(search)) {
              return batch;
            }
          }
        }));
      }

      this.set('pagedBatches', (0, _pagedArray.default)('filteredBatches', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Batches</h3><table id='batchesTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>DISPLAY NAME</th>");
      table += "<th style='text-align: left;'>COMPANY</th>";
      table += "<th style='text-align: left;'>EIN</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>NOTES</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredBatches').forEach(function (batch) {
        table += "<tr><td>".concat(batch.get('display_name'), "</td>");
        table += "<td>".concat(batch.get('company_name'), "</td>");
        table += "<td>".concat(batch.get('company_ein') || "", "</td>");
        table += "<td>".concat(batch.get('email') || "", "</td>");
        table += "<td>".concat(batch.get('notes') || "", "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('batch', id, {
            backgroundReload: false
          }).then(function (batch) {
            self.set('batch', batch);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteBatch: function deleteBatch() {
        var _this = this;

        var channel = this.get('session.pusher').subscribe("import-channel-".concat(this.get('session.data.authenticated.id')));
        this.batch.destroyRecord().then(function () {
          _this.toggleProperty('applicationController.showLoading');

          _this.set('confirmDelete', false);

          channel.bind('destroy', function (response) {
            _this.set('applicationController.showLoading', false);

            if (response.error) {
              _this.store.unloadAll('batch');

              _this.send('refreshModel');

              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(response.error, "</span></div>")
              });
            } else {
              _this.send('refreshModel');

              _this.set('filteredBatches', _this.store.peekAll('batch').filter(function (batch) {
                return !batch.get('isDeleted');
              }));

              _this.setProperties({
                pagedBatches: (0, _pagedArray.default)('filteredBatches', {
                  infinite: "unpaged",
                  page: 1,
                  perPage: 100
                }),
                batch: {}
              });

              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Batch Deleted</span></div>"
              });
            }
          });
        }).catch(function (response) {
          _this.set('applicationController.showLoading', false);

          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this.batch.rollbackAttributes();

          _this.set('confirmDelete', false);

          _this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedBatches').loadNextPage();
      },
      toggleShowLoading: function toggleShowLoading() {
        this.toggleProperty('applicationController.showLoading');
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredBatches', this.get('filteredBatches').sortBy(property));
        } else {
          this.set('filteredBatches', this.get('filteredBatches').sortBy(property).reverse());
        }

        this.set('pagedBatches', (0, _pagedArray.default)('filteredBatches', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#batchesTablee').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_batchs_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#batchesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});