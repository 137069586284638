define("account-berry-frontend/companies/show/customers/taxes/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedTaxes: (0, _pagedArray.default)('filteredTaxes', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredTaxes', this.store.peekAll('tax').filter(function (tax) {
          return !tax.get('isDeleted');
        }));
        this.set('pagedTaxes', (0, _pagedArray.default)('filteredTaxes', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalTaxes: Ember.computed('filteredTaxes', function () {
      return this.get('filteredTaxes.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("tax-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateTaxList(data.tax);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var taxId = data.tax.id;
          delete data.tax.id;

          var tax = _this.store.peekRecord('tax', taxId);

          tax.setProperties(data.tax);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var tax = _this.store.peekRecord('tax', data.tax_id);

          tax.deleteRecord();

          _this.set('filteredTaxes', _this.store.peekAll('tax').filter(function (tax) {
            return !tax.get('isDeleted');
          }));

          _this.set('pagedTaxes', (0, _pagedArray.default)('filteredTaxes', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateTaxList: function updateTaxList(tax) {
      this.store.push({
        data: {
          id: tax.id,
          type: 'tax',
          attributes: tax
        }
      });
      this.set('filteredTaxes', this.store.peekAll('tax').filter(function (tax) {
        return !tax.get('isDeleted');
      }));
      this.set('pagedTaxes', (0, _pagedArray.default)('filteredTaxes', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredTaxes: Ember.computed('model', function () {
      return this.model;
    }),
    filter: "name",
    filters: [{
      name: "NUMBER",
      value: "name"
    }],
    filterTaxes: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredTaxes', this.model);
      } else {
        this.set('filteredTaxes', this.model.filter(function (tax) {
          var taxValue = tax.get(filter);

          if (taxValue) {
            if (isNaN(taxValue)) {
              taxValue = taxValue.toLowerCase();
            }

            if (taxValue.includes(search)) {
              return tax;
            }
          }
        }));
      }

      this.set('pagedTaxes', (0, _pagedArray.default)('filteredTaxes', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterTaxesByDate: Ember.observer('fromDateFilter', 'toDateFilter', function () {
      var fromDateFilter = this.get('fromDateFilter');
      var toDateFilter = this.get('toDateFilter');

      if (fromDateFilter || toDateFilter) {
        this.set('filteredTaxes', this.get('model').sortBy('date').filter(function (tax) {
          if (fromDateFilter && toDateFilter) {
            if (tax.get('date') >= fromDateFilter.toISOString().slice(0, 10) && tax.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return tax;
            }
          } else if (fromDateFilter && !toDateFilter) {
            if (tax.get('date') >= fromDateFilter.toISOString().slice(0, 10)) {
              return tax;
            }
          } else if (!fromDateFilter && toDateFilter) {
            if (tax.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return tax;
            }
          }
        }));
      } else {
        this.set('filteredTaxes', this.get('model'));
      }

      this.set('pagedTaxes', (0, _pagedArray.default)('filteredTaxes', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center'>Taxes</h3><table id='taxesTable' style='width:100%; font-size: 14px'><thead><tr style='text-align: left;'><th>NAME</th>");
      table += "<th style='text-align: left;'>DESCRIPTION</th>";
      table += "<th style='text-align: left;'>VENDOR</th>";
      table += "<th style='text-align: left;'>ACCOUNT</th>";
      table += "<th style='text-align: right;'>PERCENTAGE</th></tr></thead>";
      this.get('filteredTaxes').forEach(function (tax) {
        table += "<tbody><td>".concat(tax.get('name'), "</td>");
        table += "<td>".concat(tax.get('description'), "</td>");
        table += "<td>".concat(tax.get('vendor'), "</td>");
        table += "<td>".concat(tax.get('account'), "</td>");
        table += "<td style='text-align: right;'>%{parseFloat(tax.get('percentage')).toFixed(2).replace(/(d)(?=(d{3})+.)/g, '$1,')}</td></tr></tbody>";
      });
      table += "</table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this2 = this;

        if (id) {
          this.store.findRecord('tax', id, {
            backgroundReload: false
          }).then(function (tax) {
            _this2.set('tax', tax);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteTax: function deleteTax() {
        var _this3 = this;

        this.tax.destroyRecord().then(function () {
          _this3.set('filteredTaxes', _this3.store.peekAll('tax').filter(function (tax) {
            return !tax.get('isDeleted');
          }));

          _this3.setProperties({
            pagedTaxes: (0, _pagedArray.default)('filteredTaxes', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            tax: {},
            confirmDelete: false
          });

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Tax Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.representative.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedTaxes').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#taxesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_taxes_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#taxesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});