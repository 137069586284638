define("account-berry-frontend/components/clover-sync/component", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    notify: Ember.inject.service(),
    cable: Ember.inject.service(),
    initial_setup_end: false,
    cloverURL: Ember.computed(function () {
      var state = "".concat(this.get('session.data.authenticated.id'), "-").concat(this.get('companyId'));
      var redirectURI = "".concat(_environment.default.clientURL, "/auth/clover/callback"); // OAUTH1
      // return `${ENV.clover.URI}/oauth/authorize?client_id=${ENV.clover.appId}&redirect_uri=${redirectURI}&state=${state}`
      // OAUTH2

      return "".concat(_environment.default.clover.URI, "/oauth/v2/authorize?client_id=").concat(_environment.default.clover.appId, "&redirect_uri=").concat(redirectURI, "&state=").concat(state);
    }),
    user: Ember.computed('session.data.authenticated.id', function () {
      return this.store.peekRecord('user', this.get('session.data.authenticated.id'));
    }),
    init: function init() {
      this._super();

      this.setChannel();
    },
    setChannel: function setChannel() {
      var _this = this;

      var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));
      consumer.createSubscription({
        channel: "CloverChannel",
        room: this.get('companyId')
      }, {
        connected: function connected() {
          console.log('Connected to CloverChannel');
        },
        received: function received(data) {
          if (data.error) {
            console.log("Error: " + data.error);

            _this.get('applicationController').set('showLoading', false);

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please add or update your payment method.</span></div>"
            }); // this.set('paymentModal', true)

          } else {
            _this.set('model.initial_setup', true);

            _this.set('initial_setup_end', false);

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Data Import Completed!</span></div>'
            });

            _this.get('applicationController').set('showLoading', false);
          }
        }
      });
    },
    startImport: function startImport() {
      var _this2 = this;

      if (this.get('session.data.authenticated.stripe_card_id') || this.get('user.card_last4_digits')) {
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.get('companyId'), "/clover_syncs"),
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            clover_sync: {
              import_data: true
            }
          }),
          successCallback: function successCallback() {
            _this2.get('applicationController').set('showLoading', true);

            _this2.get('notify').success({
              html: "<i class=\"icon-checkmark lg\"></i> <div class=\"message-text\"><strong>Success:</strong>&nbsp<span>Import process has started. Please give it a moment.</span></div>"
            });
          },
          errorCallback: function errorCallback(response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload && response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this2.get('applicationController').set('showLoading', false);

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            }); // this.set('paymentModal', true)
            // setTimeout(function() {
            //   window.location.href = `${ENV.clientURL}/${this.get('companyId')}/clover`;
            // }, 2000)

          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      } else {
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>Please Add a Payment Method</span></div>"
        });
        this.set('paymentModal', true);
      }
    },
    actions: {
      beginSetup: function beginSetup(importData) {
        var _this3 = this;

        if (importData) {
          this.startImport();
        } else {
          // this.set('model.initial_setup', true)
          this.set('model.initial_setup', true);
          this.get('model').save().then(function () {
            _this3.set('initial_setup_end', false);

            _this3.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Setup Completed!</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this3.get('model').rollbackAttributes();

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        }
      },
      updateAccounts: function updateAccounts(next) {
        var _this4 = this;

        this.get('model').save().then(function () {
          _this4.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Accounts Updated</span></div>'
          });

          if (next) {
            _this4.set('initial_setup_end', true);
          }
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this4.get('model').rollbackAttributes();

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      updateAccount: function updateAccount(value, attributeName) {
        this.set("model.".concat(attributeName), value);
      },
      back: function back() {
        this.set('initial_setup_end', false);
      },
      togglePaymentModal: function togglePaymentModal() {
        this.toggleProperty('paymentModal');
      },
      reloadRoute: function reloadRoute() {
        this.sendAction('reloadRoute');
      }
    }
  });

  _exports.default = _default;
});