define("account-berry-frontend/companies/show/vendors/480a/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    search: "",
    sortAscending: false,
    init: function init() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("a4806-channel-".concat(this.get('session.data.authenticated.id')));
      this.set('channel', channel);
      channel.bind('electronicFiling', function (response) {
        _this.get('applicationController').set('showLoading', true);

        _this.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          var element = document.createElement('a');
          var content = '';
          jsonStorage.get('stored_json.electronic_record_data').forEach(function (electronic_data) {
            content += electronic_data + '\n';
          });
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
          element.setAttribute('download', '4806a_electronic_filing.txt');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          _this.get('applicationController').set('showLoading', false);
        });
      });
      channel.bind('a4806', function (response) {
        _this.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this.set('a4806Data', jsonStorage.get('stored_json.a4806'));
        });
      });
    },
    filteredVendors: Ember.computed('a4806Data', 'search', 'sortAscending', 'hideInvalidVendors', function () {
      var _this2 = this;

      var filteredVendors = [];

      for (var key in this.get('a4806Data')) {
        if (key !== "electronicFillingNumber" && this.get("a4806Data.".concat(key, ".general_info"))) {
          var vendorData = this.get("a4806Data.".concat(key));
          var validVendor = this.get('hideInvalidVendors') ? this.validVendor(vendorData) : true;

          if (validVendor) {
            if (this.get('search') === "") {
              filteredVendors.push(vendorData);
            } else if ("".concat(vendorData.general_info.display_name || '').toLowerCase().includes(this.get('search').toLowerCase())) {
              filteredVendors.push(vendorData);
            }
          }
        }
      }

      if (this.get('sortBy')) {
        filteredVendors = filteredVendors.sort(function (a, b) {
          return a.general_info[_this2.get('sortBy')] > b.general_info[_this2.get('sortBy')];
        });
      }

      return this.get('sortAscending') ? filteredVendors.reverse() : filteredVendors;
    }),
    accounts: Ember.computed(function () {
      return this.store.findAll('account');
    }),
    commissionsAndFeesAccounts: Ember.computed(function () {
      return [undefined];
    }),
    rentAccounts: Ember.computed(function () {
      return [undefined];
    }),
    interestUnderSection102304Accounts: Ember.computed(function () {
      return [undefined];
    }),
    interestUnderSection102305Accounts: Ember.computed(function () {
      return [undefined];
    }),
    otherInterestsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    dividendsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    capitalGainDistributionsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    debtDischargeAccounts: Ember.computed(function () {
      return [undefined];
    }),
    royaltiesAccounts: Ember.computed(function () {
      return [undefined];
    }),
    paymentForSubscriptionsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    electricPowerAccounts: Ember.computed(function () {
      return [undefined];
    }),
    waterAndSewageAccounts: Ember.computed(function () {
      return [undefined];
    }),
    professionalAssociationFeesPaidAccounts: Ember.computed(function () {
      return [undefined];
    }),
    continuingEducationAccounts: Ember.computed(function () {
      return [undefined];
    }),
    homeownersAssociationFeesPaidAccounts: Ember.computed(function () {
      return [undefined];
    }),
    otherPaymentsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    grossProceedsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    inputAccounts: Ember.computed('accounts', 'commissionsAndFeesAccounts.@each', 'withholdingAccounts.@each', 'paymentsForServicesRenderedAccounts.@each', function () {
      var _this3 = this;

      var inputAccounts = [];
      var accountArrays = ['commissionsAndFeesAccounts', 'withholdingAccounts', 'paymentsForServicesRenderedAccounts'];
      accountArrays.forEach(function (arrName) {
        inputAccounts = inputAccounts.concat(_this3.get(arrName));
      });
      return inputAccounts;
    }),
    a4806PdfCoordinates: function a4806PdfCoordinates(vendorData) {
      var vendor = vendorData.general_info;

      if (vendor) {
        var commissionsAndFees = parseFloat(vendorData.commissions_and_fees) !== 0 ? parseFloat(vendorData.commissions_and_fees).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var rents = parseFloat(vendorData.rent) !== 0 ? parseFloat(vendorData.rent).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var interestUnderSection102304 = parseFloat(vendorData.interest_under_section102304) !== 0 ? parseFloat(vendorData.interest_under_section102304).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var interestUnderSection102305 = parseFloat(vendorData.interest_under_section102305) !== 0 ? parseFloat(vendorData.interest_under_section102305).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var otherInterests = parseFloat(vendorData.other_interests) !== 0 ? parseFloat(vendorData.other_interests).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var dividends = parseFloat(vendorData.dividends) !== 0 ? parseFloat(vendorData.dividends).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var capitalGainDistributions = parseFloat(vendorData.capital_gain_distributions) !== 0 ? parseFloat(vendorData.capital_gain_distributions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var debtDischarge = parseFloat(vendorData.debt_discharge) !== 0 ? parseFloat(vendorData.debt_discharge).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var royalties = parseFloat(vendorData.royalties) !== 0 ? parseFloat(vendorData.royalties).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var electricPower = parseFloat(vendorData.electric_power) !== 0 ? parseFloat(vendorData.electric_power).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var waterAndSewage = parseFloat(vendorData.water_and_sewage) !== 0 ? parseFloat(vendorData.water_and_sewage).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var professionalAssociationFeesPaid = parseFloat(vendorData.professional_association_fees_paid) !== 0 ? parseFloat(vendorData.professional_association_fees_paid).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var paymentForSubscriptions = parseFloat(vendorData.payment_for_subscriptions) !== 0 ? parseFloat(vendorData.payment_for_subscriptions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var continuingEducation = parseFloat(vendorData.continuing_education) !== 0 ? parseFloat(vendorData.continuing_education).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var homeownersAssociationFeesPaid = parseFloat(vendorData.homeowners_association_fees_paid) !== 0 ? parseFloat(vendorData.homeowners_association_fees_paid).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var otherPayments = parseFloat(vendorData.other_payments) !== 0 ? parseFloat(vendorData.other_payments).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var grossProceeds = parseFloat(vendorData.gross_proceeds) !== 0 ? parseFloat(vendorData.gross_proceeds).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var name,
            vendorName,
            vendorSsEin,
            commissionsAndFeesData,
            rentsData,
            interestUnderSection102304Data,
            interestUnderSection102305Data,
            dividendsData,
            otherInterestsData,
            capitalGainDistributionsData,
            debtDischargeData,
            royaltiesData,
            electricPowerData,
            waterAndSewageData,
            professionalAssociationFeesPaidData,
            continuingEducationData,
            homeownersAssociationFeesPaidData,
            otherPaymentsData,
            grossProceedsData,
            paymentForSubscriptionsData,
            moreThanOneHolderData = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]; // 19 items
        // if (this.year === "2019") {
        //   name = { value: this.get('company.name') || "", x: 35, y: 646, size: 10 }
        //   vendorName = { value: vendor.display_name, x: 35, y: 520, size: 10 }
        //   vendorSsEin = { value: vendor.company_ein || "", x: 35, y: 547, size: 10 }
        //   commissionsAndFeesData = { value: commissionsAndFees || "", x: 507, y: 664, size: 10 }
        //   rentsData = { value: rents || "", x: 507, y: 643, size: 10 }
        //   interestUnderSection102304Data = { value: interestUnderSection102304 || "", x: 507, y: 623, size: 10 }
        //   interestUnderSection102305Data = { value: interestUnderSection102305 || "", x: 507, y: 604, size: 10 }
        //   otherInterestsData = { value: "", x: 507, y: 590, size: 10 }
        //   dividendsData = { value: dividends || "", x: 507, y: 582, size: 10 }
        //   capitalGainDistributionsData = { value: capitalGainDistributions || "", x: 507, y: 560, size: 10 }
        //   debtDischargeData = { value: debtDischarge || "", x: 507, y: 539, size: 10 }
        //   royaltiesData = { value: royalties || "", x: 507, y: 519, size: 10 }
        //   electricPowerData = { value: electricPower || "", x: 507, y: 500, size: 10 }
        //   waterAndSewageData = { value: waterAndSewage || "", x: 507, y: 478, size: 10 }
        //   professionalAssociationFeesPaidData = { value: professionalAssociationFeesPaid || "", x: 507, y: 459, size: 10 }
        //   paymentForSubscriptionsData = { value: "", x: 507, y: 459, size: 10 }
        //   continuingEducationData = { value: continuingEducation || "", x: 507, y: 438, size: 10 }
        //   homeownersAssociationFeesPaidData = { value: homeownersAssociationFeesPaid || "", x: 507, y: 418, size: 10 }
        //   otherPaymentsData = { value: otherPayments || "", x: 507, y: 399, size: 10 }
        //   grossProceedsData = { value: grossProceeds || "", x: 507, y: 377, size: 10 }
        // } else if (this.year === "2020" || this.year === "2021") {

        if (this.year === "2023" || this.year === "2024") {
          name = {
            value: this.get('company.name') || "",
            x: 35,
            y: 650,
            size: 10
          };
          vendorName = {
            value: vendor.display_name,
            x: 35,
            y: 517,
            size: 10
          };
          vendorSsEin = {
            value: vendor.company_ein || "",
            x: 35,
            y: 543,
            size: 10
          };
          commissionsAndFeesData = {
            value: "",
            x: 507,
            y: 664,
            size: 10
          };
          rentsData = {
            value: rents || "",
            x: 507,
            y: 664,
            size: 10
          };
          interestUnderSection102304Data = {
            value: interestUnderSection102304 || "",
            x: 507,
            y: 643,
            size: 10
          };
          interestUnderSection102305Data = {
            value: interestUnderSection102305 || "",
            x: 507,
            y: 618,
            size: 10
          };
          otherInterestsData = {
            value: otherInterests || "",
            x: 507,
            y: 590,
            size: 10
          };
          dividendsData = {
            value: dividends || "",
            x: 507,
            y: 567,
            size: 10
          };
          capitalGainDistributionsData = {
            value: capitalGainDistributions || "",
            x: 507,
            y: 548,
            size: 10
          };
          debtDischargeData = {
            value: debtDischarge || "",
            x: 507,
            y: 523,
            size: 10
          };
          royaltiesData = {
            value: royalties || "",
            x: 507,
            y: 498,
            size: 10
          };
          electricPowerData = {
            value: "",
            x: 507,
            y: 480,
            size: 10
          };
          waterAndSewageData = {
            value: "",
            x: 507,
            y: 478,
            size: 10
          };
          professionalAssociationFeesPaidData = {
            value: professionalAssociationFeesPaid || "",
            x: 507,
            y: 480,
            size: 10
          };
          paymentForSubscriptionsData = {
            value: paymentForSubscriptions || "",
            x: 507,
            y: 450,
            size: 10
          };
          continuingEducationData = {
            value: "",
            x: 507,
            y: 430,
            size: 10
          };
          homeownersAssociationFeesPaidData = {
            value: homeownersAssociationFeesPaid || "",
            x: 507,
            y: 430,
            size: 10
          };
          otherPaymentsData = {
            value: otherPayments || "",
            x: 507,
            y: 400,
            size: 10
          };
          grossProceedsData = {
            value: grossProceeds || "",
            x: 507,
            y: 377,
            size: 10
          };
          moreThanOneHolderData = {
            value: vendor.moreThanOneHolder ? "X" : "",
            x: 37,
            y: 438,
            size: 10
          };
        } else {
          name = {
            value: this.get('company.name') || "",
            x: 35,
            y: 655,
            size: 10
          };
          vendorName = {
            value: vendor.display_name,
            x: 35,
            y: 510,
            size: 10
          };
          vendorSsEin = {
            value: vendor.company_ein || "",
            x: 35,
            y: 538,
            size: 10
          };
          commissionsAndFeesData = {
            value: "",
            x: 507,
            y: 664,
            size: 10
          };
          rentsData = {
            value: rents || "",
            x: 507,
            y: 664,
            size: 10
          };
          interestUnderSection102304Data = {
            value: interestUnderSection102304 || "",
            x: 507,
            y: 643,
            size: 10
          };
          interestUnderSection102305Data = {
            value: interestUnderSection102305 || "",
            x: 507,
            y: 618,
            size: 10
          };
          otherInterestsData = {
            value: otherInterests || "",
            x: 507,
            y: 590,
            size: 10
          };
          dividendsData = {
            value: dividends || "",
            x: 507,
            y: 567,
            size: 10
          };
          capitalGainDistributionsData = {
            value: capitalGainDistributions || "",
            x: 507,
            y: 548,
            size: 10
          };
          debtDischargeData = {
            value: debtDischarge || "",
            x: 507,
            y: 523,
            size: 10
          };
          royaltiesData = {
            value: royalties || "",
            x: 507,
            y: 498,
            size: 10
          };
          electricPowerData = {
            value: "",
            x: 507,
            y: 480,
            size: 10
          };
          waterAndSewageData = {
            value: "",
            x: 507,
            y: 478,
            size: 10
          };
          professionalAssociationFeesPaidData = {
            value: professionalAssociationFeesPaid || "",
            x: 507,
            y: 480,
            size: 10
          };
          paymentForSubscriptionsData = {
            value: paymentForSubscriptions || "",
            x: 507,
            y: 450,
            size: 10
          };
          continuingEducationData = {
            value: "",
            x: 507,
            y: 430,
            size: 10
          };
          homeownersAssociationFeesPaidData = {
            value: homeownersAssociationFeesPaid || "",
            x: 507,
            y: 430,
            size: 10
          };
          otherPaymentsData = {
            value: otherPayments || "",
            x: 507,
            y: 400,
            size: 10
          };
          grossProceedsData = {
            value: grossProceeds || "",
            x: 507,
            y: 377,
            size: 10
          };
        }

        return {
          ein: {
            value: this.get('company.ein') || "",
            x: 35,
            y: 671,
            size: 10
          },
          name: name,
          employerPostalAddressLineOne: {
            value: this.get('company.postal_address_line_one') || "",
            x: 35,
            y: 618,
            size: 8
          },
          employerPostalAddressLineTwo: {
            value: this.get('company.postal_address_line_two') || "",
            x: 35,
            y: 611,
            size: 8
          },
          employerPostalAddressLineThree: {
            value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
            x: 35,
            y: 600,
            size: 8
          },
          vendorSsEin: vendorSsEin,
          vendorName: vendorName,
          vendorAddressLineOne: {
            value: this.get('company.address_line_one') || "",
            x: 35,
            y: 490,
            size: 8
          },
          vendorAddressLineTwo: {
            value: this.get('company.address_line_two') || "",
            x: 35,
            y: 483,
            size: 8
          },
          vendorAddressLineThree: {
            value: "".concat(this.get('company.city') || "", " ").concat(this.get('company.state') || "", " ").concat(this.get('company.country') || "", " ").concat(this.get('company.zipcode') || ""),
            x: 35,
            y: 472,
            size: 8
          },
          bankAccountNumber: {
            value: vendorData.bank_account_number || "TEST",
            x: 113,
            y: 453,
            size: 10
          },
          controlNumber: {
            value: vendorData.control_number || "",
            x: 35,
            y: 381,
            size: 10
          },
          electronicFilingNumber: {
            value: this.get('a4806Data.electronicFilingNumber') ? this.get('a4806Data.electronicFilingNumber') : "",
            x: 474,
            y: 731,
            size: 10
          },
          commissionsAndFees: commissionsAndFeesData,
          rents: rentsData,
          interestUnderSection102304: interestUnderSection102304Data,
          interestUnderSection102305: interestUnderSection102305Data,
          otherInterests: otherInterestsData,
          dividends: dividendsData,
          capitalGainDistributions: capitalGainDistributionsData,
          debtDischarge: debtDischargeData,
          royalties: royaltiesData,
          electricPower: electricPowerData,
          waterAndSewage: waterAndSewageData,
          professionalAssociationFeesPaid: professionalAssociationFeesPaidData,
          paymentForSubscriptions: paymentForSubscriptionsData,
          continuingEducation: continuingEducationData,
          homeownersAssociationFeesPaid: homeownersAssociationFeesPaidData,
          otherPayments: otherPaymentsData,
          grossProceeds: grossProceedsData,
          moreThanOneHolder: moreThanOneHolderData
        };
      }
    },
    summaryPdfCoordinates: function summaryPdfCoordinates() {
      var totalPaid = parseFloat(this.get('a4806Data.totals.total')) !== 0 ? parseFloat(this.get('a4806Data.totals.total')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      var totalForms = 0;
      var taxPayerTypeYData = {
        "Individual": 255,
        "Partnership": 311,
        "Corporation": 367,
        "Estate or Trust": 436,
        "Others": 507
      };
      var a4806Data = this.get('a4806Data');

      for (var vendorId in a4806Data) {
        if (vendorId !== "id" && vendorId !== "electronicFilingNumber" && vendorId !== "taxPayerType" && vendorId !== "totals" && this.validVendor(a4806Data[vendorId])) {
          totalForms++;
        }
      }

      return {
        ein: {
          value: this.get('company.ein') || "",
          x: 27,
          y: 673,
          size: 10
        },
        name: {
          value: this.get('company.name') || "",
          x: 31,
          y: 653,
          size: 10
        },
        address: {
          value: "".concat(this.get('company.postal_address_line_one') || "", " ").concat(this.get('company.postal_address_line_two') || "", " ").concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 27,
          y: 625,
          size: 8
        },
        taxPayerType: {
          value: this.get('a4806Data.taxPayerType') ? "X" : "",
          x: taxPayerTypeYData[this.get('a4806Data.taxPayerType')] || 0,
          y: 678,
          size: 10
        },
        checkmark: {
          value: "X",
          x: 121,
          y: 588,
          size: 10
        },
        totalForms: {
          value: totalForms.toString(),
          x: 50,
          y: 600,
          size: 10
        },
        totalPaid: {
          value: totalPaid || "",
          x: 314,
          y: 600,
          size: 10
        },
        electronicFilingNumber: {
          value: this.get('a4806Data.electronicFilingNumber') ? this.get('a4806Data.electronicFilingNumber') : "",
          x: 466,
          y: 729,
          size: 10
        }
      };
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    validVendor: function validVendor(data) {
      var values = [data.commissions_and_fees, data.rent, data.interest_under_section102304, data.interest_under_section102305, data.dividends, data.capital_gain_distributions, data.debt_discharge, data.royalties, data.electric_power, data.water_and_sewage, data.professional_association_fees_paid, data.continuing_education, data.homeowners_association_fees_paid, data.other_payments, data.gross_proceeds];
      var valid = false;
      values.forEach(function (value) {
        if (!valid) {
          valid = value && parseFloat(value) !== 0;
        }
      });
      return valid;
    },
    a4806pdfProcess: function a4806pdfProcess(existingPdfDocBytes, _vendorId) {
      var _this4 = this;

      this.vendorSetup();
      var pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);

      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      pages[0].addFontDictionary('Helvetica', helveticaRef);
      var a4806Data = this.get('a4806Data'); // Copy first page

      var copyPage = function copyPage(page) {
        var copiedPage = PDFLib.PDFPage.fromDict(PDFLib.PDFDictionary.from(new Map(page.map), page.index));

        if (page.getMaybe('Contents')) {
          page.normalizeContents();
          var originalContents = page.Contents;
          var copiedContents = PDFLib.PDFArray.fromArray(originalContents.array.slice(), page.index);
          copiedPage.set('Contents', copiedContents);
        }

        return copiedPage;
      };

      var newPageIndex = 0;

      if (_vendorId) {
        // let newPage = copyPage(firstPage);
        // pdfDoc.insertPage(newPageIndex, newPage);
        var _pages = pdfDoc.getPages();

        var page = _pages[0];
        var pdfData = this.a4806PdfCoordinates(a4806Data[_vendorId]);
        var pageKeys = Object.keys(pdfData);
        var contentStream;
        pageKeys.forEach(function (k) {
          contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(pdfData["".concat(k)].value ? pdfData["".concat(k)].value.toString() : ""), {
            x: pdfData["".concat(k)].x,
            y: pdfData["".concat(k)].y,
            size: pdfData["".concat(k)].size,
            font: 'Helvetica',
            colorRgb: pdfData["".concat(k)].color ? pdfData["".concat(k)].color : [0, 0, 1]
          }));
          page.addContentStreams(pdfDoc.register(contentStream));
        });
      } else {
        var _loop = function _loop() {
          if (vendorId !== "id" && vendorId !== "electronicFilingNumber" && vendorId !== "taxPayerType" && vendorId !== "totals" && _this4.validVendor(a4806Data[vendorId])) {
            var newPage = copyPage(firstPage);
            pdfDoc.insertPage(newPageIndex, newPage);

            var _pages2 = pdfDoc.getPages();

            newPage = _pages2[newPageIndex];

            var _pdfData = _this4.a4806PdfCoordinates(a4806Data[vendorId]);

            var _pageKeys = Object.keys(_pdfData);

            var _contentStream;

            _pageKeys.forEach(function (k) {
              _contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_pdfData["".concat(k)].value ? _pdfData["".concat(k)].value.toString() : ""), {
                x: _pdfData["".concat(k)].x,
                y: _pdfData["".concat(k)].y,
                size: _pdfData["".concat(k)].size,
                font: 'Helvetica',
                colorRgb: _pdfData["".concat(k)].color ? _pdfData["".concat(k)].color : [0, 0, 1]
              }));
              newPage.addContentStreams(pdfDoc.register(_contentStream));
            });
          }
        };

        for (var vendorId in a4806Data) {
          _loop();
        }

        pdfDoc.removePage(pdfDoc.getPages().length - 7); //remove empty a4806 page
      }

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    summaryPdfProcess: function summaryPdfProcess(pdfDoc) {
      var _pdfDoc$embedStandard3 = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard4 = _slicedToArray(_pdfDoc$embedStandard3, 2),
          helveticaRef = _pdfDoc$embedStandard4[0],
          helveticaFont = _pdfDoc$embedStandard4[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var page = pages[0];
      var summaryPdfData = this.summaryPdfCoordinates();
      var summaryPageKeys = Object.keys(summaryPdfData);
      var contentStream;
      summaryPageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(summaryPdfData["".concat(k)].value ? summaryPdfData["".concat(k)].value.toString() : ""), {
          x: summaryPdfData["".concat(k)].x,
          y: summaryPdfData["".concat(k)].y,
          size: summaryPdfData["".concat(k)].size,
          font: 'Helvetica',
          colorRgb: summaryPdfData["".concat(k)].color ? summaryPdfData["".concat(k)].color : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    vendorSetup: function vendorSetup() {
      var vendors = this.get('a4806Data');
      var count = 1;

      for (var vendorId in vendors) {
        var vendor = vendors[vendorId];

        if (vendor.general_info) {
          var birthYear = vendor.general_info.birth_date ? vendor.general_info.birth_date.split("-")[0] : null;
          var age = birthYear ? parseFloat(this.get('year')) - parseFloat(birthYear) : null;
          var countLength = count.toString().length;
          var controlNumber = "0".repeat(9 - countLength) + count;
          this.set("a4806Data.".concat(vendorId, ".control_number"), controlNumber);

          if (age && age <= 26 && vendor.wages) {
            var wages = parseFloat(vendor.wages);
            var value = wages > 40000 ? 40000 : wages;
            this.set("a4806Data.".concat(vendorId, "._16Code"), "E");
            this.set("a4806Data.".concat(vendorId, "._16Value"), value);
          }

          count += 1;
        }
      }
    },
    electronicFiling: function electronicFiling() {
      var _this5 = this;

      var data = JSON.stringify({
        a4806: {
          a4806_id: this.get('a4806').id,
          company_id: this.companyId
        }
      });
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/a4806s/electronic_filing"),
        method: 'POST',
        contentType: 'application/json',
        data: data,
        successCallback: function successCallback(response) {// this.get('applicationController').set('showLoading', true);
        },
        errorCallback: function errorCallback(e) {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    // getA4806PDF: observer('a4806Data', function() {
    getA4806PDF: function getA4806PDF(vendorId) {
      var _this6 = this;

      if (this.get('a4806Data')) {
        fetch("https://comprobantes480.s3.amazonaws.com/480.6a_".concat(this.year, "_informativo.pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            _this6.a4806pdfProcess(new Uint8Array(f), vendorId);
          });
        });
      }
    },
    getSummaryPDF: function getSummaryPDF() {
      var _this7 = this;

      if (this.get('a4806Data')) {
        fetch("https://comprobantes480.s3.amazonaws.com/480.5_".concat(this.year, "_informativo.pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

            _this7.summaryPdfProcess(pdfDoc);
          });
        });
      }
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year);
        var a4806 = this.get('model').findBy('year', year);

        if (a4806) {
          this.set('a4806Data', a4806.get('json_data'));
          this.set('a4806Data.id', a4806.id);
          this.set('a4806Data.electronicFilingNumber', a4806.get('json_data.electronicFilingNumber'));
        }
      },
      setEditVendor: function setEditVendor(vendorId) {
        this.set('editVendor', vendorId ? this.get("a4806Data.".concat(vendorId)) : null);
      },
      saveA4806s: function saveA4806s(electronicFiling) {
        var _this8 = this;

        var a4806 = this.get('model').findBy('year', this.get('year'));

        if (a4806) {
          this.store.findRecord('a4806', a4806.id).then(function (a4806) {
            a4806.set('json_data', _this8.get('a4806Data'));
            a4806.save().then(function () {
              _this8.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>480.6A Saved</span></div>'
              });

              _this8.set('a4806', a4806);

              if (electronicFiling) {
                _this8.electronicFiling();
              }
            });
          });
        } else {
          var newSp4806 = this.store.createRecord('a4806', {
            company_id: this.get('companyId'),
            electronic_filing_number: this.get('a4806Data.electronicFilingNumber'),
            year: this.get('year'),
            json_data: this.get('a4806Data')
          });
          newSp4806.save().then(function (a4806) {
            _this8.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>480.6A Saved</span></div>'
            });

            _this8.set('a4806', a4806);

            if (electronicFiling) {
              _this8.electronicFiling();
            }
          });
        }
      },
      delete: function _delete() {
        var _this9 = this;

        var a4806 = this.get('model').findBy('year', this.get('year'));
        a4806.destroyRecord().then(function () {
          _this9.notify.alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(_this9.get('year'), " 480.6A Deleted</span></div>")
          });

          _this9.set('a4806Data', null);

          _this9.set('year', null);

          _this9.send('refreshModel');

          (0, _jquery.default)('#a4806YearSelect option')[0].selected = true;
        });
      },
      printVendorA4806: function printVendorA4806(vendorId) {
        this.getA4806PDF(vendorId);
      },
      setAccount: function setAccount(accountId, index, selectId) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#".concat(selectId).concat(index, " option"))[0].selected = true;
        } else {
          this.get("".concat(selectId)).replace(index, 1, [accountId]);

          if (!this.get("".concat(selectId)).includes(undefined)) {
            this.get("".concat(selectId)).pushObject(undefined);
            (0, _jquery.default)("#".concat(selectId).concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeAccount: function removeAccount(index, selectId) {
        if (this.get("".concat(selectId)).length === 1) {
          this.get("".concat(selectId)).replace(index, 1, [undefined]);
          (0, _jquery.default)("#".concat(selectId).concat(index, " option"))[0].selected = true;
        } else {
          this.get("".concat(selectId)).replace(index, 1, []);
        }
      },
      setCode: function setCode(vendorId) {
        var value = (0, _jquery.default)("#codeSelect-".concat(vendorId))[0].value;
        this.set("a4806Data.".concat(vendorId, ".code"), value);
      },
      calculate: function calculate() {
        var _this10 = this;

        var inputAccounts = {
          commissions_and_fees_accounts: this.get('commissionsAndFeesAccounts').filter(function (a) {
            return a;
          }),
          other_interests_accounts: this.get('otherInterestsAccounts').filter(function (a) {
            return a;
          }),
          rent_accounts: this.get('rentAccounts').filter(function (a) {
            return a;
          }),
          interest_under_section102304_accounts: this.get('interestUnderSection102304Accounts').filter(function (a) {
            return a;
          }),
          interest_under_section102305_accounts: this.get('interestUnderSection102305Accounts').filter(function (a) {
            return a;
          }),
          dividends_accounts: this.get('dividendsAccounts').filter(function (a) {
            return a;
          }),
          capital_gain_distributions_accounts: this.get('capitalGainDistributionsAccounts').filter(function (a) {
            return a;
          }),
          debt_discharge_accounts: this.get('debtDischargeAccounts').filter(function (a) {
            return a;
          }),
          royalties_accounts: this.get('royaltiesAccounts').filter(function (a) {
            return a;
          }),
          electric_power_accounts: this.get('electricPowerAccounts').filter(function (a) {
            return a;
          }),
          water_and_sewage_accounts: this.get('waterAndSewageAccounts').filter(function (a) {
            return a;
          }),
          professional_association_fees_paid_accounts: this.get('professionalAssociationFeesPaidAccounts').filter(function (a) {
            return a;
          }),
          payment_for_subscriptions_accounts: this.get('paymentForSubscriptionsAccounts').filter(function (a) {
            return a;
          }),
          continuing_education_accounts: this.get('continuingEducationAccounts').filter(function (a) {
            return a;
          }),
          homeowners_association_fees_paid_accounts: this.get('homeownersAssociationFeesPaidAccounts').filter(function (a) {
            return a;
          }),
          other_payments_accounts: this.get('otherPaymentsAccounts').filter(function (a) {
            return a;
          }),
          gross_proceeds_accounts: this.get('grossProceedsAccounts').filter(function (a) {
            return a;
          })
        };
        var data = JSON.stringify({
          a4806: {
            year: this.get('year'),
            input_accounts: inputAccounts
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/a4806s/calculate"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            _this10.get('applicationController').set('showLoading', true);

            _this10.get('channel').bind('calculate', function (response) {
              _this10.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
                _this10.set('a4806Data', jsonStorage.get('stored_json.a4806'));

                _this10.vendorSetup();

                _this10.get('applicationController').set('showLoading', false);
              });
            }); // this.set('a4806Data', response);

          },
          errorCallback: function errorCallback(e) {
            _this10.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      generateA4806: function generateA4806() {
        this.send('saveA4806s');
        this.getA4806PDF();
      },
      generateSummary: function generateSummary() {
        this.send('saveA4806s');
        this.getSummaryPDF();
      },
      sortVendors: function sortVendors(sortBy) {
        this.toggleProperty('sortAscending');
        this.set('sortBy', sortBy);
      }
    }
  });

  _exports.default = _default;
});