define("account-berry-frontend/companies/show/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    observeSession: Ember.observer('session', function () {
      this.send("sessionChanged");
    }),
    modelObserver: Ember.observer('model', function () {
      if (this.get('model')) {
        if (!this.get('model.app_message_read') && this.get('model.app_message')) {
          this.get('notify').info({
            html: "<div class=\"message-text\"><span>".concat(this.get('model.app_message'), "</span></div>"),
            closeAfter: null,
            classNames: ['app-message']
          });

          var _this = this;

          setTimeout(function () {
            (0, _jquery.default)('.app-message > .close').on('click', function () {
              _this.messageRead();
            });
          }, 500);
        }
      }
    }),
    messageRead: function messageRead() {
      this.store.findRecord('company', this.company.id, {
        backgroundReload: true
      }).then(function (company) {
        company.set('app_message_read', true);
        company.save().then(function () {}, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          console.error(errors);
        });
      });
    }
  });

  _exports.default = _default;
});