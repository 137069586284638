define("account-berry-frontend/companies/show/customers/customers-table/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newCustomer: false,
    customerForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    filter: "display_name",
    filters: Ember.computed(function () {
      return [{
        name: "DISPLAY NAME",
        value: "display_name"
      }, {
        name: "EIN",
        value: "company_ein"
      }, {
        name: "COMPANY",
        value: "company_name"
      }, {
        name: "NOTES",
        value: "notes"
      }];
    }),
    pagedCustomers: (0, _pagedArray.default)('filteredCustomers', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredCustomers', this.store.peekAll('customer').filter(function (customer) {
          return !customer.get('isDeleted');
        }));
        this.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalCustomers: Ember.computed('filteredCustomers', function () {
      return this.get('filteredCustomers.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("customer-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          _this2.updateCustomerList(data.customer);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var customerId = data.customer.id;
          delete data.customer.id;

          var customer = _this2.store.peekRecord('customer', customerId);

          customer.setProperties(data.customer);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var customer = _this2.store.peekRecord('customer', data.customer_id);

          customer.deleteRecord();

          _this2.set('filteredCustomers', _this2.store.peekAll('customer').filter(function (customer) {
            return !customer.get('isDeleted');
          }));

          _this2.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateCustomerList: function updateCustomerList(customer) {
      this.store.push({
        data: {
          id: customer.id,
          type: 'customer',
          attributes: customer
        }
      });
      this.set('filteredCustomers', this.store.peekAll('customer').filter(function (customer) {
        return !customer.get('isDeleted');
      }));
      this.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredCustomers: Ember.computed('model', function () {
      return this.model;
    }),
    filterCustomers: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('filteredCustomers', this.model);
      } else {
        this.set('filteredCustomers', this.model.filter(function (customer) {
          var customerValue = customer.get(filter);

          if (customerValue) {
            if (isNaN(customerValue)) {
              customerValue = customerValue.toLowerCase();
            }

            if (customerValue.includes(search)) {
              return customer;
            }
          }
        }));
      }

      this.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Customers</h3><table id='customersTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>DISPLAY NAME</th>";
      table += "<th style='text-align: left;'>EIN</th>";
      table += "<th style='text-align: left;'>COMPANY NAME</th>";
      table += "<th style='text-align: left;'>NOTES</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredCustomers').forEach(function (customer) {
        table += "<tr>";
        table += "<td>".concat(customer.get('display_name'), "</td>");
        table += "<td>".concat(customer.get('company_ein') || "", "</td>");
        table += "<td>".concat(customer.get('company_name'), "</td>");
        table += "<td>".concat(customer.get('notes') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Customers</h3><table id='customersTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>FIRST NAME</th>";
      table += "<th style='text-align: left;'>LAST NAME</th>";
      table += "<th style='text-align: left;'>DISPLAY NAME</th>";
      table += "<th style='text-align: left;'>COMPANY NAME</th>";
      table += "<th style='text-align: left;'>COMPANY EIN</th>";
      table += "<th style='text-align: left;'>WEBSITE</th>";
      table += "<th style='text-align: left;'>BILLING ADDRESS LINE 1</th>";
      table += "<th style='text-align: left;'>BILLING ADDRESS LINE 2</th>";
      table += "<th style='text-align: left;'>BILLING CITY</th>";
      table += "<th style='text-align: left;'>BILLING STATE</th>";
      table += "<th style='text-align: left;'>BILLING COUNTRY</th>";
      table += "<th style='text-align: left;'>BILLING ZIPCODE</th>";
      table += "<th style='text-align: left;'>SHIPPING ADDRESS LINE 1</th>";
      table += "<th style='text-align: left;'>SHIPPING ADDRESS LINE 2</th>";
      table += "<th style='text-align: left;'>SHIPPING CITY</th>";
      table += "<th style='text-align: left;'>SHIPPING STATE</th>";
      table += "<th style='text-align: left;'>SHIPPING COUNTRY</th>";
      table += "<th style='text-align: left;'>SHIPPING ZIPCODE</th>";
      table += "<th style='text-align: left;'>PHONE NUMBER</th>";
      table += "<th style='text-align: left;'>EMAIL ADDRESS</th>";
      table += "<th style='text-align: left;'>NOTES</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredCustomers').forEach(function (customer) {
        table += "<tr>";
        table += "<td>".concat(customer.get('first_name') || "", "</td>");
        table += "<td>".concat(customer.get('last_name') || "", "</td>");
        table += "<td>".concat(customer.get('display_name'), "</td>");
        table += "<td>".concat(customer.get('company_name'), "</td>");
        table += "<td>".concat(customer.get('company_ein') || "", "</td>");
        table += "<td>".concat(customer.get('website') || "", "</td>");
        table += "<td>".concat(customer.get('billing_address_line_one') || "", "</td>");
        table += "<td>".concat(customer.get('billing_address_line_two') || "", "</td>");
        table += "<td>".concat(customer.get('billing_city') || "", "</td>");
        table += "<td>".concat(customer.get('billing_state') || "", "</td>");
        table += "<td>".concat(customer.get('billing_country') || "", "</td>");
        table += "<td>".concat(customer.get('billing_zipcode') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_address_line_one') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_address_line_two') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_city') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_state') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_country') || "", "</td>");
        table += "<td>".concat(customer.get('shipping_zipcode') || "", "</td>");
        table += "<td>".concat(customer.get('phone') || "", "</td>");
        table += "<td>".concat(customer.get('email') || "", "</td>");
        table += "<td>".concat(customer.get('notes') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewCustomer: function toggleNewCustomer() {
        this.set('customer', {});
        this.toggleProperty('newCustomer');
        this.toggleProperty('customerForm');
      },
      toggleEditCustomer: function toggleEditCustomer(customer) {
        this.set('customer', customer);
        this.toggleProperty('customerForm');
      },
      cancelEdit: function cancelEdit() {
        this.customer.rollbackAttributes();
        this.toggleProperty('customerForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('customer', id, {
            backgroundReload: false
          }).then(function (customer) {
            self.set('customer', customer);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      create: function create() {
        var _this3 = this;

        this.set('customer.company_id', this.companyId);
        var newCustomer = this.store.createRecord('customer', this.customer);
        newCustomer.save().then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Customer Created</span></div>'
          });

          _this3.send('toggleNewCustomer');

          _this3.model.pushObject(newCustomer);

          _this3.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newCustomer.rollbackAttributes();

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this = this;

        this.store.findRecord('customer', this.customer.id, {
          backgroundReload: false
        }).then(function (customer) {
          customer.setProperties(_this.customer);
          customer.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Customer Updated</span></div>'
            });

            _this.send('toggleEditCustomer');
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      deleteCustomer: function deleteCustomer() {
        var _this4 = this;

        this.customer.destroyRecord().then(function () {
          _this4.set('filteredCustomers', _this4.store.peekAll('customer').filter(function (customer) {
            return !customer.get('isDeleted');
          }));

          _this4.setProperties({
            pagedCustomers: (0, _pagedArray.default)('filteredCustomers', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            customer: {},
            confirmDelete: false
          });

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Customer Deleted</span></div>"
          });
        }, function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this4.customer.rollbackAttributes();

          _this4.set('confirmDelete', false);

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedCustomers').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredCustomers', this.get('filteredCustomers').sortBy(property));
        } else {
          this.set('filteredCustomers', this.get('filteredCustomers').sortBy(property).reverse());
        }

        this.set('pagedCustomers', (0, _pagedArray.default)('filteredCustomers', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#customersTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_customers_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#customersTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});