define("account-berry-frontend/companies/show/reports/payroll-stubs/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment*/
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    init: function init() {// let channel = this.get('session.pusher').subscribe(`payroll-stubs-channel-${this.get('session.data.authenticated.id')}`);
      // channel.bind('report', (response) => {
      //   this.store.findRecord('json-storage', response.json_storage_id).then((jsonStorage) => {
      //     this.set('payrollStubsData', jsonStorage.get('stored_json.report'));
      //     this.set('filteredPayrollStubsData', jsonStorage.get('stored_json.report'));
      //     this.get('applicationController').set('showLoading', false);
      //   });
      // });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    generateTable: function generateTable(print) {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center;'>").concat(this.get('customer.display_name'), " Statement</h3>");
      table += "<h4 style='text-align: center;'>".concat(moment(this.get('date')).format('MMMM D, YYYY'), "</h4>");
      table += "<table id='statementTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: left;'>TYPE</th>";
      table += "<th style='text-align: left;'>REF. NUMBER</th>";
      table += "<th style='text-align: right'>AMOUNT</th></tr></thead><tbody>";
      this.get('statementData.transactions').forEach(function (transaction) {
        table += "<tr><td>".concat(moment(transaction.date).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(transaction.type, "</td>");
        table += "<td>".concat(transaction.number || "", "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(transaction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });

      if (print) {
        table += "<tr><td colspan='3'><strong>TOTAL</strong></td>";
      } else {
        table += "<tr><td><strong>TOTAL</strong></td><td></td><td></td>";
      }

      table += "<td style='text-align: right; border-top: 1px solid $light-grey;'><strong>".concat(parseFloat(this.get('statementData.total')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</strong></td></tr>");
      table += "</tbody></table></div>";
      return table;
    },
    filterPayrollStubs: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredPayrollStubsData', this.get('payrollStubsData'));
      } else {
        this.set('filteredPayrollStubsData', this.get('payrollStubsData').filter(function (employee) {
          var employeeValue = employee.name;

          if (employeeValue) {
            if (isNaN(employeeValue)) {
              employeeValue = employeeValue.toLowerCase();
            }

            if (employeeValue.includes(search)) {
              return employee;
            }
          }
        }));
      }
    }),
    actions: {
      getPayrollStubs: function getPayrollStubs() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'payroll_stubs'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "PayrollStubs" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-PayrollStubs');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);

              console.log('call happened');
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('payrollStubsData', data.report);

              _this2.set('filteredPayrollStubsData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-PayrollStubs disconnected");
          }
        });
      },
      print: function print() {
        // let table = this.generateTable(true);
        // $('.print-container').empty()
        // $('.print-container').append(table)
        (0, _jquery.default)('#payrollStubs').printThis(); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = (0, _jquery.default)('#payrollStubsTable');
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#payrollStubsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.get('fromDate'), "-").concat(this.get('toDate'), "PayrollStubsTable.csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#statementTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      },
      toggleConfirmSendStatement: function toggleConfirmSendStatement() {
        if (this.get('customer.email')) {
          this.toggleProperty('confirmSendStatement');
        } else {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>This customer does not have an email set up. Please update the customer's info to add a valid email.</span></div>"
          });
        }
      },
      sendStatement: function sendStatement() {
        var _this3 = this;

        var statement = this.generateTable(true).replace(/(\r\n\t|\n|\r\t)/gm, "");

        var _this$get2 = this.get('session.data.authenticated'),
            email = _this$get2.email,
            token = _this$get2.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/send_statements"), {
          method: 'POST',
          contentType: 'application/json',
          headers: headers,
          data: JSON.stringify({
            statement: statement,
            customer_email: this.get('customer.email')
          })
        }).then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success: </strong>&nbsp<span>Statement Sent</span></div>'
          });

          _this3.toggleProperty('confirmSendStatement');
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      }
    }
  });

  _exports.default = _default;
});