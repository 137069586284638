define("account-berry-frontend/companies/show/customers/invoices/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newInvoice: false,
    invoiceForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedInvoices: (0, _pagedArray.default)('filteredInvoices', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    labels: Ember.computed('model', function () {
      return this.store.peekAll('label');
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredInvoices', this.store.peekAll('invoice').filter(function (invoice) {
          return !invoice.get('isDeleted');
        }));
        this.set('pagedInvoices', (0, _pagedArray.default)('filteredInvoices', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalInvoices: Ember.computed('filteredInvoices', function () {
      return this.get('filteredInvoices.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("invoice-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateRepresentativeList(data.invoice);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var invoiceId = data.invoice.id;
          delete data.invoice.id;

          var invoice = _this.store.peekRecord('invoice', invoiceId);

          invoice.setProperties(data.invoice);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var invoice = _this.store.peekRecord('invoice', data.invoice_id);

          invoice.deleteRecord();

          _this.set('filteredInvoices', _this.store.peekAll('invoice').filter(function (invoice) {
            return !invoice.get('isDeleted');
          }));

          _this.set('pagedInvoices', (0, _pagedArray.default)('filteredInvoices', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateRepresentativeList: function updateRepresentativeList(invoice) {
      this.store.push({
        data: {
          id: invoice.id,
          type: 'invoice',
          attributes: invoice
        }
      });
      this.set('filteredInvoices', this.store.peekAll('invoice').filter(function (invoice) {
        return !invoice.get('isDeleted');
      }));
      this.set('pagedInvoices', (0, _pagedArray.default)('filteredInvoices', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filter: 'ref_number',
    filters: Ember.computed(function () {
      return [{
        name: "NUMBER",
        value: "ref_number"
      }, {
        name: "DEPOSIT DATE",
        value: "invoice_date"
      }, {
        name: "AMOUNT",
        value: "total_amount"
      }, {
        name: "CUSTOMER",
        value: "customer"
      }];
    }),
    filteredInvoices: Ember.computed('model', function () {
      return this.model;
    }),
    filterInvoices: Ember.observer('search', 'filterLabelIds', function () {
      var _this2 = this;

      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredInvoices', this.model);
      } else {
        this.set('filteredInvoices', this.model.filter(function (invoice) {
          var invoiceValue = invoice.get(filter);

          if (invoiceValue) {
            if (isNaN(invoiceValue)) {
              invoiceValue = invoiceValue.toLowerCase();
            }

            if (invoiceValue.includes(search)) {
              return invoice;
            }
          }
        }));
      }

      if (this.get('filterLabelIds') && this.get('filterLabelIds').length > 0) {
        var hasLabel;
        this.set('filteredInvoices', this.get('filteredInvoices').filter(function (invoice) {
          hasLabel = false;

          var _iterator = _createForOfIteratorHelper(_this2.get('filterLabelIds')),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var label = _step.value;
              hasLabel = invoice.get('labels').find(function (l) {
                return l.id.toString() === label.toString();
              });

              if (!hasLabel) {
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (hasLabel) {
            return invoice;
          }
        }));
      }

      this.set('pagedInvoices', (0, _pagedArray.default)('filteredInvoices', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['customers'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Invoices</h3><table id='invoicesTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: left;'>DUE DATE</th>";
      table += "<th style='text-align: left;'>CUSTOMER</th>";
      table += "<th style='text-align: center;'>PAID</th>";
      table += "<th style='text-align: right'>AMOUNT</th></tr></thead><tbody>";
      this.get('filteredInvoices').forEach(function (invoice) {
        table += "<td>".concat(invoice.get('ref_number'), "</td>");
        table += "<td>".concat(moment(invoice.get('invoice_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(moment(invoice.get('invoice_due_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(invoice.get('customer'), "</td>");
        table += "<td style='text-align: center;'>".concat(invoice.get('amount_due') === 0 ? 'YES' : 'NO', "</td>");
        table += "<td style='text-align: right'>".concat(invoice.get('total_amount').toFixed(2), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewInvoice: function toggleNewInvoice() {
        this.set('invoice', {});
        this.toggleProperty('newInvoice');
        this.toggleProperty('invoiceForm');
      },
      toggleEditInvoice: function toggleEditInvoice(invoice) {
        this.set('invoice', invoice);
        this.toggleProperty('invoiceForm');
      },
      cancelEdit: function cancelEdit() {
        this.invoice.rollbackAttributes();
        this.toggleProperty('invoiceForm');
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('invoice', id, {
            backgroundReload: false
          }).then(function (invoice) {
            self.set('invoice', invoice);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteInvoice: function deleteInvoice() {
        var _this3 = this;

        this.invoice.destroyRecord().then(function () {
          _this3.set('filteredInvoices', _this3.store.peekAll('invoice').filter(function (invoice) {
            return !invoice.get('isDeleted');
          }));

          _this3.setProperties({
            pagedInvoices: (0, _pagedArray.default)('filteredInvoices', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            invoice: {},
            confirmDelete: false
          });

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Invoice Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.invoice.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedInvoices').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredInvoices', this.get('filteredInvoices').sortBy(property));
        } else {
          this.set('filteredInvoices', this.get('filteredInvoices').sortBy(property).reverse());
        }

        this.set('pagedInvoices', (0, _pagedArray.default)('filteredInvoices', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#invoicesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_invoices_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#invoicesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});