define("account-berry-frontend/companies/show/vendors/landed-cost/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedLandedCosts: (0, _pagedArray.default)('filteredLandedCosts', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredLandedCosts', this.store.peekAll('landed-cost').filter(function (landedCost) {
          return !landedCost.get('isDeleted');
        }));
        this.set('pagedLandedCosts', (0, _pagedArray.default)('filteredLandedCosts', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalLandedCosts: Ember.computed('filteredLandedCosts', function () {
      return this.get('filteredLandedCosts.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("landed-cost-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateLandedCostList(data.landed_cost);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var landedCostId = data.landed_cost.id;
          delete data.landed_cost.id;

          var landedCost = _this.store.peekRecord('landed-cost', landedCostId);

          landedCost.setProperties(data.landed_cost);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var landedCost = _this.store.peekRecord('landed-cost', data.landed_cost_id);

          landedCost.deleteRecord();

          _this.set('filteredLandedCosts', _this.store.peekAll('landed-cost').filter(function (landedCost) {
            return !landedCost.get('isDeleted');
          }));

          _this.set('pagedLandedCosts', (0, _pagedArray.default)('filteredLandedCosts', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateLandedCostList: function updateLandedCostList(landedCost) {
      this.store.push({
        data: {
          id: landedCost.id,
          type: 'landed-cost',
          attributes: landedCost
        }
      });
      this.set('filteredLandedCosts', this.store.peekAll('landed-cost').filter(function (landedCost) {
        return !landedCost.get('isDeleted');
      }));
      this.set('pagedLandedCosts', (0, _pagedArray.default)('filteredLandedCosts', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredLandedCosts: Ember.computed('model', function () {
      return this.model;
    }),
    filterLandedCosts: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredLandedCosts', this.model);
      } else {
        this.set('filteredLandedCosts', this.model.filter(function (landedCost) {
          var landedCostValue = landedCost.get(filter);

          if (landedCostValue) {
            if (isNaN(landedCostValue)) {
              landedCostValue = landedCostValue.toLowerCase();
            }

            if (landedCostValue.includes(search)) {
              return landedCost;
            }
          }
        }));
      }

      this.set('pagedLandedCosts', (0, _pagedArray.default)('filteredLandedCosts', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterLandedCostsByDate: Ember.observer('fromDateFilter', 'toDateFilter', function () {
      var fromDateFilter = this.get('fromDateFilter');
      var toDateFilter = this.get('toDateFilter');

      if (fromDateFilter || toDateFilter) {
        this.set('filteredLandedCosts', this.get('model').sortBy('date').filter(function (landedCost) {
          if (fromDateFilter && toDateFilter) {
            if (landedCost.get('date') >= fromDateFilter.toISOString().slice(0, 10) && landedCost.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return landedCost;
            }
          } else if (fromDateFilter && !toDateFilter) {
            if (landedCost.get('date') >= fromDateFilter.toISOString().slice(0, 10)) {
              return landedCost;
            }
          } else if (!fromDateFilter && toDateFilter) {
            if (landedCost.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return landedCost;
            }
          }
        }));
      } else {
        this.set('filteredLandedCosts', this.get('model'));
      }

      this.set('pagedLandedCosts', (0, _pagedArray.default)('filteredLandedCosts', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center'>Landed Costs</h3><table id='landedCostsTable' style='width:100%; font-size: 14px'><thead><tr style='text-align: left;'>");
      table += "<th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: right;'>AMOUNT</th></tr></thead><tbody>";
      this.get('filteredLandedCosts').forEach(function (landedCost) {
        table += "<td>".concat(landedCost.get('ref_number') || "", "</td>");
        table += "<td>".concat(moment(landedCost.get('date')).format('MM/DD/YYYY'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(landedCost.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this2 = this;

        if (id) {
          this.store.findRecord('landed-cost', id, {
            backgroundReload: false
          }).then(function (landedCost) {
            _this2.set('landedCost', landedCost);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteLandedCost: function deleteLandedCost() {
        var _this3 = this;

        this.landedCost.destroyRecord().then(function () {
          _this3.set('filteredLandedCosts', _this3.store.peekAll('landed-cost').filter(function (landedCost) {
            return !landedCost.get('isDeleted');
          }));

          _this3.setProperties({
            pagedLandedCosts: (0, _pagedArray.default)('filteredLandedCosts', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            landedCost: {},
            confirmDelete: false
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this3.landedCost.rollbackAttributes();

          _this3.set('confirmDelete', false);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedLandedCosts').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#landedCostsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_landed_cost_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#landedCostsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});