define("account-berry-frontend/companies/show/reports/balance-sheet/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    includeUnassigned: false,
    reportType: 'labels',
    comparativeYears: Ember.computed(function () {
      return "1";
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    addReportSubData: function addReportSubData(account, table) {
      if (account.is_title) {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td></tr>");
        account.sub_accounts.forEach(function (subAccount) {
          table += "<tr><td>".concat(subAccount.number ? subAccount.number : "", " ").concat(subAccount.name, "</td>");
          subAccount.balance.forEach(function (balance) {
            table += "<td>".concat(parseFloat(balance).toFixed(2), "</td>");
          });
          table += "</tr>";
        });
        table += "<tr><td>Total ".concat(account.name, "</td>");
        account.total_balance.forEach(function (totalBalance) {
          table += "<td>".concat(parseFloat(totalBalance).toFixed(2), "</td>");
        });
        table += "</tr>";
      } else {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td>");
        account.balance.forEach(function (balance) {
          table += "<td>".concat(parseFloat(balance).toFixed(2), "</td>");
        });
        table += "</tr>";
      }

      return table;
    },
    actions: {
      getBalanceSheet: function getBalanceSheet() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date
          },
          report_type: 'balance_sheet',
          comparative_years: this.get('comparativeYears'),
          labels: labelIds
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var labelIds = this.get('labelIds');

        if (this.get('includeUnassigned') && labelIds && !labelIds.includes('unassigned')) {
          labelIds.push('unassigned');
        }

        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "BalanceSheet" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-BalanceSheet');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('balanceSheetData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-BalanceSheet disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this3 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this3.set('account', account); // this.transactionsActionCable();


          _this3.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      setComparativeYears: function setComparativeYears(years) {
        this.set('comparativeYears', years);
      },
      setLabels: function setLabels(labels) {
        this.set('labelIds', labels);
      },
      setReportType: function setReportType(type) {
        this.set('reportType', type);
        this.set('labelsReport', type === "labels");
        this.setProperties({
          balanceSheetData: null,
          date: null,
          accountTransactions: false
        });
      },
      print: function print() {
        // let divToPrint=document.getElementById("balanceSheet");
        // let newWin = window.open("");
        // newWin.document.write(divToPrint.outerHTML);
        // newWin.print();
        // newWin.close();
        (0, _jquery.default)('#balanceSheet').printThis();
      },
      csvExport: function csvExport() {
        var _this4 = this;

        var table = "<table id='balanceSheetTable' style='width:100%; font-size: 14px'><tbody>";

        if (this.get('comparativeYears') === "1") {
          table += "<tr><td>Balance Sheet ".concat(moment(this.get('date')).format('YYYY-MM-DD'), "</td></tr>");
        } else {
          table += "<tr><td>Balance Sheet</td></tr>";
          table += "<tr>";
          table += "<td></td>";
          this.get('balanceSheetData.years').forEach(function (year) {
            table += "<td>".concat(year, "</td>");
          });
          table += "</tr>";
        }

        table += "<tr><td>ASSETS</td></tr>";
        table += "<tr><td>Current Assets</td></tr>";
        this.get('balanceSheetData.current_assets').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Current Assets</td>";
        this.get('balanceSheetData.total_current_assets').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Fixed Assets</td></tr>";
        this.get('balanceSheetData.fixed_assets').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Fixed Assets</td>";
        this.get('balanceSheetData.total_fixed_assets').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Other Assets</td></tr>";
        this.get('balanceSheetData.other_assets').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Other Assets</td>";
        this.get('balanceSheetData.total_other_assets').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>TOTAL ASSETS</td>";
        this.get('balanceSheetData.total_assets').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>LIABILITIES AND EQUITY</td></tr>";
        table += "<tr><td>Liabilities</td></tr>";
        table += "<tr><td>Current Liabilities</td></tr>";
        this.get('balanceSheetData.current_liabilities').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Current Liabilities</td>";
        this.get('balanceSheetData.total_current_liabilities').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Long Term Liability</td></tr>";
        this.get('balanceSheetData.long_term_liability').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Long Term Liabilities</td>";
        this.get('balanceSheetData.total_long_term_liability').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Equity</td></tr>";
        this.get('balanceSheetData.equity').forEach(function (account) {
          table = _this4.addReportSubData(account, table);
        });
        table += "<tr><td>Total Equity</td>";
        this.get('balanceSheetData.total_equity').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>TOTAL LIABILITIES AND EQUITY</td>";
        this.get('balanceSheetData.total_liabilities_and_equity').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "</tbody></table>";
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#balanceSheetTable').table2CSV({
          delivery: 'download',
          filename: 'BalanceSheetTable.csv'
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#balanceSheetTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});