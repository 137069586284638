define("account-berry-frontend/companies/show/physical-inventory/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedPhysicalInventories: (0, _pagedArray.default)('filteredPhysicalInventories', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredPhysicalInventories', this.store.peekAll('physical-inventory').filter(function (physicalInventory) {
          return !physicalInventory.get('isDeleted');
        }));
        this.set('pagedPhysicalInventories', (0, _pagedArray.default)('filteredPhysicalInventories', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalPhysicalInventories: Ember.computed('filteredPhysicalInventories', function () {
      return this.get('filteredPhysicalInventories.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("physical-inventory-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updatePhysicalInventoryList(data.physical_inventory);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var physicalInventoryId = data.physical_inventory.id;
          delete data.physical_inventory.id;

          var physicalInventory = _this.store.peekRecord('physical-inventory', physicalInventoryId);

          physicalInventory.setProperties(data.physical_inventory);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var physicalInventory = _this.store.peekRecord('physical-inventory', data.physical_inventory_id);

          physicalInventory.deleteRecord();

          _this.set('filteredPhysicalInventories', _this.store.peekAll('physical-inventory').filter(function (physicalInventory) {
            return !physicalInventory.get('isDeleted');
          }));

          _this.set('pagedPhysicalInventories', (0, _pagedArray.default)('filteredPhysicalInventories', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updatePhysicalInventoryList: function updatePhysicalInventoryList(physicalInventory) {
      this.store.push({
        data: {
          id: physicalInventory.id,
          type: 'physical-inventory',
          attributes: physicalInventory
        }
      });
      this.set('filteredPhysicalInventories', this.store.peekAll('physical-inventory').filter(function (physicalInventory) {
        return !physicalInventory.get('isDeleted');
      }));
      this.set('pagedPhysicalInventories', (0, _pagedArray.default)('filteredPhysicalInventories', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredPhysicalInventories: Ember.computed('model', function () {
      return this.model;
    }),
    filter: "ref_number",
    filters: [{
      name: "NUMBER",
      value: "ref_number"
    }, {
      name: "DATE",
      value: "date"
    }],
    filterPhysicalInventories: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredPhysicalInventories', this.model);
      } else {
        this.set('filteredPhysicalInventories', this.model.filter(function (physicalInventory) {
          var physicalInventoryValue = physicalInventory.get(filter);

          if (physicalInventoryValue) {
            if (isNaN(physicalInventoryValue)) {
              physicalInventoryValue = physicalInventoryValue.toLowerCase();
            }

            if (physicalInventoryValue.includes(search)) {
              return physicalInventory;
            }
          }
        }));
      }

      this.set('pagedPhysicalInventories', (0, _pagedArray.default)('filteredPhysicalInventories', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterPhysicalInventoriesByDate: Ember.observer('fromDateFilter', 'toDateFilter', function () {
      var fromDateFilter = this.get('fromDateFilter');
      var toDateFilter = this.get('toDateFilter');

      if (fromDateFilter || toDateFilter) {
        this.set('filteredPhysicalInventories', this.get('model').sortBy('date').filter(function (physicalInventory) {
          if (fromDateFilter && toDateFilter) {
            if (physicalInventory.get('date') >= fromDateFilter.toISOString().slice(0, 10) && physicalInventory.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return physicalInventory;
            }
          } else if (fromDateFilter && !toDateFilter) {
            if (physicalInventory.get('date') >= fromDateFilter.toISOString().slice(0, 10)) {
              return physicalInventory;
            }
          } else if (!fromDateFilter && toDateFilter) {
            if (physicalInventory.get('date') <= toDateFilter.toISOString().slice(0, 10)) {
              return physicalInventory;
            }
          }
        }));
      } else {
        this.set('filteredPhysicalInventories', this.get('model'));
      }

      this.set('pagedPhysicalInventories', (0, _pagedArray.default)('filteredPhysicalInventories', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3>");
      table += "<h3 style='text-align: center;'>Physical Inventory</h3><table id='physicalInventoriesTable' style='width:100%; font-size: 14px'>";
      table += "<thead><tr><th style='text-align: left;'>REFERENCE NO.</th>";
      table += "<th style='text-align: left;'>DATE</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredPhysicalInventories').forEach(function (physicalInventory) {
        table += '<tr>';
        table += "<td>".concat(physicalInventory.get('ref_number'), "</td>");
        table += "<td>".concat(moment(physicalInventory.get('date')).format('MM/DD/YYYY'), "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        var _this2 = this;

        if (id) {
          this.store.findRecord('physical-inventory', id, {
            backgroundReload: false
          }).then(function (physicalInventory) {
            _this2.set('physicalInventory', physicalInventory);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deletePhysicalInventory: function deletePhysicalInventory() {
        var _this3 = this;

        this.physicalInventory.destroyRecord().then(function () {
          _this3.set('filteredPhysicalInventories', _this3.store.peekAll('physical-inventory').filter(function (physicalInventory) {
            return !physicalInventory.get('isDeleted');
          }));

          _this3.setProperties({
            pagedPhysicalInventories: (0, _pagedArray.default)('filteredPhysicalInventories', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            physicalInventory: {},
            confirmDelete: false
          });

          var company = _this3.store.peekRecord('company', _this3.get('companyId'));

          company.set('counts.physical_inventory_count', company.get('counts.physical_inventory_count') - 1);

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Physical Inventory Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedPhysicalInventories').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#physicalInventoriesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_physical_inventories_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#physicalInventoriesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});