define("account-berry-frontend/companies/show/vendors/vendors-table/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newVendor: false,
    vendorForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    filter: "display_name",
    filters: [{
      name: "DISPLAY NAME",
      value: "display_name"
    }, {
      name: "COMPANY",
      value: "company_name"
    }, {
      name: "EIN",
      value: "ein"
    }, {
      name: "EMAIL",
      value: "email"
    }],
    pagedVendors: (0, _pagedArray.default)('filteredVendors', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredVendors', this.store.peekAll('vendor').filter(function (vendor) {
          return !vendor.get('isDeleted');
        }));
        this.set('pagedVendors', (0, _pagedArray.default)('filteredVendors', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalVendors: Ember.computed('filteredVendors', function () {
      return this.get('filteredVendors.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("vendor-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateVendorList(data.vendor);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var vendorId = data.vendor.id;
          delete data.vendor.id;

          var vendor = _this.store.peekRecord('vendor', vendorId);

          vendor.setProperties(data.vendor);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var vendor = _this.store.peekRecord('vendor', data.vendor_id);

          vendor.deleteRecord();

          _this.set('filteredVendors', _this.store.peekAll('vendor').filter(function (vendor) {
            return !vendor.get('isDeleted');
          }));

          _this.set('pagedVendors', (0, _pagedArray.default)('filteredVendors', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateVendorList: function updateVendorList(vendor) {
      this.store.push({
        data: {
          id: vendor.id,
          type: 'vendor',
          attributes: vendor
        }
      });
      this.set('filteredVendors', this.store.peekAll('vendor').filter(function (vendor) {
        return !vendor.get('isDeleted');
      }));
      this.set('pagedVendors', (0, _pagedArray.default)('filteredVendors', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filteredVendors: Ember.computed('model', function () {
      return this.model;
    }),
    filterVendors: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredVendors', this.model);
      } else {
        this.set('filteredVendors', this.model.filter(function (vendor) {
          var vendorValue = vendor.get(filter);

          if (vendorValue) {
            if (isNaN(vendorValue)) {
              vendorValue = vendorValue.toLowerCase();
            }

            if (vendorValue.includes(search)) {
              return vendor;
            }
          }
        }));
      }

      this.set('pagedVendors', (0, _pagedArray.default)('filteredVendors', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Vendors</h3><table id='vendorsTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>DISPLAY NAME</th>");
      table += "<th style='text-align: left;'>COMPANY</th>";
      table += "<th style='text-align: left;'>EIN</th>";
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>NOTES</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredVendors').forEach(function (vendor) {
        table += "<tr><td>".concat(vendor.get('display_name'), "</td>");
        table += "<td>".concat(vendor.get('company_name'), "</td>");
        table += "<td>".concat(vendor.get('company_ein') || "", "</td>");
        table += "<td>".concat(vendor.get('email') || "", "</td>");
        table += "<td>".concat(vendor.get('notes') || "", "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center;'>Vendors</h3><table id='vendorsTable' style='width:100%; font-size: 14px'>");
      table += "<thead><tr><th style='text-align: left;'>FIRST NAME</th>";
      table += "<th style='text-align: left;'>LAST NAME</th>";
      table += "<th style='text-align: left;'>DISPLAY NAME</th>";
      table += "<th style='text-align: left;'>COMPANY NAME</th>";
      table += "<th style='text-align: left;'>COMPANY EIN</th>";
      table += "<th style='text-align: left;'>WEBSITE</th>";
      table += "<th style='text-align: left;'>ADDRESS LINE 1</th>";
      table += "<th style='text-align: left;'>ADDRESS LINE 2</th>";
      table += "<th style='text-align: left;'>CITY</th>";
      table += "<th style='text-align: left;'>STATE</th>";
      table += "<th style='text-align: left;'>COUNTRY</th>";
      table += "<th style='text-align: left;'>ZIPCODE</th>";
      table += "<th style='text-align: left;'>PHONE NUMBER</th>";
      table += "<th style='text-align: left;'>EMAIL ADDRESS</th>";
      table += "<th style='text-align: left;'>NOTES</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredVendors').forEach(function (vendor) {
        table += "<tr>";
        table += "<td>".concat(vendor.get('first_name') || "", "</td>");
        table += "<td>".concat(vendor.get('last_name') || "", "</td>");
        table += "<td>".concat(vendor.get('display_name'), "</td>");
        table += "<td>".concat(vendor.get('company_name'), "</td>");
        table += "<td>".concat(vendor.get('company_ein') || "", "</td>");
        table += "<td>".concat(vendor.get('website') || "", "</td>");
        table += "<td>".concat(vendor.get('address_line_one') || "", "</td>");
        table += "<td>".concat(vendor.get('address_line_two') || "", "</td>");
        table += "<td>".concat(vendor.get('city') || "", "</td>");
        table += "<td>".concat(vendor.get('state') || "", "</td>");
        table += "<td>".concat(vendor.get('country') || "", "</td>");
        table += "<td>".concat(vendor.get('zipcode') || "", "</td>");
        table += "<td>".concat(vendor.get('phone') || "", "</td>");
        table += "<td>".concat(vendor.get('email') || "", "</td>");
        table += "<td>".concat(vendor.get('notes') || "", "</td>");
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('vendor', id, {
            backgroundReload: false
          }).then(function (vendor) {
            self.set('vendor', vendor);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteVendor: function deleteVendor() {
        var _this2 = this;

        this.vendor.destroyRecord().then(function () {
          _this2.set('filteredVendors', _this2.store.peekAll('vendor').filter(function (vendor) {
            return !vendor.get('isDeleted');
          }));

          _this2.setProperties({
            pagedVendors: (0, _pagedArray.default)('filteredVendors', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            vendor: {},
            confirmDelete: false
          });

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Vendor Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this2.vendor.rollbackAttributes();

          _this2.set('confirmDelete', false);

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedVendors').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredVendors', this.get('filteredVendors').sortBy(property));
        } else {
          this.set('filteredVendors', this.get('filteredVendors').sortBy(property).reverse());
        }

        this.set('pagedVendors', (0, _pagedArray.default)('filteredVendors', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#vendorsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_vendors_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#vendorsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});