define("account-berry-frontend/companies/show/reports/reconciliation-report/controller", ["exports", "jquery", "account-berry-frontend/config/environment", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _environment, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    sortAscending: false,
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    pagedClearedTransactions: (0, _pagedArray.default)('clearedTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100000
    }),
    pagedUnclearedTransactions: (0, _pagedArray.default)('unclearedTransactions', {
      infinite: "unpaged",
      page: 1,
      perPage: 100000
    }),
    totalClearedTransactions: Ember.computed.alias('pagedClearedTransactions.length'),
    totalUnclearedTransactions: Ember.computed.alias('pagedUnclearedTransactions.length'),
    setBalances: function setBalances() {
      // let clearedTransactions = this.get('clearedTransactions');
      // let unclearedTransactions = this.get('unclearedTransactions');
      var clearedBalance = 0;
      this.get('clearedTransactions').forEach(function (transaction) {
        if (transaction.account_action === 'debit') {
          clearedBalance = clearedBalance + parseFloat(transaction.amount);
        } else {
          clearedBalance = clearedBalance - parseFloat(transaction.amount);
        }

        Ember.set(transaction, 'balance', clearedBalance);
      });
      var unclearedBalance = 0;
      this.get('unclearedTransactions').forEach(function (transaction) {
        if (transaction.account_action === 'debit') {
          unclearedBalance = unclearedBalance + parseFloat(transaction.amount);
        } else {
          unclearedBalance = unclearedBalance - parseFloat(transaction.amount);
        }

        Ember.set(transaction, 'balance', unclearedBalance);
      });
      this.set('pagedUnclearedTransactions', (0, _pagedArray.default)('unclearedTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100000
      }));
      this.set('pagedClearedTransactions', (0, _pagedArray.default)('clearedTransactions', {
        infinite: "unpaged",
        page: 1,
        perPage: 100000
      }));
    },
    cashAccounts: Ember.computed('accounts', function () {
      if (this.get('accounts')) {
        var cashAccounts = this.get('accounts').filter(function (account) {
          if (account.get('account_type.name').toLowerCase() === 'cash' && !account.get('is_title')) {
            return account;
          }
        });

        if (cashAccounts.length === 1 && !this.get('reconciliation.cash_account_id')) {
          this.send('updateCashAccount', cashAccounts[0].get('id'));
          var selectOptions = (0, _jquery.default)("#selectCashAccount option");
          selectOptions[0].text = cashAccounts[0].get('name');
          selectOptions[0].value = cashAccounts[0].get('id');
          selectOptions[0].selected = true;
        }

        return cashAccounts;
      }
    }),
    reconciliations: Ember.computed('model', 'cashAccountId', function () {
      var _this2 = this;

      if (this.get('model') && this.get('cashAccountId')) {
        return this.get('model').filter(function (reconciliation) {
          return reconciliation.get('cash_account_id').toString() === _this2.get('cashAccountId').toString();
        });
      }
    }),
    // reconciliationDataObserver: Ember.observer('reconciliationData', function() {
    //   if (this.get('reconciliationData.length') > 0) {
    //     this.setProperties({
    //       'unclearedTransactions': pagedArray('reconciliationData.uncleared_transactions', { infinite: "unpaged", page: 1, perPage: 100 }),
    //       'clearedTransactions': pagedArray('reconciliationData.cleared_transactions', { infinite: "unpaged", page: 1, perPage: 100 }),
    //     });
    //   }
    // }),
    clearData: function clearData() {
      this.setProperties({
        reconciliationData: null,
        clearedTransactions: null,
        unclearedTransactions: null
      });
    },
    actions: {
      getReconciliationReport: function getReconciliationReport() {
        var _this3 = this;

        this.clearData();
        var data = JSON.stringify({
          report: {
            id: this.get('reconciliation.id')
          },
          report_type: 'reconciliation_report'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ReconciliationReport" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ReconciliationReport');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('reconciliationData', data.report);

              _this3.set('unclearedTransactions', data.report.uncleared_transactions);

              _this3.set('clearedTransactions', data.report.cleared_transactions);

              _this3.setBalances();

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ReconciliationReport disconnected");
          }
        });
      },
      updateCashAccount: function updateCashAccount(value) {
        this.set('cashAccountId', value);
        this.set('cashAccount', this.store.peekRecord('account', this.get('cashAccountId')));
      },
      setReconciliation: function setReconciliation(id) {
        this.set('reconciliation', this.store.peekRecord('reconciliation', id));
      },
      print: function print() {
        (0, _jquery.default)('#reconciliationReport').printThis();
      },
      csvExport: function csvExport() {
        var clearedTransactions = (0, _jquery.default)('#clearedTransactions').table2CSV({
          delivery: 'return'
        });
        var unclearedTransactions = (0, _jquery.default)('#unclearedTransactions').table2CSV({
          delivery: 'return'
        });
        clearedTransactions = "\"".concat(this.get('cashAccount').get('number'), " ").concat(this.get('cashAccount').get('name'), "\"\n\"").concat(moment(this.get('reconciliation.date')).format('MMMM D, YYYY'), "\"\n\"Beginning Balance: ").concat(this.get('reconciliationData.beginning_balance'), "\"\n\n\"\",\"\",\"\",\"CLEARED TRANSACTIONS\" \n\"Total Items: ").concat(this.get('totalClearedTransactions'), "\"\n\n") + clearedTransactions;
        unclearedTransactions = "\n\n\"\",\"\",\"\",\"UNCLEARED TRANSACTIONS\" \n\"Total Items: ".concat(this.get('totalUnclearedTransactions'), "\"\n\n ") + unclearedTransactions;
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(clearedTransactions + unclearedTransactions));
        element.setAttribute('download', 'ReconciliationReport.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');
        var sortedClearedTransactions = this.get('clearedTransactions').sort(function (a, b) {
          return a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0;
        });
        var sortedUnclearedTransactions = this.get('unclearedTransactions').sort(function (a, b) {
          return a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0;
        });

        if (this.get('sortAscending')) {
          this.set('clearedTransactions', sortedClearedTransactions);
          this.set('unclearedTransactions', sortedUnclearedTransactions);
        } else {
          this.set('clearedTransactions', sortedClearedTransactions.reverse());
          this.set('unclearedTransactions', sortedUnclearedTransactions.reverse());
        }

        this.setBalances();
      },
      clearedLoadNext: function clearedLoadNext() {
        this.get('pagedClearedTransactions').loadNext();
      },
      unclearedLoadNext: function unclearedLoadNext() {
        this.get('pagedUnclearedTransactions').loadNext();
      }
    }
  });

  _exports.default = _default;
});