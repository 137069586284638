define("account-berry-frontend/companies/show/reports/vendor-tax-detail/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    showTransactions: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    init: function init() {
      var _this2 = this;

      _jquery.default.initialize('.vendor-tax-detail-report-table', function () {
        _this2.setTable();
      });
    },
    setTable: function setTable() {
      var printableTable = (0, _jquery.default)('.report-table').clone();
      printableTable.css('font-size', '1vw');
      this.set('printableTable', printableTable); // $('.vendor-tax-detail-report-table').tableHeadFixer({
      //   // fix table header
      //   head: true,
      //   // fix table footer
      //   foot: false,
      //   // fix x left columns
      //   left: 0,
      //   // fix x right columns
      //   right: 0,
      //   // z-index
      //   'z-index': 0
      // });
    },
    actions: {
      toggleShowTransactions: function toggleShowTransactions() {
        this.toggleProperty('showTransactions');
      },
      getVendorTaxDetailData: function getVendorTaxDetailData() {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'vendor_tax_detail'
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reports"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this3.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            var payload = e.payload;

            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(payload ? payload.errors : 'An error occured', "</span></div>")
            });
          }
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "VendorTaxDetail" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-VendorTaxDetail');

            _this.get('ajaxCall').request(ajaxOptions);
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('vendorTaxDetailData', data.report);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-VendorTaxDetail disconnected");
          }
        });
      },
      print: function print() {
        this.setTable();
        this.printableTable.printThis({
          pageTitle: "".concat(this.get('company.name'), " - Vendor Tax Detail Report")
        });
      },
      csvExport: function csvExport() {
        (0, _jquery.default)('.vendor-tax-detail-report-table').table2CSV({
          delivery: 'download',
          filename: 'vendor_tax_detail_report.csv'
        });
      }
    }
  });

  _exports.default = _default;
});