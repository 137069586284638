define("account-berry-frontend/components/journal-entry-form/component", ["exports", "ember-cli-pagination/computed/paged-array", "jquery", "account-berry-frontend/config/environment"], function (_exports, _pagedArray, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    refNumber: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    journalEntryAmount: Ember.computed.alias('journalEntry.amount'),
    init: function init() {
      this._super();

      if (!this.get('journalEntry')) {
        this.set('journalEntry', {});
      }

      if (this.newJournalEntry) {
        this.generateJeNumber();
      }
    },
    generateJeNumber: function generateJeNumber() {
      var _this2 = this;

      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/journal_entries/number"),
        method: 'GET',
        contentType: 'application/json',
        successCallback: function successCallback(response) {
          var jeNumber = _this2.get('refNumber').generate(response.last_je_number || "");

          _this2.set('journalEntry.je_number', jeNumber);
        },
        errorCallback: function errorCallback(response) {
          _this2.errorHandler(response);
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    errorHandler: function errorHandler(response) {
      var errors = "An Unkown Error Occured.";

      if (response) {
        if (response.errors && response.errors[0].status !== "500") {
          errors = response.errors.map(function (error) {
            return error;
          });
        } else if (response.payload.errors) {
          errors = response.payload.errors.map(function (error) {
            return error;
          });
        }
      }

      this.get('notify').alert({
        html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
      });
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      var companyId = this.companyId;
      this.store.findAll('account').then(function (accounts) {
        var filteredAccounts = accounts.toArray().filter(function (account) {
          return account.get('company_id').toString() === companyId.toString();
        });

        _this3.set('accounts', filteredAccounts);
      });
      this.store.findAll('customer').then(function (customers) {
        var filteredCustomers = customers.toArray().filter(function (customer) {
          return customer.get('company_id').toString() === companyId.toString();
        });

        _this3.set('customers', filteredCustomers);
      });
      this.store.findAll('vendor').then(function (vendors) {
        var filteredVendors = vendors.toArray().filter(function (vendor) {
          return vendor.get('company_id').toString() === companyId.toString();
        });

        _this3.set('vendor', filteredVendors);
      });
      this.store.findAll('payee').then(function (payees) {
        var filteredPayees = payees.toArray().filter(function (payee) {
          return payee.get('company_id').toString() === companyId.toString();
        });

        _this3.set('payees', filteredPayees);
      });
      this.store.findAll('item').then(function (items) {
        var filteredItems = items.toArray().filter(function (item) {
          return item.get('company_id').toString() === companyId.toString();
        });

        _this3.set('items', filteredItems);
      });
      this.store.findAll('label', {
        reload: true
      }).then(function (labels) {
        _this3.set('labels', labels);
      });
    },
    inputAccounts: Ember.computed('journalEntry.journal_entry_accounts', function () {
      var accountJournalEntries = this.get('journalEntry.journal_entry_accounts');

      var _this = this;

      var jeaClear = false;

      if (accountJournalEntries) {
        accountJournalEntries.map(function (jea) {
          if (jea.account_action === "debit") {
            Ember.set(jea, 'debitAmount', parseFloat(jea.amount).toFixed(2));
          } else {
            Ember.set(jea, 'creditAmount', parseFloat(jea.amount).toFixed(2));
          }

          if (jea.customer_id) {
            var customer = _this.store.peekRecord('customer', jea.customer_id);

            if (customer) {
              Ember.set(jea, 'accountsReceivableSelected', true);
              Ember.set(jea, 'customer', customer.get('name'));
            }
          } else if (jea.vendor_id) {
            var vendor = _this.store.peekRecord('vendor', jea.vendor_id);

            if (vendor) {
              Ember.set(jea, 'vendor', vendor.get('name'));
              Ember.set(jea, 'accountsPayableSelected', true);
            }
          } else if (jea.item_id) {
            var item = _this.store.peekRecord('item', jea.item_id);

            if (item) {
              Ember.set(jea, 'item', item.get('name'));
              Ember.set(jea, 'inventorySelected', true);
            }
          }

          if (jea.clear && !jeaClear) {
            (0, _jquery.default)('.journal_entry_date').prop('disabled', true);
          }
        }); // this.set('anyTransactionCleared', jeaClear)

        return accountJournalEntries;
      } else {
        return [{}, {}];
      }
    }),
    debitTotal: 0.00,
    creditTotal: 0.00,
    debitTotalSetter: function debitTotalSetter() {
      var debitTotal = 0;
      this.get('inputAccounts').map(function (account) {
        if (account.debitAmount && !account.removed) {
          debitTotal += Number(account.debitAmount);
        }
      });
      this.set('debitTotal', debitTotal.toFixed(2));
    },
    creditTotalSetter: function creditTotalSetter() {
      var creditTotal = 0;
      this.get('inputAccounts').map(function (account) {
        if (account.creditAmount && !account.removed) {
          creditTotal += Number(account.creditAmount);
        }
      });
      this.set('creditTotal', creditTotal.toFixed(2));
    },
    pagedInputAccounts: (0, _pagedArray.default)('inputAccounts', {
      infinite: "unpaged",
      page: 1,
      perPage: 1000
    }),
    inputAccountsObserver: Ember.observer('inputAccounts', function () {
      this.debitTotalSetter();
      this.creditTotalSetter();

      try {
        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 1000
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    formatCurrency: function formatCurrency(amount) {
      return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    },
    actions: {
      updateJournalEntryDate: function updateJournalEntryDate(date) {
        this.set('journalEntry.date', date.toISOString().slice(0, 10));
      },
      updateAccountList: function updateAccountList(id, index, account) {
        var accountRecord = this.store.peekRecord('account', id); // this.set('account', accountRecord);

        Ember.set(account, 'account_id', id);

        switch (accountRecord.get('reference')) {
          case 'Accounts Receivable':
            Ember.set(account, 'accountsReceivableSelected', true);
            Ember.set(account, 'inventorySelected', false);
            Ember.set(account, 'accountsPayableSelected', false);
            break;

          case 'Accounts Payable':
            Ember.set(account, 'accountsReceivableSelected', false);
            Ember.set(account, 'inventorySelected', false);
            Ember.set(account, 'accountsPayableSelected', true);
            break;

          case 'Inventory':
            Ember.set(account, 'accountsReceivableSelected', false);
            Ember.set(account, 'accountsPayableSelected', false);
            Ember.set(account, 'inventorySelected', true);
            break;

          default:
            Ember.set(account, 'accountsReceivableSelected', false);
            Ember.set(account, 'accountsPayableSelected', false);
            Ember.set(account, 'inventorySelected', false);
        }

        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 1000
        }));
      },
      addAccountRow: function addAccountRow() {
        this.get('inputAccounts').push({});
        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 1000
        }));
      },
      removeAccountInput: function removeAccountInput(index) {
        if (this.get('inputAccounts')[index].id) {
          Ember.set(this.get('inputAccounts')[index], 'removed', true);
        } else {
          this.get('inputAccounts').splice(index, 1);
        }

        this.set('pagedInputAccounts', (0, _pagedArray.default)('inputAccounts', {
          infinite: "unpaged",
          page: 1,
          perPage: 1000
        }));
        this.debitTotalSetter();
        this.creditTotalSetter();
      },
      amountChanged: function amountChanged(account, accountAction) {
        if (accountAction === 'debit') {
          account.amount = account.debitAmount;
          account.account_action = "debit";
          this.debitTotalSetter();
          Ember.set(account, 'creditAmount', null);
        } else {
          account.amount = account.creditAmount;
          account.account_action = 'credit';
          this.creditTotalSetter();
          Ember.set(account, 'debitAmount', null);
        }
      },
      setRecurrency: function setRecurrency(recurrency) {
        if (recurrency.active) {
          this.set('journalEntryRecurrency', recurrency);
        } else {
          this.set('journalEntryRecurrency', null);
        }
      },
      create: function create() {
        var _this4 = this;

        var journalEntryAccounts = this.get('inputAccounts').map(function (inputAccount) {
          // if (inputAccount.item_id && this.store.peekRecord('account', inputAccount.account_id).get('reference') !== "Inventory") {
          //   inputAccount.item_id = null;
          // }
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        this.set('journalEntry.accounts', journalEntryAccounts.filter(Boolean));
        this.set('journalEntry.company_id', this.companyId);
        this.set('journalEntry.recurrency', this.get("journalEntryRecurrency"));
        var accountAmountSum = 0;
        var journalEntryAmount = 0;
        var itemDivisable = true;
        this.get('inputAccounts').forEach(function (account) {
          if (account.account_action === "debit" && account.amount) {
            accountAmountSum += parseFloat(Number(account.amount).toFixed(2));
            journalEntryAmount += parseFloat(Number(account.amount).toFixed(2));
          } else if (account.account_action === "credit" && account.amount) {
            accountAmountSum -= parseFloat(Number(account.amount).toFixed(2));
          } // if (account.inventorySelected) {
          //   let item = this.store.peekRecord('item', account.item_id);
          //   itemDivisable = Number(account.amount) % Number(item.get('cost')) === 0;
          // }

        });
        this.set('journalEntry.amount', journalEntryAmount);

        if (parseFloat(accountAmountSum.toFixed(2)) !== 0) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Account amount sum must equal 0</span></div>"
          });
          return;
        }

        if (!itemDivisable) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Adjustements to quantity in inventory items cannot create decimals</span></div>"
          });
          return;
        }

        var data = JSON.stringify({
          journalEntry: this.get('journalEntry')
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/journal_entries"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Journal Entry Created</span></div>'
            });

            _this4.sendAction('reloadRoute');

            _this4.get('router').transitionTo('companies.show.journal-entries', _this4.companyId);
          },
          errorCallback: function errorCallback(response) {
            _this4.errorHandler(response);
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      update: function update() {
        var _this = this;

        var accountAmountSum = 0;
        var journalEntryAmount = 0; // let itemDivisable = true;

        var journalEntryAccounts = this.get('inputAccounts').map(function (inputAccount) {
          if (inputAccount.account_id || inputAccount.account_id === 0) {
            inputAccount.label_ids = inputAccount.labels ? inputAccount.labels.map(function (label) {
              return label.id;
            }) : [];
            return inputAccount;
          }
        });
        this.set('journalEntry.accounts', journalEntryAccounts.filter(Boolean));
        this.get('inputAccounts').forEach(function (account) {
          if (!account.removed) {
            accountAmountSum = parseFloat(accountAmountSum.toFixed(2));

            if (account.account_action === "debit" && account.amount) {
              accountAmountSum += Number(account.amount);
              journalEntryAmount += Number(account.amount);
            } else if (account.account_action === "credit" && account.amount) {
              accountAmountSum -= Number(account.amount);
            } // if (account.inventorySelected) {
            //     let item = _this.store.peekRecord('item', account.item_id);
            //     itemDivisable = Number(account.amount) % Number(item.get('cost')) === 0;
            // }

          }
        });
        this.set('journalEntry.amount', journalEntryAmount);
        this.set('journalEntry.recurrency', this.get("journalEntryRecurrency"));

        if (parseFloat(accountAmountSum) !== 0) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Debit minus Credit must equal to 0</span></div>"
          });
          return;
        } // if (!itemDivisable) {
        //   this.get('notify').alert({ html: `<i class="icon-warning lg"></i> <div class="message-text"><span>Adjustements to quantity in inventory items cannot create decimals</span></div>` });
        //   return;
        // }


        this.store.findRecord('journal-entry', this.journalEntry.id, {
          backgroundReload: false
        }).then(function (journalEntry) {
          journalEntry.setProperties(_this.journalEntry);
          journalEntry.set('accounts', _this.get('inputAccounts'));
          journalEntry.save().then(function () {
            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Journal Entry Updated</span></div>'
            });

            if (_this.accountId) {
              _this.get('router').transitionTo('companies.show.accounts.transactions', _this.companyId, _this.accountId);
            } else if (_this.reconciliationId) {
              if (_this.reconciliationId === "new") {
                _this.get('router').transitionTo('companies.show.reconciliations.new', _this.companyId);
              } else {
                _this.get('router').transitionTo('companies.show.reconciliations.show', _this.companyId, _this.reconciliationId);
              }
            } else {
              _this.get('router').transitionTo('companies.show.journal-entries', _this.companyId);
            }
          }, function (response) {
            _this.errorHandler(response);
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete() {
        this.toggleProperty('confirmDelete');
      },
      deleteJournalEntry: function deleteJournalEntry() {
        var _this5 = this;

        this.journalEntry.destroyRecord().then(function () {
          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Journal Entry Deleted</span></div>"
          });

          _this5.sendAction('reloadRoute');

          _this5.get('router').transitionTo('companies.show.journal-entries', _this5.companyId);
        }).catch(function (response) {
          _this5.errorHandler(response);

          _this5.journalEntry.rollbackAttributes();
        });
      },
      formatAccountAmount: function formatAccountAmount(account) {
        if (account.debitAmount) {
          Ember.set(account, 'debitAmount', Math.abs(Number(account.debitAmount)).toFixed(2));
        } else {
          Ember.set(account, 'creditAmount', Math.abs(Number(account.creditAmount)).toFixed(2));
        }
      },
      updateItem: function updateItem(account, index) {
        var value = (0, _jquery.default)("#selectItem-".concat(index))[0].value;

        if (account.vendor_id || account.customer_id) {
          account.vendor_id = null;
          account.customer_id = null;
        }

        try {
          account.item_id = value;
        } catch (e) {
          Ember.set(account, 'item_id', value);
        }
      },
      updateCustomer: function updateCustomer(customer, type, account) {
        if (account.vendor_id || account.item_id) {
          account.vendor_id = null;
          account.item_id = null;
        }

        try {
          account.customer_id = customer.id;
        } catch (e) {
          Ember.set(account, 'customer_id', customer.id);
        }
      },
      updateVendor: function updateVendor(vendor, type, account) {
        if (account.customer_id || account.item_id) {
          account.customer_id = null;
          account.item_id = null;
        }

        try {
          account.vendor_id = vendor.id;
        } catch (e) {
          Ember.set(account, 'vendor_id', vendor.id);
        }
      },
      cancel: function cancel() {
        if (Ember.typeOf(this.get('journalEntry')) === "instance") {
          this.get('journalEntry').rollbackAttributes();
        }

        if (this.accountId) {
          this.get('router').transitionTo('companies.show.accounts.transactions', this.companyId, this.accountId);
        } else if (this.reconciliationId) {
          if (this.reconciliationId === "new") {
            this.get('router').transitionTo('companies.show.reconciliations.new', this.companyId);
          } else {
            this.get('router').transitionTo('companies.show.reconciliations.show', this.companyId, this.reconciliationId);
          }
        } else {
          this.get('router').transitionTo('companies.show.journal-entries', this.companyId);
        }
      },
      setAccountLabels: function setAccountLabels(index, labels) {
        Ember.set(this.get('inputAccounts')[index], 'labels', labels);
      }
    }
  });

  _exports.default = _default;
});