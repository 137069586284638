define("account-berry-frontend/companies/show/reports/trial-balance/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    init: function init() {// let channel = this.get('session.pusher').subscribe(`trial-balance-channel-${this.get('session.data.authenticated.id')}`);
      // channel.bind('report', (response) => {
      //   this.store.findRecord('json-storage', response.json_storage_id).then((jsonStorage) => {
      //     this.set('trialBalanceData', jsonStorage.get('stored_json.report'));
      //     this.get('applicationController').set('showLoading', false);
      //   });
      // });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    arrayToCSV: function arrayToCSV(data) {
      return data.map(function (row) {
        return row.map(String) // convert every value to String
        .map(function (v) {
          return v.replaceAll('"', '""');
        }) // escape double quotes
        .map(function (v) {
          return "\"".concat(v, "\"");
        }) // quote it
        .join(',');
      } // comma-separated
      ).join('\r\n'); // rows starting on new lines
    },
    actions: {
      getTrialBalance: function getTrialBalance() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date
          },
          report_type: 'trial_balance'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "TrialBalance" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-TrialBalance');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('trialBalanceData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-TrialBalance disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this3 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this3.set('account', account); // this.transactionsActionCable();


          _this3.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      print: function print() {
        (0, _jquery.default)('#trialBalance').printThis();
      },
      csvExport: function csvExport() {
        (0, _jquery.default)('#trialBalanceTable').table2CSV({
          delivery: 'download',
          filename: 'TrialBalanceTable.csv'
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
      },
      cegExport: function cegExport() {
        // Create array
        // let cegExportArray = [["AcctNo", "AcctDescription", "PriorYearBalance", "CurrentYearBalance", "GroupCode", "AllocCode", "SubGroupCode"]];
        var cegExportArray = [];
        var accountNumberCount = 1;
        var validExport = true;
        this.get('trialBalanceData.account_data').forEach(function (account) {
          if (account.balance && account.balance !== '0.0') {
            if (account.group_code) {
              cegExportArray.push({
                AcctNo: account.number ? account.number : "0000" + accountNumberCount.toString(),
                AcctDescription: account.name,
                PriorYearBalance: 0,
                CurrentYearBalance: account.debit ? account.balance : parseFloat(account.balance) * -1,
                GroupCode: account.group_code,
                AllocCode: 0,
                SubGroupCode: 0 // account.number ? account.number : "0000" + accountNumberCount.toString(), 
                // account.name, 
                // 0, 
                // (account.debit ? account.balance : parseFloat(account.balance) * -1), 
                // account.group_code,
                // 0,0

              });

              if (!account.number) {
                accountNumberCount += 1;
              }

              ;
            } else if (!account.group_code) {
              validExport = false;
            }
          }
        });

        if (validExport) {
          var worksheet = XLSX.utils.json_to_sheet(cegExportArray);
          var workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Trial Balance");
          XLSX.writeFile(workbook, "".concat(this.get('company.name'), "_Expert_Tax_Trial_Balance_Import.xlsx"), {
            compression: true
          }); // let cegCSV = this.arrayToCSV(cegExportArray);
          // // Create a blob
          // let blob = new Blob([cegCSV], { type: 'text/csv;charset=utf-8;' });
          // let url = URL.createObjectURL(blob);
          // // Create a link to download it
          // let pom = document.createElement('a');
          // pom.href = url;
          // pom.setAttribute('download', `${this.get(('company.name'))}_Expert_Tax_Trial_Balance_Import.csv`);
          // pom.click();
        } else {
          this.set('showMissingGroupCodes', true);
          this.set('hideZeros', true);
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>To export expert tax file, you need to asign a group code to all accounts with a balance</span></div>"
          });
        }
      }
    }
  });

  _exports.default = _default;
});