define("account-berry-frontend/companies/show/employees/941-pr/controller", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    init: function init() {
      var _this2 = this;

      this._super();

      var channel = this.get('session.pusher').subscribe("941pr-channel-".concat(this.get('session.data.authenticated.id')));
      channel.bind('quarter', function (response) {
        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          _this2.set('quarterData', jsonStorage.get('stored_json.quarter'));
        });
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    _941pr2020Covid19: Ember.computed('quarterEndingOn', function () {
      return this.get('year') === '2021' && this.get('periodoContributivo') === "primero" || this.get('year') === '2020' && this.get('periodoContributivo') !== "primero";
    }),
    _941pr2021: Ember.computed('quarterEndingOn', function () {
      return this.get('year') === '2021' && this.get('periodoContributivo') !== "primero";
    }),
    ein: Ember.computed('company.ein', function () {
      if (this.get('company')) {
        var ein = this.get('company.ein') ? this.get('company.ein').replace("-", "") : "";
        return ein;
      } else {
        return "";
      }
    }),
    // Returns an object of shape: { width: number, height: number }
    getPageDimensions: function getPageDimensions(page) {
      var mediaBox; // Check for MediaBox on the page itself

      var hasMediaBox = !!page.getMaybe('MediaBox');

      if (hasMediaBox) {
        mediaBox = page.index.lookup(page.get('MediaBox'));
      } // Check for MediaBox on each parent node


      page.Parent.ascend(function (parent) {
        var parentHasMediaBox = !!parent.getMaybe('MediaBox');

        if (!mediaBox && parentHasMediaBox) {
          mediaBox = parent.index.lookup(parent.get('MediaBox'));
        }
      }, true); // This should never happen in valid PDF files

      if (!mediaBox) throw new Error('Page Tree is missing MediaBox'); // Extract and return the width and height

      return {
        width: mediaBox.array[2].number,
        height: mediaBox.array[3].number
      };
    },
    firstPageEINXPositions: [165, 189, 227, 252, 277, 301, 327, 351, 377],
    firstPageEINYPosition: Ember.computed(function () {
      return {
        2019: 694,
        2024: 705,
        2025: 705
      };
    }),
    lastPageEINPositions: [149, 177, 214, 241, 269, 295, 320, 347, 373],
    quarterFirstMonth: function quarterFirstMonth() {
      return [{
        dollarX: 88,
        dollarY: 528,
        centX: 117,
        centY: 528
      }, {
        dollarX: 88,
        dollarY: 509,
        centX: 117,
        centY: 509
      }, {
        dollarX: 88,
        dollarY: 492,
        centX: 117,
        centY: 492
      }, {
        dollarX: 88,
        dollarY: 475,
        centX: 117,
        centY: 475
      }, {
        dollarX: 88,
        dollarY: 456,
        centX: 117,
        centY: 456
      }, {
        dollarX: 88,
        dollarY: 437,
        centX: 117,
        centY: 437
      }, {
        dollarX: 88,
        dollarY: 420,
        centX: 117,
        centY: 420
      }, {
        dollarX: 88,
        dollarY: 402,
        centX: 117,
        centY: 402
      }, {
        dollarX: 189,
        dollarY: 528,
        centX: 218,
        centY: 528
      }, {
        dollarX: 189,
        dollarY: 509,
        centX: 218,
        centY: 509
      }, {
        dollarX: 189,
        dollarY: 492,
        centX: 218,
        centY: 492
      }, {
        dollarX: 189,
        dollarY: 475,
        centX: 218,
        centY: 475
      }, {
        dollarX: 189,
        dollarY: 456,
        centX: 218,
        centY: 456
      }, {
        dollarX: 189,
        dollarY: 437,
        centX: 218,
        centY: 437
      }, {
        dollarX: 189,
        dollarY: 420,
        centX: 218,
        centY: 420
      }, {
        dollarX: 189,
        dollarY: 402,
        centX: 218,
        centY: 402
      }, {
        dollarX: 290,
        dollarY: 528,
        centX: 317,
        centY: 528
      }, {
        dollarX: 290,
        dollarY: 509,
        centX: 317,
        centY: 509
      }, {
        dollarX: 290,
        dollarY: 492,
        centX: 317,
        centY: 492
      }, {
        dollarX: 290,
        dollarY: 475,
        centX: 317,
        centY: 475
      }, {
        dollarX: 290,
        dollarY: 456,
        centX: 317,
        centY: 456
      }, {
        dollarX: 290,
        dollarY: 437,
        centX: 317,
        centY: 437
      }, {
        dollarX: 290,
        dollarY: 420,
        centX: 317,
        centY: 420
      }, {
        dollarX: 290,
        dollarY: 402,
        centX: 317,
        centY: 402
      }, {
        dollarX: 391,
        dollarY: 528,
        centX: 418,
        centY: 528
      }, {
        dollarX: 391,
        dollarY: 509,
        centX: 418,
        centY: 509
      }, {
        dollarX: 391,
        dollarY: 492,
        centX: 418,
        centY: 492
      }, {
        dollarX: 391,
        dollarY: 475,
        centX: 418,
        centY: 475
      }, {
        dollarX: 391,
        dollarY: 456,
        centX: 418,
        centY: 456
      }, {
        dollarX: 391,
        dollarY: 437,
        centX: 418,
        centY: 437
      }, {
        dollarX: 391,
        dollarY: 420,
        centX: 418,
        centY: 420
      }, {
        dollarX: 529,
        dollarY: 500,
        centX: 556,
        centY: 500
      }];
    },
    quarterSecondMonth: function quarterSecondMonth() {
      return [{
        dollarX: 88,
        dollarY: 372,
        centX: 117,
        centY: 372
      }, {
        dollarX: 88,
        dollarY: 354,
        centX: 117,
        centY: 354
      }, {
        dollarX: 88,
        dollarY: 336,
        centX: 117,
        centY: 336
      }, {
        dollarX: 88,
        dollarY: 318,
        centX: 117,
        centY: 318
      }, {
        dollarX: 88,
        dollarY: 300,
        centX: 117,
        centY: 300
      }, {
        dollarX: 88,
        dollarY: 282,
        centX: 117,
        centY: 282
      }, {
        dollarX: 88,
        dollarY: 263,
        centX: 117,
        centY: 263
      }, {
        dollarX: 88,
        dollarY: 246,
        centX: 117,
        centY: 246
      }, {
        dollarX: 189,
        dollarY: 372,
        centX: 218,
        centY: 372
      }, {
        dollarX: 189,
        dollarY: 354,
        centX: 218,
        centY: 354
      }, {
        dollarX: 189,
        dollarY: 336,
        centX: 218,
        centY: 336
      }, {
        dollarX: 189,
        dollarY: 318,
        centX: 218,
        centY: 318
      }, {
        dollarX: 189,
        dollarY: 300,
        centX: 218,
        centY: 300
      }, {
        dollarX: 189,
        dollarY: 282,
        centX: 218,
        centY: 282
      }, {
        dollarX: 189,
        dollarY: 263,
        centX: 218,
        centY: 263
      }, {
        dollarX: 189,
        dollarY: 246,
        centX: 218,
        centY: 246
      }, {
        dollarX: 290,
        dollarY: 372,
        centX: 317,
        centY: 372
      }, {
        dollarX: 290,
        dollarY: 354,
        centX: 317,
        centY: 354
      }, {
        dollarX: 290,
        dollarY: 336,
        centX: 317,
        centY: 336
      }, {
        dollarX: 290,
        dollarY: 318,
        centX: 317,
        centY: 318
      }, {
        dollarX: 290,
        dollarY: 300,
        centX: 317,
        centY: 300
      }, {
        dollarX: 290,
        dollarY: 282,
        centX: 317,
        centY: 282
      }, {
        dollarX: 290,
        dollarY: 263,
        centX: 317,
        centY: 263
      }, {
        dollarX: 290,
        dollarY: 246,
        centX: 317,
        centY: 246
      }, {
        dollarX: 391,
        dollarY: 372,
        centX: 418,
        centY: 372
      }, {
        dollarX: 391,
        dollarY: 354,
        centX: 418,
        centY: 354
      }, {
        dollarX: 391,
        dollarY: 336,
        centX: 418,
        centY: 336
      }, {
        dollarX: 391,
        dollarY: 318,
        centX: 418,
        centY: 318
      }, {
        dollarX: 391,
        dollarY: 300,
        centX: 418,
        centY: 300
      }, {
        dollarX: 391,
        dollarY: 282,
        centX: 418,
        centY: 282
      }, {
        dollarX: 391,
        dollarY: 263,
        centX: 418,
        centY: 263
      }, {
        dollarX: 529,
        dollarY: 344,
        centX: 556,
        centY: 344
      }];
    },
    quarterThirdMonth: function quarterThirdMonth() {
      return [{
        dollarX: 88,
        dollarY: 215,
        centX: 117,
        centY: 215
      }, {
        dollarX: 88,
        dollarY: 197,
        centX: 117,
        centY: 197
      }, {
        dollarX: 88,
        dollarY: 178,
        centX: 117,
        centY: 178
      }, {
        dollarX: 88,
        dollarY: 161,
        centX: 117,
        centY: 161
      }, {
        dollarX: 88,
        dollarY: 142,
        centX: 117,
        centY: 142
      }, {
        dollarX: 88,
        dollarY: 124,
        centX: 117,
        centY: 124
      }, {
        dollarX: 88,
        dollarY: 107,
        centX: 117,
        centY: 107
      }, {
        dollarX: 88,
        dollarY: 89,
        centX: 117,
        centY: 89
      }, {
        dollarX: 189,
        dollarY: 215,
        centX: 218,
        centY: 215
      }, {
        dollarX: 189,
        dollarY: 197,
        centX: 218,
        centY: 197
      }, {
        dollarX: 189,
        dollarY: 178,
        centX: 218,
        centY: 178
      }, {
        dollarX: 189,
        dollarY: 161,
        centX: 218,
        centY: 161
      }, {
        dollarX: 189,
        dollarY: 142,
        centX: 218,
        centY: 142
      }, {
        dollarX: 189,
        dollarY: 124,
        centX: 218,
        centY: 124
      }, {
        dollarX: 189,
        dollarY: 107,
        centX: 218,
        centY: 107
      }, {
        dollarX: 189,
        dollarY: 89,
        centX: 218,
        centY: 89
      }, {
        dollarX: 290,
        dollarY: 215,
        centX: 317,
        centY: 215
      }, {
        dollarX: 290,
        dollarY: 197,
        centX: 317,
        centY: 197
      }, {
        dollarX: 290,
        dollarY: 178,
        centX: 317,
        centY: 178
      }, {
        dollarX: 290,
        dollarY: 161,
        centX: 317,
        centY: 161
      }, {
        dollarX: 290,
        dollarY: 142,
        centX: 317,
        centY: 142
      }, {
        dollarX: 290,
        dollarY: 124,
        centX: 317,
        centY: 124
      }, {
        dollarX: 290,
        dollarY: 107,
        centX: 317,
        centY: 107
      }, {
        dollarX: 290,
        dollarY: 89,
        centX: 317,
        centY: 89
      }, {
        dollarX: 391,
        dollarY: 215,
        centX: 418,
        centY: 215
      }, {
        dollarX: 391,
        dollarY: 197,
        centX: 418,
        centY: 197
      }, {
        dollarX: 391,
        dollarY: 178,
        centX: 418,
        centY: 178
      }, {
        dollarX: 391,
        dollarY: 161,
        centX: 418,
        centY: 161
      }, {
        dollarX: 391,
        dollarY: 142,
        centX: 418,
        centY: 142
      }, {
        dollarX: 391,
        dollarY: 124,
        centX: 418,
        centY: 124
      }, {
        dollarX: 391,
        dollarY: 107,
        centX: 418,
        centY: 107
      }, {
        dollarX: 529,
        dollarY: 188,
        centX: 556,
        centY: 188
      }];
    },
    part1InputValues: Ember.computed(function () {
      return {
        '_2': 0.00,
        '_3': 0.00,
        '5f': 0.00,
        '_7': 0.00,
        '_8': 0.00,
        '_9': 0.00,
        '_11': 0.00,
        '_13': 0.00
      };
    }),
    part1Values: Ember.computed('quarterData', 'noSSniMedicare', function () {
      var values = this.get('part1InputValues');

      if (!this.get('noSSniMedicare')) {
        values['5a'] = parseFloat(this.get('quarterData.total_wages_subject_to_ss_withholding'));
        values['5b'] = parseFloat(this.get('quarterData.total_tips_subject_to_ss_withholding'));
        values['5c'] = parseFloat(this.get('quarterData.total_wages_subject_to_medicare_withholding'));
        values['5d'] = parseFloat(this.get('quarterData.total_wages_subject_to_over_medicare_withholding'));
        values['5e'] = values['5a'] * 0.124 - (values['5a'] > 0 ? 0.005 : 0) + (values['5b'] * 0.124 - (values['5b'] > 0 ? 0.005 : 0)) + (values['5c'] * 0.029 - (values['5c'] > 0 ? 0.005 : 0)) + (values['5d'] * 0.009 - (values['5d'] > 0 ? 0.005 : 0));
      } else {
        values['5a'] = null;
        values['5b'] = null;
        values['5c'] = null;
        values['5d'] = null;
        values['5e'] = null;
        Ember.set(values, '5f', null);
      }

      var _5f = values['5f'] ? parseFloat(values['5f']) : 0;

      var _7 = values['_7'] ? parseFloat(values['_7']) : 0;

      var _8 = values['_8'] ? parseFloat(values['_8']) : 0;

      var _9 = values['_9'] ? parseFloat(values['_9']) : 0;

      var _11 = values['_11'] ? parseFloat(values['_11']) : 0;

      var _13 = values['_13'] ? parseFloat(values['_13']) : 0;

      values['_6'] = (values['5e'] || 0) + _5f;
      values['_10'] = (values['_6'] || 0) + _7 + _8 + _9;
      values['_12'] = (values['_10'] || 0) - _11;

      if (values['_12'] > _13) {
        values['_14'] = values['_12'] - _13;
        this.set('pagadaEnExceso', false);
        values['_15'] = null;
      } else if (_13 > values['_12']) {
        values['_15'] = _13 - values['_12'];
        this.set('pagadaEnExceso', true);
        values['_14'] = null;
      } else {
        values['_14'] = null;
        values['_15'] = null;
        this.set('pagadaEnExceso', false);
      }

      return values;
    }),
    firstPageValues: Ember.computed(function () {
      var standard_coords = {
        '_2': [{
          dollarX: 525,
          dollarY: 465,
          centX: 555,
          centY: 465
        }],
        '_3': [{
          dollarX: 525,
          dollarY: 448,
          centX: 555,
          centY: 448
        }],
        '5a': [{
          dollarX: 250,
          dollarY: 391,
          centX: 282,
          centY: 391
        }, {
          dollarX: 393,
          dollarY: 391,
          centX: 425,
          centY: 391
        }],
        '5b': [{
          dollarX: 250,
          dollarY: 370,
          centX: 282,
          centY: 370
        }, {
          dollarX: 393,
          dollarY: 370,
          centX: 425,
          centY: 370
        }],
        '5c': [{
          dollarX: 250,
          dollarY: 350,
          centX: 282,
          centY: 350
        }, {
          dollarX: 393,
          dollarY: 350,
          centX: 425,
          centY: 350
        }],
        '5d': [{
          dollarX: 250,
          dollarY: 322,
          centX: 282,
          centY: 322
        }, {
          dollarX: 393,
          dollarY: 322,
          centX: 425,
          centY: 322
        }],
        '5e': [{
          dollarX: 525,
          dollarY: 298,
          centX: 554,
          centY: 298
        }],
        '5f': [{
          dollarX: 525,
          dollarY: 273,
          centX: 554,
          centY: 273
        }],
        '_6': [{
          dollarX: 525,
          dollarY: 253,
          centX: 554,
          centY: 253
        }],
        '_7': [{
          dollarX: 525,
          dollarY: 236,
          centX: 554,
          centY: 236
        }],
        '_8': [{
          dollarX: 525,
          dollarY: 218,
          centX: 554,
          centY: 218
        }],
        '_9': [{
          dollarX: 525,
          dollarY: 193,
          centX: 554,
          centY: 193
        }],
        '_10': [{
          dollarX: 525,
          dollarY: 176,
          centX: 554,
          centY: 176
        }],
        '_11': [{
          dollarX: 525,
          dollarY: 151,
          centX: 554,
          centY: 151
        }],
        '_12': [{
          dollarX: 525,
          dollarY: 128,
          centX: 554,
          centY: 128
        }],
        '_13': [{
          dollarX: 525,
          dollarY: 95,
          centX: 554,
          centY: 95
        }],
        '_14': [{
          dollarX: 525,
          dollarY: 80,
          centX: 554,
          centY: 80
        }],
        '_15': [{
          dollarX: 300,
          dollarY: 53,
          centX: 332,
          centY: 53
        }]
      };
      return {
        '2025': standard_coords,
        '2024': standard_coords
      };
    }),
    firstPagePdfCoordinates: Ember.computed('quarterData', 'company', 'trimestreY', 'nombreExtranjero', 'provinciaExtranjera', 'zipExtranjero', 'noSSniMedicare', 'pagadaEnExceso', 'proximaPlanilla', function () {
      var standard_coords = {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 190,
          y: 680,
          size: 8
        },
        'commercialName': {
          value: this.get('company.name') || "",
          x: 155,
          y: 655,
          size: 8
        },
        'trimestre': {
          value: this.get('trimestreY') ? "X" : "",
          x: 415,
          y: this.get('trimestreY') || 0,
          size: 8
        },
        'address': {
          value: "".concat(this.get('company.postal_address_line_one') || "", " ").concat(this.get('company.postal_address_line_two') || ""),
          x: 90,
          y: 635,
          size: 8
        },
        'city': {
          value: this.get('company.postal_address_city') || "",
          x: 90,
          y: 605,
          size: 8
        },
        'state': {
          value: this.get('company.postal_address_state') || "",
          x: 277,
          y: 605,
          size: 8
        },
        'zip': {
          value: this.get('company.postal_address_zip') || "",
          x: 322,
          y: 605,
          size: 8
        },
        'nombreExtranjero': {
          value: this.get('nombreExtranjero') || "",
          x: 90,
          y: 575,
          size: 8
        },
        'provinciaExtranjera': {
          value: this.get('provinciaExtranjera') || "",
          x: 196,
          y: 575,
          size: 8
        },
        'zipExtranjero': {
          value: this.get('zipExtranjero') || "",
          x: 300,
          y: 575,
          size: 8
        },
        'numeroEmpleados': {
          value: this.get('quarterData.employees_count').toString(),
          x: 450,
          y: 483,
          size: 10
        },
        'noSSniMedicare': {
          value: this.get('noSSniMedicare') ? "X" : "",
          x: 449,
          y: 418,
          size: 8
        },
        'pagadaEnExceso': {
          value: this.get('pagadaEnExceso') ? "X" : "",
          x: this.get('proximaPlanilla') ? 405 : 499,
          y: 57,
          size: 8
        }
      };
      return {
        '2024': standard_coords,
        '2025': standard_coords
      };
    }),
    secondPageValues: Ember.computed('quarterData', function () {
      return [{
        2025: {
          value: this.get('quarterData.first_month_ss_medicare'),
          dollarX: 368,
          dollarY: 612,
          centX: 395,
          centY: 612
        },
        2024: {
          value: this.get('quarterData.first_month_ss_medicare'),
          dollarX: 368,
          dollarY: 612,
          centX: 395,
          centY: 612
        },
        2019: {
          value: this.get('quarterData.first_month_ss_medicare'),
          dollarX: 368,
          dollarY: 608,
          centX: 395,
          centY: 608
        }
      }, {
        2025: {
          value: this.get('quarterData.second_month_ss_medicare'),
          dollarX: 368,
          dollarY: 593,
          centX: 395,
          centY: 593
        },
        2024: {
          value: this.get('quarterData.second_month_ss_medicare'),
          dollarX: 368,
          dollarY: 593,
          centX: 395,
          centY: 593
        },
        2019: {
          value: this.get('quarterData.second_month_ss_medicare'),
          dollarX: 368,
          dollarY: 590,
          centX: 395,
          centY: 590
        }
      }, {
        2025: {
          value: this.get('quarterData.third_month_ss_medicare'),
          dollarX: 368,
          dollarY: 575,
          centX: 395,
          centY: 575
        },
        2024: {
          value: this.get('quarterData.third_month_ss_medicare'),
          dollarX: 368,
          dollarY: 575,
          centX: 395,
          centY: 575
        },
        2019: {
          value: this.get('quarterData.third_month_ss_medicare'),
          dollarX: 368,
          dollarY: 572,
          centX: 395,
          centY: 572
        }
      }, {
        2025: {
          value: this.get('quarterData.total_ss_medicare'),
          dollarX: 368,
          dollarY: 557,
          centX: 395,
          centY: 557
        },
        2024: {
          value: this.get('quarterData.total_ss_medicare'),
          dollarX: 368,
          dollarY: 557,
          centX: 395,
          centY: 557
        },
        2019: {
          value: this.get('quarterData.total_ss_medicare'),
          dollarX: 368,
          dollarY: 553,
          centX: 395,
          centY: 553
        }
      }];
    }),
    secondPagePdfCoordinates: Ember.computed('company', '17check', '17date', '18check', 'parte4', 'parte4Nombre', 'parte4Numero', 'parte5Cargo', 'parte5Num', 'parte5Nombre', 'parte5CuentaPropia', 'parte5PTIN', 'parte5NombrePreparador', 'parte5Ein', 'parte5Ciudad', 'parte5Estado', 'parte5PreparadorNum', 'parte5Zip', 'parte5NombreEmpresa', 'parte5Direccion', function () {
      var splitted17Date = this.get('17date') && this.get('17check') ? this.get('17date').split("-") : [];
      var today = new Date();
      var standard_coords = {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 50,
          y: 735,
          size: 10
        },
        'ein': {
          value: this.get('ein'),
          x: 400,
          y: 735,
          size: 10
        },
        '17check': {
          value: this.get('17check') ? "X" : "",
          x: 478,
          y: 502,
          size: 10
        },
        '17month': {
          value: splitted17Date[1] || "",
          x: 249,
          y: 480,
          size: 10
        },
        '17day': {
          value: splitted17Date[2] || "",
          x: 268,
          y: 480,
          size: 10
        },
        '17year': {
          value: splitted17Date[0] || "",
          x: 286,
          y: 480,
          size: 10
        },
        '18check': {
          value: this.get('18check') ? "X" : "",
          x: 478,
          y: 465,
          size: 10
        },
        'parte4Si': {
          value: this.get('parte4') ? "X" : "",
          x: 66,
          y: 405,
          size: 10
        },
        'parte4No': {
          value: !this.get('parte4') ? "X" : "",
          x: 66,
          y: 351,
          size: 10
        },
        'parte4Nombre': {
          value: this.get('parte4Nombre') || "",
          x: 179,
          y: 392,
          size: 10
        },
        'parte4Numero': {
          value: this.get('parte4Numero') || "",
          x: 441,
          y: 392,
          size: 10
        },
        'parte5Nombre': {
          value: this.get('parte5Nombre') || "",
          x: 412,
          y: 286,
          size: 10
        },
        'parte5Cargo': {
          value: this.get('parte5Cargo') || "",
          x: 412,
          y: 263,
          size: 10
        },
        'parte5Num': {
          value: this.get('parte5Num') || "",
          x: 445,
          y: 238,
          size: 10
        },
        'parte5Month': {
          value: (today.getMonth() + 1).toString(),
          x: 105,
          y: 239,
          size: 10
        },
        'parte5Day': {
          value: today.getDate().toString(),
          x: 123,
          y: 239,
          size: 10
        },
        'parte5year': {
          value: today.getFullYear().toString(),
          x: 143,
          y: 239,
          size: 10
        },
        'parte5CuentaPropia': {
          value: this.get('parte5CuentaPropia') ? "X" : "",
          x: 563,
          y: 219,
          size: 10
        },
        'parte5NombrePreparador': {
          value: this.get('parte5NombrePreparador') || "",
          x: 125,
          y: 195,
          size: 10
        },
        'parte5NombreEmpresa': {
          value: this.get('parte5NombreEmpresa') || "",
          x: 125,
          y: 147,
          size: 10
        },
        'parte5Direccion': {
          value: this.get('parte5Direccion') || "",
          x: 125,
          y: 122,
          size: 10
        },
        'parte5Ciudad': {
          value: this.get('parte5Ciudad') || "",
          x: 125,
          y: 98,
          size: 10
        },
        'parte5Estado': {
          value: this.get('parte5Estado') || "",
          x: 355,
          y: 98,
          size: 10
        },
        'parte5Zip': {
          value: this.get('parte5Zip') || "",
          x: 457,
          y: 98,
          size: 10
        },
        'parte5PTIN': {
          value: this.get('parte5PTIN') || "",
          x: 457,
          y: 195,
          size: 10
        },
        'parte5PreparadorMonth': {
          value: (today.getMonth() + 1).toString(),
          x: 465,
          y: 170,
          size: 10
        },
        'parte5PreparadorDay': {
          value: today.getDate().toString(),
          x: 500,
          y: 170,
          size: 10
        },
        'parte5PreparadorYear': {
          value: today.getFullYear().toString(),
          x: 530,
          y: 170,
          size: 10
        },
        'parte5Ein': {
          value: this.get('parte5Ein') ? this.get('parte5Ein').replace("-", "") : "",
          x: 457,
          y: 147,
          size: 10
        },
        'parte5PreparadorNum': {
          value: this.get('parte5PreparadorNum') || "",
          x: 457,
          y: 122,
          size: 10
        }
      };
      return {
        '2025': standard_coords,
        '2024': standard_coords
      };
    }),
    partTwoProcedure: function partTwoProcedure(pdfDoc, secondPage) {
      var _this3 = this;

      switch (this.get('partTwo')) {
        case "a":
          this.set("secondPagePdfCoordinates.".concat(this.get('year'), ".partTwo"), {
            value: "X",
            x: 124,
            y: 691,
            size: 10
          });
          break;

        case "b":
          this.set("secondPagePdfCoordinates.".concat(this.get('year'), ".partTwo"), {
            value: "X",
            x: 124,
            y: 637,
            size: 10
          });
          this.get('secondPageValues').forEach(function (data) {
            data = data[_this3.get('year')];
            var coord = {
              dollarX: data.dollarX,
              dollarY: data.dollarY,
              centX: data.centX,
              centY: data.centY
            };

            _this3.setValues(pdfDoc, secondPage, parseFloat(data.value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
          });
          break;

        case "c":
          this.set("secondPagePdfCoordinates.".concat(this.get('year'), ".partTwo"), {
            value: "X",
            x: 124,
            y: 543,
            size: 10
          });
          break;

        default:
      }
    },
    part4Procedure: function part4Procedure(pdfDoc, secondPage) {
      var _this4 = this;

      var pinCoords = [_defineProperty({
        2025: {
          x: 476,
          y: 368
        },
        2019: {
          x: 473,
          y: 368
        },
        2024: {
          x: 476,
          y: 368
        }
      }, "2019", {
        x: 473,
        y: 368
      }), _defineProperty({
        2025: {
          x: 498,
          y: 368
        },
        2019: {
          x: 495,
          y: 368
        },
        2024: {
          x: 498,
          y: 368
        }
      }, "2019", {
        x: 495,
        y: 368
      }), _defineProperty({
        2025: {
          x: 520,
          y: 368
        },
        2019: {
          x: 514,
          y: 368
        },
        2024: {
          x: 520,
          y: 368
        }
      }, "2019", {
        x: 514,
        y: 368
      }), _defineProperty({
        2025: {
          x: 542,
          y: 368
        },
        2019: {
          x: 536,
          y: 368
        },
        2024: {
          x: 542,
          y: 368
        }
      }, "2019", {
        x: 536,
        y: 368
      }), _defineProperty({
        2025: {
          x: 563,
          y: 368
        },
        2019: {
          x: 555,
          y: 368
        },
        2024: {
          x: 563,
          y: 368
        }
      }, "2019", {
        x: 555,
        y: 368
      })];

      if (this.get('parte4Pin')) {
        this.get('parte4Pin').split("").forEach(function (num, index) {
          var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this4.get('helveticaFont').encodeText(num.toString()), {
            x: pinCoords[index][_this4.get('year')].x,
            y: pinCoords[index][_this4.get('year')].y,
            size: 10,
            font: 'Helvetica',
            colorRgb: [0, 0, 1]
          }));
          secondPage.addContentStreams(pdfDoc.register(contentStream));
        });
      }
    },
    thirdPagePdfCoordinates: Ember.computed('periodoContributivo', function () {
      var standard_coords = {
        'ein': {
          value: this.get('ein'),
          x: 59,
          y: 152,
          size: 10
        },
        // 'pagoDollar': { value: "1000000", x: 506, y: 162, size: 10 },
        // 'pagoCent': { value: "00", x: 540, y: 162, size: 10 },
        'primerTrimestre': {
          value: this.get('periodoContributivo') === "primero" ? "X" : "",
          x: 54,
          y: 118,
          size: 10
        },
        'segundoTrimestre': {
          value: this.get('periodoContributivo') === "segundo" ? "X" : "",
          x: 54,
          y: 88,
          size: 10
        },
        'tercerTrimestre': {
          value: this.get('periodoContributivo') === "tercero" ? "X" : "",
          x: 140,
          y: 118,
          size: 10
        },
        'cuartoTrimestre': {
          value: this.get('periodoContributivo') === "cuarto" ? "X" : "",
          x: 140,
          y: 88,
          size: 10
        },
        'nombreComercial': {
          value: this.get('company.name') || "",
          x: 236,
          y: 130,
          size: 10
        },
        'direccion1': {
          value: "".concat(this.get('company.postal_address_one') || "", " ").concat(this.get('company.postal_address_two') || ""),
          x: 236,
          y: 110,
          size: 10
        },
        'direccion2': {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 236,
          y: 105,
          size: 10
        }
      };
      return {
        '2025': standard_coords,
        '2024': standard_coords
      };
    }),
    lastPagePdfCoordinates: Ember.computed('year', 'trimestreY', 'trimestreYAnexo', function () {
      var splittedYear = this.get('year').split("");
      return {
        'name': {
          value: this.get('company.contact_name') || "",
          x: 135,
          y: 666,
          size: 10
        },
        'trimestre': {
          value: this.get('trimestreYAnexo') ? "X" : "",
          x: 422,
          y: this.get('trimestreYAnexo') || 0,
          size: 8
        },
        'yearDigitOne': {
          value: splittedYear[0],
          x: 149,
          y: 641
        },
        'yearDigitTwo': {
          value: splittedYear[1],
          x: 176,
          y: 641
        },
        'yearDigitThree': {
          value: splittedYear[2],
          x: 202,
          y: 641
        },
        'yearDigitFour': {
          value: splittedYear[3],
          x: 230,
          y: 641
        }
      };
    }),
    lastPageProcedure: function lastPageProcedure(pdfDoc, lastPage) {
      if (this.get('partTwo') === "c") {
        for (var i = 0; i < 32; i++) {
          if (this.get("quarterData.first_month_dates.".concat(i + 1))) {
            var _amount = parseFloat(this.get("quarterData.first_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount, this.get('quarterFirstMonth')()[i]);
          }

          if (this.get("quarterData.second_month_dates.".concat(i + 1))) {
            var _amount2 = parseFloat(this.get("quarterData.second_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount2, this.get('quarterSecondMonth')()[i]);
          }

          if (this.get("quarterData.third_month_dates.".concat(i + 1))) {
            var _amount3 = parseFloat(this.get("quarterData.third_month_dates.".concat(i + 1))).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

            this.setValues(pdfDoc, lastPage, _amount3, this.get('quarterThirdMonth')()[i]);
          }

          if (i == 31) {
            var firstMonthAmount = parseFloat(this.get('quarterData.first_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var secondMonthAmount = parseFloat(this.get('quarterData.second_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            var thirdMonthAmount = parseFloat(this.get('quarterData.third_month_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            this.setValues(pdfDoc, lastPage, firstMonthAmount, this.get('quarterFirstMonth')()[i]);
            this.setValues(pdfDoc, lastPage, secondMonthAmount, this.get('quarterSecondMonth')()[i]);
            this.setValues(pdfDoc, lastPage, thirdMonthAmount, this.get('quarterThirdMonth')()[i]);
          }
        }

        var amount = parseFloat(this.get('quarterData.total_ss_medicare')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        this.setValues(pdfDoc, lastPage, amount, {
          dollarX: 528,
          dollarY: 52,
          centX: 556,
          centY: 52
        });
      }
    },
    setEIN: function setEIN(pdfDoc, einPosition, page, y) {
      var _this5 = this;

      var ein = this.get('ein') ? this.get('ein').split("") : [];
      ein.forEach(function (num, index) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this5.get('helveticaFont').encodeText(num), {
          x: einPosition[index],
          y: y,
          size: 10,
          font: 'Helvetica',
          colorRgb: [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
    },
    setValues: function setValues(pdfDoc, page, val, coord) {
      var splittedVal = val.toString().split("."); // Set dollar amount

      var textWidth = this.get('helveticaFont').widthOfTextAtSize(splittedVal[0], 10);
      var realX = coord.dollarX - textWidth + 20;
      var contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(splittedVal[0]), {
        x: realX,
        y: coord.dollarY,
        size: 10,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
      contentStream = pdfDoc.createContentStream(PDFLib.drawText(this.get('helveticaFont').encodeText(splittedVal[1] || "00"), {
        x: coord.centX,
        y: coord.centY,
        size: 10,
        font: 'Helvetica',
        colorRgb: [0, 0, 1]
      }));
      page.addContentStreams(pdfDoc.register(contentStream));
    },
    pageSetup: function pageSetup(page, keys, coordinates, pdfDoc) {
      var _this = this;

      var coord = this.get("".concat(coordinates, ".").concat(this.get('year'))) ? this.get("".concat(coordinates, ".").concat(this.get('year'))) : this.get("".concat(coordinates));
      keys.forEach(function (k) {
        var contentStream = pdfDoc.createContentStream(PDFLib.drawText(_this.get('helveticaFont').encodeText(coord[k]['value']), {
          x: coord[k]['x'],
          y: coord[k]['y'],
          size: coord[k]['size'] ? coord[k]['size'] : 10,
          font: 'Helvetica',
          colorRgb: coord[k]['color'] ? coord[k]['color'] : [0, 0, 1]
        }));
        page.addContentStreams(pdfDoc.register(contentStream));
      });
    },
    pdfProcess: function pdfProcess(pdfDoc) {
      var _this6 = this;

      var _this = this; // let pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);
      // pdfDoc = this.addPage(pdfDoc)


      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      var secondPage = pages[1];
      var thirdPage = pages[2];
      pages.forEach(function (page) {
        page.addFontDictionary('Helvetica', helveticaRef);
      }); // ------------- FIRST PAGE ---------------------

      var firstPageKeys = Object.keys(this.get('firstPagePdfCoordinates')[this.get('year')]);
      this.setEIN(pdfDoc, this.get('firstPageEINXPositions'), firstPage, this.get('firstPageEINYPosition')[this.get('year')]);
      var valueKeys = Object.keys(this.get("firstPageValues.".concat(this.get('year'))));
      valueKeys.forEach(function (k) {
        _this6.get("firstPageValues.".concat(_this6.get('year'), ".").concat(k)).forEach(function (coord, index) {
          if (_this6.get("part1Values.".concat(k)) || _this6.get("part1Values.".concat(k)) === 0) {
            var val = parseFloat(_this6.get("part1Values.".concat(k)));

            if (index === 1) {
              switch (k) {
                case '5a':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.124;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5b':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.124;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5c':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.029;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                case '5d':
                  val = parseFloat(_this6.get("part1Values.".concat(k))) * 0.009;
                  val -= val > 0 ? 0.005 : 0;
                  break;

                default:
              }
            }

            _this6.setValues(pdfDoc, firstPage, parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord);
          }
        });
      });
      this.pageSetup(firstPage, firstPageKeys, 'firstPagePdfCoordinates', pdfDoc); // ----------------------------------------------
      // ------------- SECOND PAGE ---------------------

      this.partTwoProcedure(pdfDoc, secondPage);
      this.part4Procedure(pdfDoc, secondPage);
      var secondPageKeys = Object.keys(this.get("secondPagePdfCoordinates.".concat(this.get('year'))));
      this.pageSetup(secondPage, secondPageKeys, 'secondPagePdfCoordinates', pdfDoc); // ----------------------------------------------
      // ------------- THIRD PAGE ---------------------

      if (this.get('part1Values._14')) {
        var coord = {
          2025: {
            dollarX: 510,
            dollarY: 160,
            centX: 542,
            centY: 160
          },
          2024: {
            dollarX: 510,
            dollarY: 160,
            centX: 542,
            centY: 160
          },
          2019: {
            dollarX: 506,
            dollarY: 162,
            centX: 540,
            centY: 162
          }
        };
        this.setValues(pdfDoc, thirdPage, parseFloat(this.get('part1Values._14')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), coord[this.get('year')]);
        var thirdPageKeys = Object.keys(this.get("thirdPagePdfCoordinates.".concat(this.get('year'))));
        this.pageSetup(thirdPage, thirdPageKeys, 'thirdPagePdfCoordinates', pdfDoc);
      } // ----------------------------------------------
      // ------------- LAST PAGE ---------------------


      if (this.get('partTwo') === "c") {
        var lastPage = pages[3];
        this.lastPageProcedure(pdfDoc, lastPage);
        this.setEIN(pdfDoc, this.get('lastPageEINPositions'), lastPage, 690);
        var lastPageKeys = Object.keys(this.get('lastPagePdfCoordinates'));
        this.pageSetup(lastPage, lastPageKeys, 'lastPagePdfCoordinates', pdfDoc);
      } // ----------------------------------------------


      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    getPDF: Ember.observer('quarterData', function () {
      var _this7 = this;

      if (!this.get('_941pr2020Covid19')) {
        var fileName = this.get('year') === '2024' ? 'f941sp.pdf' : "f941sp-".concat(this.get('year'), ".pdf");
        fetch("https://trimestrales.s3.amazonaws.com/".concat(fileName)).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

            if (_this7.get('partTwo') === "c") {
              _this7.addPage(pdfDoc);
            } else {
              _this7.pdfProcess(pdfDoc);
            }
          });
        });
      }
    }),
    addPage: function addPage(pdfDoc) {
      var _this8 = this;

      var fileName = this.get('year') === '2024' || this.get('year') === '2025' ? 'f941spb.pdf' : 'f941pr_anexo_b.pdf';
      fetch("https://trimestrales.s3.amazonaws.com/".concat(fileName)).then(function (res) {
        var file = res.arrayBuffer();
        file.then(function (f) {
          var newDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));
          pdfDoc.addPage(newDoc.getPages()[0]);

          _this8.pdfProcess(pdfDoc);
        });
      });
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year); // this.set('pdfCoordinates.type.y', y)
      },
      setQuarter: function setQuarter(quarter) {
        var y = 0;
        var yAnexo = 0;
        var yByYear = {
          '2025': {
            '31-03': 682,
            '30-06': 664,
            '30-09': 647,
            '31-12': 630
          },
          '2024': {
            '31-03': 682,
            '30-06': 664,
            '30-09': 647,
            '31-12': 630
          },
          '2023': {
            '31-03': 682,
            '30-06': 664,
            '30-09': 647,
            '31-12': 630
          },
          '2022': {
            '31-03': 682,
            '30-06': 664,
            '30-09': 647,
            '31-12': 630
          },
          '2021': {
            '31-03': 682,
            '30-06': 664,
            '30-09': 647,
            '31-12': 630
          },
          '2019': {
            '31-03': 672,
            '30-06': 654,
            '30-09': 636,
            '31-12': 619
          }
        };
        var yByYearAnexo = {
          '2025': {
            '31-03': 675,
            '30-06': 657,
            '30-09': 640,
            '31-12': 621
          },
          '2024': {
            '31-03': 675,
            '30-06': 657,
            '30-09': 640,
            '31-12': 621
          }
        };

        switch (quarter) {
          case "31-03":
            this.set('periodoContributivo', "primero");
            y = yByYear[this.get('year')]['31-03'];
            yAnexo = yByYearAnexo[this.get('year')]['31-03'];
            break;

          case "30-06":
            this.set('periodoContributivo', "segundo");
            y = yByYear[this.get('year')]['30-06'];
            yAnexo = yByYearAnexo[this.get('year')]['30-06'];
            break;

          case "30-09":
            this.set('periodoContributivo', "tercero");
            y = yByYear[this.get('year')]['30-09'];
            yAnexo = yByYearAnexo[this.get('year')]['30-09'];
            break;

          case "31-12":
            this.set('periodoContributivo', "cuarto");
            y = yByYear[this.get('year')]['31-12'];
            yAnexo = yByYearAnexo[this.get('year')]['31-12'];
            break;

          default:
            this.set("firstPagePdfCoordinates.".concat(this.get('year'), ".type.value"), "");
            break;
        }

        this.set('quarterEndingOn', "".concat(quarter, "-").concat(this.get('year')));
        this.set('trimestreY', y);
        this.set('trimestreYAnexo', yAnexo);
      },
      generate: function generate() {
        var _this9 = this;

        var data = JSON.stringify({
          quarter: {
            date: this.get('quarterEndingOn'),
            type: '941pr'
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/quarters"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this9.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback(e) {
            _this9.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      }
    }
  });

  _exports.default = _default;
});