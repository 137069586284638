define("account-berry-frontend/components/payroll-check/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    numFormat: Ember.inject.service(),
    init: function init() {
      this._super();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      // this.currencyFormatter();
      if (this.get('payrollCheck.earnings_amount') > 0) {
        setTimeout(function () {
          if (!_this2.isDestroyed) {
            _this2.calculateEarnings();

            _this2.calculateDeductions();

            _this2.autoCalculations();
          }
        }, 1000);
      }
    },
    enabled: Ember.computed(function () {
      return {
        checkNumber: false,
        regularHours: false,
        overtimeHours: false,
        vacationHours: false,
        sickHours: false,
        bonus: false,
        allowances: false,
        commission: false,
        tips: false,
        reimbursements: false,
        otherTaxableEarningsOne: false,
        otherTaxableEarningsTwo: false,
        otherTaxableEarningsThree: false,
        otherTaxableEarningsFour: false,
        otherTaxableEarningsFive: false,
        otherNonTaxableEarningsOne: false,
        otherNonTaxableEarningsTwo: false,
        otherNonTaxableEarningsThree: false,
        otherNonTaxableEarningsFour: false,
        otherNonTaxableEarningsFive: false,
        stateIncomeTax: false,
        federalIncomeTax: false,
        otherIncomeTax: false,
        medicalPlan: false,
        retirementPlan: false,
        uncollectedMedTips: false,
        uncollectedSsTips: false,
        chauffeurInsurance: false,
        childSupport: false,
        garnishment: false,
        unionDues: false,
        lifeInsurance: false,
        profitSharing: false,
        codaPlan: false,
        colaPlan: false,
        otherDeductionOne: false,
        otherDeductionTwo: false,
        otherDeductionThree: false,
        otherDeductionFour: false,
        otherDeductionfive: false
      };
    }),
    // currencyFormatter() {
    //   let inputs = $('input[type=number]');
    //   for(let i = 0; i < inputs.length; i++) {
    //     if (parseFloat(inputs[i].value)) {
    //       inputs[i].value = parseFloat(inputs[i].value).toFixed(2);
    //     }
    //   }
    // },
    employeeHourDataObserver: Ember.observer('employeeHourData', function () {
      var _this3 = this;

      var payrollCheck = this.get('payrollCheck');
      this.get('employeeHourData').forEach(function (data) {
        if (data.employee_id && payrollCheck.employee_id === data.employee_id.toString()) {
          _this3.set('payrollCheck.regular_hours', data.regular_hours);

          _this3.set('payrollCheck.overtime_hours', data.overtime_hours);

          _this3.set('payrollCheck.vacation_hours', data.vacation_hours);

          _this3.set('payrollCheck.sick_hours', data.sick_hours);

          _this3.set('payrollCheck.bonus', data.bonus);

          _this3.set('payrollCheck.allowances', data.allowances);

          _this3.set('payrollCheck.commission', data.commission);

          _this3.set('payrollCheck.tips', data.tips);

          _this3.set('payrollCheck.reimbursements', data.reimbursements);
        }
      });
    }),
    payrollInternalSetting: Ember.computed('pay_date', 'payrollInternalSettings', function () {
      var _this4 = this;

      var payrollInternalSettings = this.get('payrollInternalSettings');

      if (payrollInternalSettings) {
        return payrollInternalSettings.find(function (payrollInternalSetting) {
          return new Date(payrollInternalSetting.get('date')).getUTCFullYear() === new Date(_this4.get('pay_date')).getUTCFullYear();
        });
      }
    }),
    calcStateIncomeTax: Ember.computed('payrollCheck.calculate_state_income_tax', function () {
      var calcStateIncomeTax = this.get('payrollCheck.calculate_state_income_tax') !== undefined ? this.get('payrollCheck.calculate_state_income_tax') : true;
      return calcStateIncomeTax;
    }),
    calcFederalIncomeTax: Ember.computed('payrollCheck.calculate_federal_income_tax', function () {
      var calcFederalIncomeTax = this.get('payrollCheck.calculate_federal_income_tax') !== undefined ? this.get('payrollCheck.calculate_federal_income_tax') : true;
      return calcFederalIncomeTax;
    }),
    calcOtherIncomeTax: Ember.computed('payrollCheck.calculate_other_income_tax', function () {
      var calcOtherIncomeTax = this.get('payrollCheck.calculate_other_income_tax') !== undefined ? this.get('payrollCheck.calculate_other_income_tax') : true;
      return calcOtherIncomeTax;
    }),
    isNumber: function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    regularHoursObserver: Ember.observer('payrollCheck.regular_hours', function () {
      this.set('payrollCheck.regular_amount', (this.get('payrollCheck.regular_hours') * this.get('payrollCheck.employee_rate.regular_rate')).toFixed(2));
    }),
    overtimeHoursObserver: Ember.observer('payrollCheck.overtime_hours', function () {
      this.set('payrollCheck.overtime_amount', (this.get('payrollCheck.overtime_hours') * this.get('payrollCheck.employee_rate.overtime_rate')).toFixed(2));
    }),
    vacationHoursObserver: Ember.observer('payrollCheck.vacation_hours', function () {
      this.set('payrollCheck.vacation_amount', (this.get('payrollCheck.vacation_hours') * this.get('payrollCheck.employee_rate.regular_rate')).toFixed(2));
    }),
    sickHoursObserver: Ember.observer('payrollCheck.sick_hours', function () {
      this.set('payrollCheck.sick_amount', (this.get('payrollCheck.sick_hours') * this.get('payrollCheck.employee_rate.regular_rate')).toFixed(2));
    }),
    calculateEarnings: function calculateEarnings() {
      var _this5 = this;

      var earnings = [this.get('payrollCheck.regular_amount'), this.get('payrollCheck.overtime_amount'), this.get('payrollCheck.vacation_amount'), this.get('payrollCheck.sick_amount'), this.get('payrollCheck.bonus'), this.get('payrollCheck.allowances'), this.get('payrollCheck.commission'), this.get('payrollCheck.tips'), this.get('payrollCheck.reimbursements'), this.get('payrollCheck.other_taxable_earnings_one'), this.get('payrollCheck.other_taxable_earnings_two'), this.get('payrollCheck.other_taxable_earnings_three'), this.get('payrollCheck.other_taxable_earnings_four'), this.get('payrollCheck.other_taxable_earnings_five'), this.get('payrollCheck.other_non_taxable_earnings_one'), this.get('payrollCheck.other_non_taxable_earnings_two'), this.get('payrollCheck.other_non_taxable_earnings_three'), this.get('payrollCheck.other_non_taxable_earnings_four'), this.get('payrollCheck.other_non_taxable_earnings_five')];
      var earningsAmount = 0;
      earnings.forEach(function (earning) {
        if (_this5.isNumber(earning)) {
          earningsAmount += parseFloat(earning);
        }
      });

      if (earningsAmount > 0) {
        this.set('payrollCheck.void', false);
      }

      this.set('payrollCheck.earnings_amount', earningsAmount.toFixed(2));
    },
    calculateDeductions: function calculateDeductions() {
      var _this6 = this;

      var deductions = [this.get('payrollCheck.state_income_tax'), this.get('payrollCheck.federal_income_tax'), this.get('payrollCheck.other_income_tax'), this.get('payrollCheck.medical_plan'), this.get('payrollCheck.retirement_plan'), this.get('payrollCheck.uncollected_med_tips'), this.get('payrollCheck.uncollected_ss_tips'), this.get('payrollCheck.chauffeur_insurance'), this.get('payrollCheck.child_support'), this.get('payrollCheck.garnishment'), this.get('payrollCheck.union_dues'), this.get('payrollCheck.life_insurance'), this.get('payrollCheck.profit_sharing'), this.get('payrollCheck.coda_plan'), this.get('payrollCheck.cola_plan'), this.get('payrollCheck.social_security'), this.get('payrollCheck.social_security_tips'), this.get('payrollCheck.medicare'), this.get('payrollCheck.medicare_tips'), this.get('payrollCheck.disability'), this.get('payrollCheck.other_deduction_one'), this.get('payrollCheck.other_deduction_two'), this.get('payrollCheck.other_deduction_three'), this.get('payrollCheck.other_deduction_four'), this.get('payrollCheck.other_deduction_five')];
      var deductionsAmount = 0;
      deductions.forEach(function (deduction) {
        if (_this6.isNumber(deduction)) {
          deductionsAmount += parseFloat(deduction);
        }
      }); // if Chauffer insurance has a value, make sure chauffeur weeks also does

      if (this.isNumber(this.get('payrollCheck.chauffeur_insurance')) && !this.get('payrollCheck.chauffeur_weeks')) {
        this.set('payrollCheck.chauffeur_weeks', 1);
      }

      this.set('payrollCheck.deductions_amount', deductionsAmount.toFixed(2));
      this.otherDeductionCalculations();
    },
    incomeTaxCalculations: function incomeTaxCalculations(earningsAmount) {
      if (this.calcStateIncomeTax && this.get('payrollCheck.calculate_state_income_tax')) {
        this.set('payrollCheck.state_income_tax', this.get('numFormat').truncate((earningsAmount - (this.get('payrollCheck.other_non_taxable_income') ? parseFloat(this.get('payrollCheck.other_non_taxable_income')) : 0)) * (parseFloat(this.get('payrollCheck.employee_rate.state_income_tax_percentage')) / 100)).toFixed(2));
      }

      if (this.calcFederalIncomeTax && this.get('payrollCheck.calculate_federal_income_tax')) {
        this.set('payrollCheck.federal_income_tax', this.get('numFormat').truncate((earningsAmount - (this.get('payrollCheck.other_non_taxable_income') ? parseFloat(this.get('payrollCheck.other_non_taxable_income')) : 0)) * (parseFloat(this.get('payrollCheck.employee_rate.federal_income_tax_percentage')) / 100)).toFixed(2));
      }

      if (this.calcOtherIncomeTax && this.get('payrollCheck.calculate_other_income_tax')) {
        this.set('payrollCheck.other_income_tax', this.get('numFormat').truncate((earningsAmount - (this.get('payrollCheck.other_non_taxable_income') ? parseFloat(this.get('payrollCheck.other_non_taxable_income')) : 0)) * (parseFloat(this.get('payrollCheck.employee_rate.other_income_tax_percentage')) / 100)).toFixed(2));
      }

      var incomeTaxTotal = (this.get('payrollCheck.state_income_tax') ? parseFloat(this.get('payrollCheck.state_income_tax')) : 0) + (this.get('payrollCheck.federal_income_tax') ? parseFloat(this.get('payrollCheck.federal_income_tax')) : 0) + (this.get('payrollCheck.other_income_tax') ? parseFloat(this.get('payrollCheck.other_income_tax')) : 0);
      this.set('payrollCheck.income_tax_total', this.get('numFormat').truncate(incomeTaxTotal).toFixed(2));
    },
    socialSecurityCalculations: function socialSecurityCalculations(earningsAmount) {
      if (this.get('payrollSettings.calc_ss')) {
        var tips = this.get('payrollCheck.tips') ? parseFloat(this.get('payrollCheck.tips')) : 0;
        var socialSecurity = (earningsAmount - tips) * (this.get('payrollInternalSetting.ss_percentage') / 100);
        var socialSecurityTips = (tips ? parseFloat(tips) : 0) * (this.get('payrollInternalSetting.ss_percentage') / 100);
        this.set('payrollCheck.social_security', this.get('numFormat').truncate(socialSecurity).toFixed(2));
        this.set('payrollCheck.social_security_tips', this.get('numFormat').truncate(socialSecurityTips).toFixed(2));
        var socialSecurityTotal = socialSecurity + socialSecurityTips;
        socialSecurityTotal += this.get('payrollCheck.uncollected_ss_tips') ? parseFloat(this.get('payrollCheck.uncollected_ss_tips')) : 0;
        this.set('payrollCheck.social_security_total', this.get('numFormat').truncate(socialSecurityTotal).toFixed(2));
      } else {
        this.set('payrollCheck.social_security_total', this.get('payrollCheck.uncollected_ss_tips') ? parseFloat(this.get('payrollCheck.uncollected_ss_tips')).toFixed(2) : 0);
      }
    },
    medicareOverAmount: function medicareOverAmount(filingStatus) {
      var overAmount;

      switch (filingStatus) {
        case 'Married Filing Jointly':
          overAmount = this.get('payrollInternalSetting.medicare_over_amount_married_filing_jointly');
          break;

        case 'Married Filing Separate':
          overAmount = this.get('payrollInternalSetting.medicare_over_amount_married_filing_separate');
          break;

        case 'Single':
          overAmount = this.get('payrollInternalSetting.medicare_over_amount_single');
          break;

        case 'Head of Household':
          overAmount = this.get('payrollInternalSetting.medicare_over_amount_head_of_household');
          break;

        case 'Qualifying Widow(er) with Dependent Child':
          overAmount = this.get('payrollInternalSetting.medicare_over_amount_qualifying_widower');
          break;

        default:
          break;
      }

      return overAmount;
    },
    medicareCalculations: function medicareCalculations(earningsAmount) {
      if (this.get('payrollSettings.calc_medicare')) {
        var ytdEarningsAmount = parseFloat(this.get('payrollCheck.ytd.regular_amount')) + parseFloat(this.get('payrollCheck.ytd.overtime_amount')) + parseFloat(this.get('payrollCheck.ytd.sick_amount')) + parseFloat(this.get('payrollCheck.ytd.vacation_amount')) + parseFloat(this.get('payrollCheck.ytd.other_income')) + parseFloat(this.get('payrollCheck.ytd.other_taxable_income'));
        ytdEarningsAmount -= this.get('payrollCheck.payroll_id') ? parseFloat(earningsAmount) : 0;
        var medicareOverAmount = this.medicareOverAmount(this.get('payrollCheck.employee.filing_status'));
        var medicare;

        if (ytdEarningsAmount && medicareOverAmount && ytdEarningsAmount && parseFloat(ytdEarningsAmount) >= medicareOverAmount) {
          medicare = this.get('numFormat').truncate(earningsAmount * ((this.get('payrollInternalSetting.medicare_percentage') + this.get('payrollInternalSetting.medicare_over_percentage')) / 100));
        } else if (earningsAmount && medicareOverAmount && earningsAmount && parseFloat(earningsAmount) >= medicareOverAmount) {
          medicare = this.get('numFormat').truncate(earningsAmount * (this.get('payrollInternalSetting.medicare_percentage') / 100));
          medicare += this.get('numFormat').truncate((earningsAmount - medicareOverAmount) * (this.get('payrollInternalSetting.medicare_over_percentage') / 100));
        } else if (ytdEarningsAmount && medicareOverAmount && ytdEarningsAmount + earningsAmount >= medicareOverAmount) {
          medicare = earningsAmount * (this.get('payrollInternalSetting.medicare_percentage') / 100);
          var medicareDifference = this.get('numFormat').truncate(ytdEarningsAmount + earningsAmount - medicareOverAmount);
          medicare += this.get('numFormat').truncate((earningsAmount - medicareDifference) * this.get('payrollInternalSetting.medicare_over_percentage') / 100);
        } else {
          medicare = this.get('numFormat').truncate(earningsAmount * (this.get('payrollInternalSetting.medicare_percentage') / 100));
        }

        var tips = this.get('payrollCheck.tips') ? parseFloat(this.get('payrollCheck.tips')) : 0;
        var medicareTips = (tips ? parseFloat(tips) : 0) * (this.get('payrollInternalSetting.medicare_percentage') / 100);
        medicare -= medicareTips;
        var medicareTotal = medicare;
        this.set('payrollCheck.medicare', medicare.toFixed(2));
        this.set('payrollCheck.medicare_tips', this.get('numFormat').truncate(medicareTips).toFixed(2));
        medicareTotal += medicareTips;
        medicareTotal += this.get('payrollCheck.uncollected_med_tips') ? parseFloat(this.get('payrollCheck.uncollected_med_tips')) : 0;
        this.set('payrollCheck.medicare_total', this.get('numFormat').truncate(medicareTotal).toFixed(2));
      } else {
        this.set('payrollCheck.medicare_total', this.get('payrollCheck.uncollected_med_tips') ? parseFloat(this.get('payrollCheck.uncollected_med_tips')).toFixed(2) : 0);
      }
    },
    disabilityCalculations: function disabilityCalculations(earningsAmount) {
      if (!this.get('payrollCheck.custom_disability')) {
        if (this.get('payrollCheck.employee_rate.calc_disability') && this.get('payrollSettings.disability_ceiling') && parseFloat(this.get('payrollSettings.disability_ceiling')) > 0) {
          var disability;
          var ytdEarningsAmount = parseFloat(this.get('payrollCheck.ytd.regular_amount')) + parseFloat(this.get('payrollCheck.ytd.overtime_amount')) + parseFloat(this.get('payrollCheck.ytd.sick_amount')) + parseFloat(this.get('payrollCheck.ytd.vacation_amount')) + parseFloat(this.get('payrollCheck.ytd.other_income')) + parseFloat(this.get('payrollCheck.ytd.other_taxable_income')) - parseFloat(this.get('payrollCheck.ytd.bonus'));
          var disabilityRemainingCeiling = parseFloat(this.get('payrollSettings.disability_ceiling')) - (ytdEarningsAmount - earningsAmount);

          if (disabilityRemainingCeiling >= 0) {
            var bonus = this.get('payrollCheck.bonus') || 0; // Bonus should be included in disability calculation per caribbean payroll information - April 2024
            // if (disabilityRemainingCeiling - (earningsAmount - parseFloat(bonus)) <= 0) {

            if (disabilityRemainingCeiling - earningsAmount <= 0) {
              disability = disabilityRemainingCeiling * (parseFloat(this.get('payrollSettings.disability_percentage')) / 100);
            } else {
              // disability = (earningsAmount - parseFloat(bonus)) * (parseFloat(this.get('payrollSettings.disability_percentage'))/100);
              disability = earningsAmount * (parseFloat(this.get('payrollSettings.disability_percentage')) / 100);
            }
          } else if (!disabilityRemainingCeiling) {
            disability = earningsAmount * (parseFloat(this.get('payrollSettings.disability_percentage')) / 100);
          }

          this.set('payrollCheck.disability', this.get('numFormat').truncate(disability).toFixed(2));
        }
      }
    },
    otherIncomeCalculations: function otherIncomeCalculations() {
      var otherIncomeData = [this.get('payrollCheck.bonus'), this.get('payrollCheck.commission'), this.get('payrollCheck.tips'), this.get('payrollCheck.allowances')];
      var otherIncome = 0;
      otherIncomeData.forEach(function (income) {
        if (income) {
          otherIncome += parseFloat(income);
        }
      });
      var otherTaxableIncome = [this.get('payrollCheck.other_taxable_earnings_one'), this.get('payrollCheck.other_taxable_earnings_two'), this.get('payrollCheck.other_taxable_earnings_three'), this.get('payrollCheck.other_taxable_earnings_four'), this.get('payrollCheck.other_taxable_earnings_five')];
      var otherNonTaxableIncome = [this.get('payrollCheck.other_non_taxable_earnings_one'), this.get('payrollCheck.other_non_taxable_earnings_two'), this.get('payrollCheck.other_non_taxable_earnings_three'), this.get('payrollCheck.other_non_taxable_earnings_four'), this.get('payrollCheck.other_non_taxable_earnings_five')];
      var taxableIncomeAmount = 0;
      var nonTaxableIncomeAmount = 0;
      otherTaxableIncome.forEach(function (income) {
        if (income) {
          taxableIncomeAmount += parseFloat(income);
        }
      });
      otherNonTaxableIncome.forEach(function (income) {
        if (income) {
          nonTaxableIncomeAmount += parseFloat(income);
        }
      });
      this.setProperties({
        'payrollCheck.other_taxable_income': this.get('numFormat').truncate(taxableIncomeAmount).toFixed(2),
        'payrollCheck.other_non_taxable_income': this.get('numFormat').truncate(nonTaxableIncomeAmount).toFixed(2),
        'payrollCheck.other_income': this.get('numFormat').truncate(otherIncome).toFixed(2)
      });
    },
    otherDeductionCalculations: function otherDeductionCalculations() {
      var otherDeductions = [this.get('payrollCheck.other_deduction_one'), this.get('payrollCheck.other_deduction_two'), this.get('payrollCheck.other_deduction_three'), this.get('payrollCheck.other_deduction_four'), this.get('payrollCheck.other_deduction_five'), this.get('payrollCheck.retirement_plan'), this.get('payrollCheck.uncollected_med_tips'), this.get('payrollCheck.uncollected_ss_tips'), this.get('payrollCheck.union_dues'), this.get('payrollCheck.life_insurance'), this.get('payrollCheck.profit_sharing'), this.get('payrollCheck.coda_plan'), this.get('payrollCheck.cola_plan')];
      var otherDeductionsAmount = 0;
      otherDeductions.forEach(function (deduction) {
        if (deduction) {
          otherDeductionsAmount += parseFloat(deduction);
        }
      });
      this.set('payrollCheck.other_deductions_total', this.get('numFormat').truncate(otherDeductionsAmount).toFixed(2));
    },
    autoCalculations: function autoCalculations() {
      var earningsAmount = this.get('payrollCheck.earnings_amount');
      earningsAmount -= this.get('payrollCheck.reimbursements') || 0;
      this.otherIncomeCalculations();
      this.incomeTaxCalculations(earningsAmount);
      var nonTaxAmount = earningsAmount - (this.get('payrollCheck.other_non_taxable_income') ? parseFloat(this.get('payrollCheck.other_non_taxable_income')) : 0);
      this.socialSecurityCalculations(nonTaxAmount);
      this.medicareCalculations(nonTaxAmount);
      this.disabilityCalculations(nonTaxAmount);
    },
    earningsObserver: Ember.observer('payrollSettings', 'payrollCheck', 'payrollCheck.regular_amount', 'payrollCheck.overtime_amount', 'payrollCheck.vacation_amount', 'payrollCheck.sick_amount', 'payrollCheck.bonus', 'payrollCheck.allowances', 'payrollCheck.commission', 'payrollCheck.tips', 'payrollCheck.reimbursements', 'payrollCheck.other_taxable_earnings_one', 'payrollCheck.other_taxable_earnings_two', 'payrollCheck.other_taxable_earnings_three', 'payrollCheck.other_taxable_earnings_four', 'payrollCheck.other_taxable_earnings_five', 'payrollCheck.other_non_taxable_earnings_one', 'payrollCheck.other_non_taxable_earnings_two', 'payrollCheck.other_non_taxable_earnings_three', 'payrollCheck.other_non_taxable_earnings_four', 'payrollCheck.other_non_taxable_earnings_five', 'payrollCheck.uncollected_ss_tips', 'payrollCheck.custom_disability', 'payrollCheck.void', 'payrollCheck.state_income_tax', 'payrollCheck.federal_income_tax', 'payrollCheck.other_income_tax', function () {
      this.calculateEarnings();
      this.autoCalculations();
    }),
    deductionsObserver: Ember.observer('payrollSettings', 'payrollCheck', 'payrollCheck.state_income_tax', 'payrollCheck.federal_income_tax', 'payrollCheck.other_income_tax', 'payrollCheck.medical_plan', 'payrollCheck.retirement_plan', 'payrollCheck.uncollected_med_tips', 'payrollCheck.uncollected_ss_tips', 'payrollCheck.chauffeur_insurance', 'payrollCheck.social_security', 'payrollCheck.social_security_tips', 'payrollCheck.medicare', 'payrollCheck.disability', 'payrollCheck.child_support', 'payrollCheck.garnishment', 'payrollCheck.union_dues', 'payrollCheck.life_insurance', 'payrollCheck.profit_sharing', 'payrollCheck.coda_plan', 'payrollCheck.cola_plan', 'payrollCheck.other_deduction_one', 'payrollCheck.other_deduction_two', 'payrollCheck.other_deduction_three', 'payrollCheck.other_deduction_four', 'payrollCheck.other_deduction_five', 'payrollCheck.void', function () {
      this.calculateDeductions();
    }),
    actions: {
      enableInput: function enableInput(inputType) {
        Ember.set(this.get('enabled'), inputType, true);

        var _this = this;

        setTimeout(function () {
          (0, _jquery.default)("#".concat(inputType).concat(_this.get('payrollCheck.employee_rate_id'))).focus();
        }, 10);
      },
      calcStateIncomeTaxToggle: function calcStateIncomeTaxToggle(val) {
        if (val) {
          var earningsAmount = this.get('payrollCheck.earnings_amount');
          earningsAmount -= this.get('payrollCheck.reimbursements') || 0;
          this.otherIncomeCalculations();
          this.incomeTaxCalculations(earningsAmount);
        }

        this.set('payrollCheck.calculate_state_income_tax', val);
      },
      calcFederalIncomeTaxToggle: function calcFederalIncomeTaxToggle(val) {
        if (val) {
          var earningsAmount = this.get('payrollCheck.earnings_amount');
          earningsAmount -= this.get('payrollCheck.reimbursements') || 0;
          this.otherIncomeCalculations();
          this.incomeTaxCalculations(earningsAmount);
        }

        this.set('payrollCheck.calculate_federal_income_tax', val);
      },
      calcOtherIncomeTaxToggle: function calcOtherIncomeTaxToggle(val) {
        if (val) {
          var earningsAmount = this.get('payrollCheck.earnings_amount');
          earningsAmount -= this.get('payrollCheck.reimbursements') || 0;
          this.otherIncomeCalculations();
          this.incomeTaxCalculations(earningsAmount);
        }

        this.set('payrollCheck.calculate_other_income_tax', val);
      },
      setLabels: function setLabels(index, labels) {
        this.setProperties({
          'payrollCheck.labels': labels,
          'payrollCheck.label_ids': labels.map(function (label) {
            return label.id;
          })
        });
      },
      toggleConfirmVoid: function toggleConfirmVoid() {
        this.toggleProperty('confirmVoid');
      },
      showConfirmPrint: function showConfirmPrint(employeeId) {
        this.sendAction('showConfirmPrint', employeeId);
      },
      voidCheck: function voidCheck() {
        this.setProperties({
          'payrollCheck.regular_amount': null,
          'payrollCheck.overtime_amount': null,
          'payrollCheck.vacation_amount': null,
          'payrollCheck.regular_hours': null,
          'payrollCheck.overtime_hours': null,
          'payrollCheck.vacation_hours': null,
          'payrollCheck.sick_hours': null,
          'payrollCheck.bonus': null,
          'payrollCheck.allowances': null,
          'payrollCheck.commission': null,
          'payrollCheck.tips': null,
          'payrollCheck.reimbursements': null,
          'payrollCheck.other_taxable_earnings_one': null,
          'payrollCheck.other_taxable_earnings_two': null,
          'payrollCheck.other_taxable_earnings_three': null,
          'payrollCheck.other_taxable_earnings_four': null,
          'payrollCheck.other_taxable_earnings_five': null,
          'payrollCheck.other_non_taxable_earnings_one': null,
          'payrollCheck.other_non_taxable_earnings_two': null,
          'payrollCheck.other_non_taxable_earnings_three': null,
          'payrollCheck.other_non_taxable_earnings_four': null,
          'payrollCheck.other_non_taxable_earnings_five': null,
          'payrollCheck.uncollected_ss_tips': null,
          'payrollCheck.custom_disability': null,
          'payrollCheck.state_income_tax': null,
          'payrollCheck.federal_income_tax': null,
          'payrollCheck.other_income_tax': null,
          'payrollCheck.medical_plan': null,
          'payrollCheck.retirement_plan': null,
          'payrollCheck.uncollected_med_tips': null,
          'payrollCheck.chauffeur_insurance': null,
          'payrollCheck.social_security': null,
          'payrollCheck.social_security_tips': null,
          'payrollCheck.medicare': null,
          'payrollCheck.disability': null,
          'payrollCheck.child_support': null,
          'payrollCheck.garnishment': null,
          'payrollCheck.union_dues': null,
          'payrollCheck.life_insurance': null,
          'payrollCheck.profit_sharing': null,
          'payrollCheck.coda_plan': null,
          'payrollCheck.cola_plan': null,
          'payrollCheck.other_deduction_one': null,
          'payrollCheck.other_deduction_two': null,
          'payrollCheck.other_deduction_three': null,
          'payrollCheck.other_deduction_four': null,
          'payrollCheck.other_deduction_five': null,
          'payrollCheck.void': true
        }); // this.set('payrollCheck', {
        //     id: this.get('payrollCheck.id'), 
        //     employee_id: this.get('payrollCheck.employee_id'),
        //     employee: this.get('payrollCheck.employee'),
        //     employee_rate_id: this.get('payrollCheck.employee_rate_id'),
        //     employee_rate: this.get('payrollCheck.employee_rate'),
        //     labels: this.get('payrollCheck.labels'),
        //     check_number: this.get('payrollCheck.check_number'),
        //     payroll_id: this.get('payrollCheck.payroll_id'),
        //     check_id: this.get('payrollCheck.check_id'),
        //     earnings_amount: 0,
        //     deductions_amount: 0,
        //     calculate_state_income_tax: true,
        //     calculate_federal_income_tax: true,
        //     calculate_other_income_tax: true,
        //     custom_disability: false,
        //     void: true
        // });

        this.set('confirmVoid', false);
      }
    }
  });

  _exports.default = _default;
});