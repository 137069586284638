define("account-berry-frontend/companies/show/employees/payroll-settings/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    payrollPeriods: ["Weekly", "Bi-Weekly", "Monthly", "Semi-Monthly"],
    basicTab: true,
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['employees'];
    }),
    init: function init() {
      _jquery.default.initialize('input[type=number]', function () {
        var inputs = (0, _jquery.default)('input[type=number]');

        for (var i = 0; i < inputs.length; i++) {
          if (parseFloat(inputs[i].value)) {
            inputs[i].value = parseFloat(inputs[i].value).toFixed(2);
          }
        }
      });
    },
    modelObserver: Ember.observer('model', function () {
      var inputs = (0, _jquery.default)('input[type=number]');

      for (var i = 0; i < inputs.length; i++) {
        if (parseFloat(inputs[i].value)) {
          inputs[i].value = parseFloat(inputs[i].value).toFixed(2);
        }
      }
    }),
    customAttributesObserver: Ember.observer('payrollSettings.other_deduction_one', 'payrollSettings.other_deduction_two', 'payrollSettings.other_deduction_three', 'payrollSettings.other_deduction_four', 'payrollSettings.other_deduction_five', 'payrollSettings.other_taxable_earnings_one', 'payrollSettings.other_taxable_earnings_two', 'payrollSettings.other_taxable_earnings_three', 'payrollSettings.other_taxable_earnings_four', 'payrollSettings.other_taxable_earnings_five', 'payrollSettings.other_non_taxable_earnings_two', 'payrollSettings.other_non_taxable_earnings_three', 'payrollSettings.other_non_taxable_earnings_four', 'payrollSettings.other_non_taxable_earnings_five', function () {
      var _this = this;

      if (this.get('payrollSettings')) {
        var customAttributes = [{
          attr: this.get('payrollSettings.other_deduction_one'),
          account_id: this.get('payrollSettings.other_deduction_one_account_id'),
          key: 'payrollSettings.other_deduction_one_account_id'
        }, {
          attr: this.get('payrollSettings.other_deduction_two'),
          account_id: this.get('payrollSettings.other_deduction_two_account_id'),
          key: 'payrollSettings.other_deduction_two_account_id'
        }, {
          attr: this.get('payrollSettings.other_deduction_three'),
          account_id: this.get('payrollSettings.other_deduction_three_account_id'),
          key: 'payrollSettings.other_deduction_three_account_id'
        }, {
          attr: this.get('payrollSettings.other_deduction_four'),
          account_id: this.get('payrollSettings.other_deduction_four_account_id'),
          key: 'payrollSettings.other_deduction_four_account_id'
        }, {
          attr: this.get('payrollSettings.other_deduction_five'),
          account_id: this.get('payrollSettings.other_deduction_five_account_id'),
          key: 'payrollSettings.other_deduction_five_account_id'
        }, {
          attr: this.get('payrollSettings.other_taxable_earnings_one'),
          account_id: this.get('payrollSettings.other_taxable_earnings_one_account_id'),
          key: 'payrollSettings.other_taxable_earnings_one_account_id'
        }, {
          attr: this.get('payrollSettings.other_taxable_earnings_two'),
          account_id: this.get('payrollSettings.other_taxable_earnings_two_account_id'),
          key: 'payrollSettings.other_taxable_earnings_two_account_id'
        }, {
          attr: this.get('payrollSettings.other_taxable_earnings_three'),
          account_id: this.get('payrollSettings.other_taxable_earnings_three_account_id'),
          key: 'payrollSettings.other_taxable_earnings_three_account_id'
        }, {
          attr: this.get('payrollSettings.other_taxable_earnings_four'),
          account_id: this.get('payrollSettings.other_taxable_earnings_four_account_id'),
          key: 'payrollSettings.other_taxable_earnings_four_account_id'
        }, {
          attr: this.get('payrollSettings.other_taxable_earnings_five'),
          account_id: this.get('payrollSettings.other_taxable_earnings_five_account_id'),
          key: 'payrollSettings.other_taxable_earnings_five_account_id'
        }, {
          attr: this.get('payrollSettings.other_non_taxable_earnings_two'),
          account_id: this.get('payrollSettings.other_non_taxable_earnings_two_account_id'),
          key: 'payrollSettings.other_non_taxable_earnings_two_account_id'
        }, {
          attr: this.get('payrollSettings.other_non_taxable_earnings_three'),
          account_id: this.get('payrollSettings.other_non_taxable_earnings_three_account_id'),
          key: 'payrollSettings.other_non_taxable_earnings_three_account_id'
        }, {
          attr: this.get('payrollSettings.other_non_taxable_earnings_four'),
          account_id: this.get('payrollSettings.other_non_taxable_earnings_four_account_id'),
          key: 'payrollSettings.other_non_taxable_earnings_four_account_id'
        }, {
          attr: this.get('payrollSettings.other_non_taxable_earnings_five'),
          account_id: this.get('payrollSettings.other_non_taxable_earnings_five_account_id'),
          key: 'payrollSettings.other_non_taxable_earnings_five_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.garnishment_account_id'),
          key: 'payrollSettings.garnishment_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.union_dues_account_id'),
          key: 'payrollSettings.union_dues_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.life_insurance_account_id'),
          key: 'payrollSettings.life_insurance_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.profit_sharing_account_id'),
          key: 'payrollSettings.profit_sharing_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.coda_plan_account_id'),
          key: 'payrollSettings.coda_plan_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.cola_plan_account_id'),
          key: 'payrollSettings.cola_plan_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.medical_plan_account_id'),
          key: 'payrollSettings.medical_plan_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.retirement_plan_account_id'),
          key: 'payrollSettings.retirement_plan_account_id'
        }, {
          attr: true,
          account_id: this.get('payrollSettings.payrollSettings.other_income_tax_account_id'),
          key: 'payrollSettings.other_income_tax_account_id'
        }];
        customAttributes.forEach(function (attribute) {
          if ((!attribute.attr || attribute.attr === "") && attribute.account_id) {
            _this.set(attribute.key, null);
          }
        });
      }
    }),
    actions: {
      updateAccount: function updateAccount(value, attributeName) {
        this.set("model.".concat(attributeName), value);
      },
      updatePayrollPeriod: function updatePayrollPeriod(value) {
        this.set('model.payroll_period', value);
      },
      showTab: function showTab(tab) {
        this.setProperties({
          'basicTab': tab === "basic",
          'deductionsTab': tab === "deductions",
          'earningsTab': tab === "earnings",
          'vacationSicknessTab': tab === "vacationSickness"
        });
      },
      update: function update() {
        var _this2 = this;

        this.store.findRecord('payroll-setting', 1, {
          reload: true
        }).then(function (payrollSettings) {
          payrollSettings.setProperties(_this2.payrollSettings);
          payrollSettings.save().then(function () {
            _this2.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Payroll Settings Updated</span></div>'
            });
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            payrollSettings.rollbackAttributes();

            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      updateVacationAccrualType: function updateVacationAccrualType(accrualType) {
        this.set('payrollSettings.vacation_accrual_type', accrualType);
      },
      updateSicknessAccrualType: function updateSicknessAccrualType(accrualType) {
        this.set('payrollSettings.sickness_accrual_type', accrualType);
      },
      updateVacationAccrualExpiration: function updateVacationAccrualExpiration(accrualExpiration) {
        this.set('payrollSettings.vacation_accrual_expiration', accrualExpiration);
      },
      updateSicknessAccrualExpiration: function updateSicknessAccrualExpiration(accrualExpiration) {
        this.set('payrollSettings.sickness_accrual_expiration', accrualExpiration);
      },
      updateVacationExpirationDate: function updateVacationExpirationDate(expirationDate) {
        this.set('payrollSettings.vacation_expiration_date', expirationDate);
      },
      updateSicknessExpirationDate: function updateSicknessExpirationDate(expirationDate) {
        this.set('payrollSettings.sickness_expiration_date', expirationDate);
      },
      cancel: function cancel() {
        this.transitionToRoute('companies.show.employees');
      }
    }
  });

  _exports.default = _default;
});