define("account-berry-frontend/journal-entry/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    clear: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    date: _emberData.default.attr(),
    je_number: _emberData.default.attr(),
    journal_entry_accounts: _emberData.default.attr(),
    accounts: _emberData.default.attr(),
    uneditable: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    recurrency: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr(),
    duplicate: _emberData.default.attr()
  });

  _exports.default = _default;
});