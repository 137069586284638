define("account-berry-frontend/components/twitter-feed/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'twitterFeed',
    classNames: ['twitter_feed'],
    _insertedNode: null,
    didInsertElement: function didInsertElement() {
      // let elementId = this.get('elementId');
      var insertedNode = null;
      /* Widget JS provided by Twitter start */

      /*jshint ignore:start*/

      !function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0],
            p = /^http:/.test(d.location) ? 'http' : 'https';

        if (!d.getElementById(id)) {
          js = d.createElement(s);
          js.id = id;
          js.src = p + "://platform.twitter.com/widgets.js";
          insertedNode = fjs.parentNode.insertBefore(js, fjs); // custom
        } else {
          Ember.Logger.warn('A #twitter-wjs DOM node is already present.'); // custom

          Ember.Logger.warn('Please verify that you are NOT including the JS line provided by Twitter.'); // custom
        }

        (0, _jquery.default)('.timeline-Widget').css('overflow', 'scroll');
        (0, _jquery.default)('.timeline-Widget').css('height', '100%');
      }(document, "script", "twitter-wjs");
      /*jshint ignore:end*/

      /* Widget JS provided by Twitter end */

      this._insertedNode = insertedNode;
    },
    willClearRender: function willClearRender() {
      (0, _jquery.default)(this._insertedNode).remove();
    }
  });

  _exports.default = _default;
});