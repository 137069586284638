define("account-berry-frontend/components/account-groupcode/component", ["exports", "account-berry-frontend/utils/tax-group-codes"], function (_exports, _taxGroupCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    taxGroupCodes: Ember.computed('accountTypeId', function () {
      var _this = this;

      if (this.get('accountTypeId')) {
        var accountType = this.get('session.data.authenticated.account_types').find(function (accountType) {
          return accountType.id.toString() === _this.get('accountTypeId').toString();
        });
        return (0, _taxGroupCodes.default)().filter(function (taxGroupCode) {
          return taxGroupCode.category === accountType.group;
        });
      }
    }),
    actions: {
      updateAccountGroupCode: function updateAccountGroupCode(value) {
        this.sendAction('updateAccountGroupCode', this.get('account'), value);
      }
    }
  });

  _exports.default = _default;
});