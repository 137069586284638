define("account-berry-frontend/companies/show/bank-sync/route", ["exports", "account-berry-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('plaid-link', {
        reload: true
      }).then(function (linkedAccounts) {
        return linkedAccounts.toArray();
      });
    },
    setupController: function setupController(controller) {
      controller.set('breadCrumb', 'Bank Sync');
      controller.set('companyId', this.paramsFor('companies.show').company_id);
      var company = this.store.peekRecord('company', this.paramsFor('companies.show').company_id);

      if (company.get('stripe_plan_id') !== _environment.default.stripe_plan_id.gold_plan) {
        controller.set('notInGold', true);
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This feature is only available in the gold subscription plan</span></div>"
        });
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('companies.show.bank-sync', {
        into: 'application',
        model: model
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});