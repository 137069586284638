define("account-berry-frontend/invoice/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invoice_date: _emberData.default.attr(),
    invoice_due_date: _emberData.default.attr(),
    ref_number: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    customer_id: _emberData.default.attr(),
    estimate: _emberData.default.attr('boolean'),
    representative_id: _emberData.default.attr(),
    total_amount: _emberData.default.attr('number'),
    memo: _emberData.default.attr(),
    customer: _emberData.default.attr(),
    freight_amount: _emberData.default.attr('number'),
    discount_amount: _emberData.default.attr('number'),
    discount_percentage: _emberData.default.attr('number'),
    net_amount: _emberData.default.attr('number'),
    tax_amount: _emberData.default.attr('number'),
    undeletable: _emberData.default.attr(),
    sold_items: _emberData.default.attr(),
    amount_due: _emberData.default.attr('number'),
    terms: _emberData.default.attr('string'),
    labels: _emberData.default.attr(),
    label_ids: _emberData.default.attr(),
    sent_at: _emberData.default.attr(),
    ship_to: _emberData.default.attr(),
    bill_to: _emberData.default.attr(),
    email: _emberData.default.attr(),
    duplicate: _emberData.default.attr(),
    recurrency: _emberData.default.attr()
  });

  _exports.default = _default;
});