define("account-berry-frontend/companies/show/reports/trial-balance/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    init: function init() {// let channel = this.get('session.pusher').subscribe(`trial-balance-channel-${this.get('session.data.authenticated.id')}`);
      // channel.bind('report', (response) => {
      //   this.store.findRecord('json-storage', response.json_storage_id).then((jsonStorage) => {
      //     this.set('trialBalanceData', jsonStorage.get('stored_json.report'));
      //     this.get('applicationController').set('showLoading', false);
      //   });
      // });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getTrialBalance: function getTrialBalance() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date
          },
          report_type: 'trial_balance'
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "TrialBalance" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-TrialBalance');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('trialBalanceData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-TrialBalance disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this3 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this3.set('account', account); // this.transactionsActionCable();


          _this3.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      print: function print() {
        (0, _jquery.default)('#trialBalance').printThis();
      },
      csvExport: function csvExport() {
        (0, _jquery.default)('#trialBalanceTable').table2CSV({
          delivery: 'download',
          filename: 'TrialBalanceTable.csv'
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
      }
    }
  });

  _exports.default = _default;
});