define("account-berry-frontend/companies/show/reports/unpaid-bills/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    actions: {
      getUnpaidBills: function getUnpaidBills() {
        var _this2 = this;

        var data = JSON.stringify({
          report: {
            date: this.date
          },
          report_type: 'unpaid_bills'
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reports"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this2.get('applicationController').set('showLoading', true);

            var channel = _this2.get('session.pusher').subscribe("unpaid-bills-channel-".concat(_this2.get('session.data.authenticated.id')));

            channel.bind('report', function (response) {
              _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {});
            });
          },
          errorCallback: function errorCallback() {
            _this2.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "UnpaidBills" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-UnpaidBills');

            _this.get('ajaxCall').request(ajaxOptions);
          },
          received: function received(data) {
            if (data.error) {
              _this2.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this2.set('unpaidBillsData', data.report);

              consumer.disconnect();

              _this2.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-UnpaidBills disconnected");
          }
        });
      },
      filterByVendor: function filterByVendor(vendor) {
        this.set('selectedVendor', vendor);
      },
      print: function print() {
        (0, _jquery.default)('#unpaidBills').printThis();
      },
      csvExport: function csvExport() {
        (0, _jquery.default)('.table').table2CSV({
          delivery: 'download',
          filename: 'UnpaidBillsTable.csv'
        }); // let table = "<table id='unpaidBillsTable' style='width:100%; font-size: 14px'><thead>";
        // table += "<tr><th></th><th>TYPE</th><th>DATE</th><th>NUMBER</th>";
        // table +="<th>DUE DATE</th><th>AGING</th><th>OPEN BALANCE</th></thead>";
        // table += "<tbody>";
        // this.get('unpaidBillsData.vendors').forEach((vendor) => {
        //   table += `<tr><td>${vendor.name}</td><td></td><td></td><td></td><td></td><td></td></tr>`;
        //   vendor.transactions.forEach((transaction) => {
        //     table += `<tr><td>${transaction.type}</td>`;
        //     table += `<td>${transaction.date}</td>`;
        //     table += `<td>${transaction.number}</td>`;
        //     table += `<td>${transaction.due_date ? transaction.due_date : ""}</td>`;
        //     table += `<td>${transaction.aging ? transaction.aging : ""}</td>`;
        //     table += `<td>${parseFloat(transaction.open_balance).toFixed(2)}</td></tr>`;
        //   });
        //   table += `<tr><td>Total ${vendor.name}</td><td></td><td></td><td></td><td></td>`;
        //   table += `<td>${parseFloat(vendor.total).toFixed(2)}</td></tr>`;
        // });
        // table += "<tr><td>Total</td><td></td><td></td><td></td><td></td>";
        // table += `<td>${parseFloat(this.get('unpaidBillsData.total')).toFixed(2)}</td></tr>`;
        // table += "</tbody></table>";
        // $('#csvExport').show();
        // document.getElementById('csvExport').innerHTML += table;
        // $('#unpaidBillsTable').table2CSV({
        //   delivery: 'download',
        //   filename: 'UnpaidBillsTable.csv'
        // });
        // // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
        // $('#unpaidBillsTable').remove();
        // $('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});