define("account-berry-frontend/components/video-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nF8d5Pll",
    "block": "{\"symbols\":[],\"statements\":[[4,\"add-tooltip\",null,[[\"el\",\"title\",\"class\",\"placement\"],[\"#navHelp\",\"HELP\",\"navbar\",\"right\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"help-icon-wrapper clickable\"],[3,\"action\",[[19,0,[]],\"showModal\"]],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"id\",\"navHelp\"],[10,\"class\",[26,[\"icon-camera lg \",[18,\"theme\"]]]],[7],[8],[0,\"\\n\"],[0,\"    \"],[6,\"span\"],[10,\"class\",[26,[\"nav-item-name \",[18,\"theme\"]]]],[7],[0,\"HELP\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"showVideo\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"targetAttachment\",\"onClose\",\"targetAttachment\",\"clickOutsideToClose\",\"hasOverlay\"],[\"center\",[25,\"action\",[[19,0,[]],\"hideModal\"],null],\"center\",true,true]],{\"statements\":[[4,\"if\",[[20,[\"selectedVideo\",\"title\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"modal-header\"],[7],[0,\"\\n          \"],[6,\"h5\"],[9,\"class\",\"modal-title\"],[10,\"id\",[26,[\"exampleModalLongTitle\",[18,\"index\"]]]],[7],[1,[20,[\"selectedVideo\",\"title\"]],false],[8],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"clickable icon-cross icon-styling\"],[3,\"action\",[[19,0,[]],\"hideModal\"]],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n          \"],[6,\"iframe\"],[9,\"id\",\"ytplayer\"],[9,\"type\",\"text/html\"],[9,\"width\",\"705\"],[9,\"height\",\"415\"],[10,\"src\",[26,[\"https://www.youtube.com/embed/\",[20,[\"selectedVideo\",\"embeded\"]]]]],[9,\"frameborder\",\"0\"],[9,\"allowfullscreen\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"h3\"],[7],[0,\"We Currently Do Not Have a Video Tutorial for this Section.\"],[8],[0,\"\\n      \"],[6,\"h4\"],[7],[0,\"Please let us know if you would like a video tutorial for this section.\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "account-berry-frontend/components/video-help/template.hbs"
    }
  });

  _exports.default = _default;
});