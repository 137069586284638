define("account-berry-frontend/companies/show/bank-sync/account-categorization/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    search: "",
    sortAscending: false,
    filter: "category",
    filters: Ember.computed(function () {
      return [{
        name: "CATEGORY",
        value: "category"
      }, {
        name: "SPECIFIC CATEGORY",
        value: "specific_category"
      }, {
        name: "ACCOUNT",
        value: "account_name"
      }];
    }),
    filteredCategories: Ember.computed('model', function () {
      return this.get('model');
    }),
    accounts: Ember.computed('companyId', function () {
      return this.store.peekAll('account');
    }),
    filterCategories: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('filteredCategories', this.model);
      } else {
        this.set('filteredCategories', this.model.filter(function (category) {
          var categoryValue = category.get(filter);

          if (categoryValue) {
            if (isNaN(categoryValue)) {
              categoryValue = categoryValue.toLowerCase();
            }

            if (categoryValue.includes(search)) {
              return category;
            }
          }
        }));
      }
    }),
    actions: {
      update: function update(accountId, index) {
        var _this = this;

        this.get('filteredCategories')[index].account_id = accountId; // this.get('filteredCategories')[index].set('account_id', accountId)

        var account = this.store.peekRecord('account', accountId);
        this.get('filteredCategories')[index].account_name = (account.get('number') || "") + " " + account.get('name'); // this.get('filteredCategories')[index].set('account_name', (account.get('number') || "") + " " + account.get('name'))

        this.get('filteredCategories')[index].save().then(function (category) {
          _this.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Category Account Updated</span></div>'
          });
        });
      },
      toggleIsEditing: function toggleIsEditing(category) {
        category.toggleProperty('edit', true);
      },
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredCategories', this.get('filteredCategories').sortBy(property));
        } else {
          this.set('filteredCategories', this.get('filteredCategories').sortBy(property).reverse());
        } // this.set('pagedRepresentatives', pagedArray('model', { infinite: "unpaged", page: 1, perPage: 100 }));

      }
    }
  });

  _exports.default = _default;
});