define("account-berry-frontend/companies/show/journal-entries/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array", "account-berry-frontend/config/environment"], function (_exports, _jquery, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    ajaxCall: Ember.inject.service(),
    pagedJournalEntries: (0, _pagedArray.default)('filteredJournalEntries', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredJournalEntries', this.store.peekAll('journal-entry').filter(function (journalEntry) {
          return !journalEntry.get('isDeleted');
        }));
        this.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    filteredJournalEntries: Ember.computed('model', function () {
      return this.model;
    }),
    totalJournalEntries: Ember.computed('filteredJournalEntries', function () {
      return this.get('filteredJournalEntries.length');
    }),
    filter: "je_number",
    filters: [{
      name: "NUMBER",
      value: "je_number"
    }, {
      name: "DATE",
      value: "date"
    }],
    filterJournalEntries: Ember.observer('search', 'filterLabelIds', function () {
      var _this2 = this;

      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredJournalEntries', this.model);
      } else {
        this.set('filteredJournalEntries', this.model.filter(function (journalEntry) {
          var journalEntryValue = journalEntry.get(filter);

          if (journalEntryValue) {
            if (isNaN(journalEntryValue)) {
              journalEntryValue = journalEntryValue.toLowerCase();
            }

            if (journalEntryValue.includes(search)) {
              return journalEntry;
            }
          }
        }));
      }

      if (this.get('filterLabelIds') && this.get('filterLabelIds').length > 0) {
        var hasLabel;
        this.set('filteredJournalEntries', this.get('filteredJournalEntries').filter(function (je) {
          hasLabel = false;

          var _iterator = _createForOfIteratorHelper(_this2.get('filterLabelIds')),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var label = _step.value;
              hasLabel = je.get('labels').find(function (l) {
                return l.id.toString() === label.toString();
              });

              if (!hasLabel) {
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (hasLabel) {
            return je;
          }
        }));
      }

      this.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    filterJournalEntriesByDate: Ember.observer('fromDateFilter', 'toDateFilter', function () {
      var fromDateFilter = this.get('fromDateFilter');
      var toDateFilter = this.get('toDateFilter');

      if (fromDateFilter || toDateFilter) {
        this.set('filteredJournalEntries', this.get('model').sortBy('date').filter(function (journal_entry) {
          if (fromDateFilter && toDateFilter) {
            if (journal_entry.get('date') >= fromDateFilter && journal_entry.get('date') <= toDateFilter) {
              return journal_entry;
            }
          } else if (fromDateFilter && !toDateFilter) {
            if (journal_entry.get('date') >= fromDateFilter) {
              return journal_entry;
            }
          } else if (!fromDateFilter && toDateFilter) {
            if (journal_entry.get('date') <= toDateFilter) {
              return journal_entry;
            }
          }
        }));
      } else {
        this.set('filteredJournalEntries', this.get('model'));
      }

      this.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this3 = this;

      var channel = this.get('session.pusher').subscribe("journal-entry-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this3.get('session.data.authenticated.id').toString()) {
          _this3.updateDepositList(data.journal_entry);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this3.get('session.data.authenticated.id').toString()) {
          var journalEntryId = data.journal_entry.id;
          delete data.journal_entry.id;

          var journalEntry = _this3.store.peekRecord('journal-entry', journalEntryId);

          journalEntry.setProperties(data.journal_entry);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this3.get('session.data.authenticated.id').toString()) {
          var journalEntry = _this3.store.peekRecord('journal-entry', data.journal_entry_id);

          journalEntry.deleteRecord();

          _this3.set('filteredJournalEntries', _this3.store.peekAll('journal-entry').filter(function (journalEntry) {
            return !journalEntry.get('isDeleted');
          }));

          _this3.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateDepositList: function updateDepositList(journalEntry) {
      this.store.push({
        data: {
          id: journalEntry.id,
          type: 'journal-entry',
          attributes: journalEntry
        }
      });
      this.set('filteredJournalEntries', this.store.peekAll('journal-entry').filter(function (journalEntry) {
        return !journalEntry.get('isDeleted');
      }));
      this.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    isGold: Ember.computed('company', function () {
      return this.get('company').get('stripe_plan_id') === _environment.default.stripe_plan_id.gold_plan;
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center'>Journal Entries</h3><table id='journalEntriesTable' style='width:100%; font-size: 14px'><thead><tr style='text-align: left;'><th>NUMBER</th>");
      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: right;'>AMOUNT</th></tr></thead>";
      this.get('filteredJournalEntries').forEach(function (journalEntry) {
        table += "<tbody><tr><td>".concat(journalEntry.get('je_number'), "</td>");
        table += "<td>".concat(moment(journalEntry.get('date')).format("MM/DD/YYYY"), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(journalEntry.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    generateCSV: function generateCSV() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align: center'>Journal Entries</h3><table id='journalEntriesTable' style='width:100%; font-size: 14px'><thead><tr style='text-align: left;'><th>NUMBER</th>");
      table += "<th style='text-align: left;'>DATE</th>";
      table += "<th style='text-align: left;'>NEW TRANSACTION</th>";
      table += "<th style='text-align: left;'>ACCOUNT</th>";
      table += "<th style='text-align: left;'>CUSTOMER</th>";
      table += "<th style='text-align: left;'>VENDOR</th>";
      table += "<th style='text-align: left;'>DESCRIPTION</th>";
      table += "<th style='text-align: right;'>DEBIT</th>";
      table += "<th style='text-align: right;'>CREDIT</th></tr></thead>";
      this.get('filteredJournalEntries').forEach(function (journalEntry) {
        table += "<tbody><tr><td>".concat(journalEntry.get('je_number'), "</td>");
        table += "<td>".concat(moment(journalEntry.get('date')).format("MM/DD/YYYY"), "</td>");
        table += "<td>YES</td>";
        table += "<td></td>";
        table += "<td></td>";
        table += "<td></td>";
        table += "<td></td>";
        table += "<td style='text-align: right;'>".concat(parseFloat(journalEntry.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td>");
        table += "<td style='text-align: right;'>".concat(parseFloat(journalEntry.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
        journalEntry.get('journal_entry_accounts').forEach(function (jeAccount) {
          table += "<td></td>";
          table += "<td></td>";
          table += "<td></td>";
          table += "<td>".concat(jeAccount.account_name, "</td>");
          table += "<td>".concat(jeAccount.customer_name || "", "</td>");
          table += "<td>".concat(jeAccount.vendor_name || "", "</td>");
          table += "<td>".concat(jeAccount.memo || "", "</td>");
          table += "<td style='text-align: right;'>".concat(jeAccount.account_action === "debit" ? parseFloat(jeAccount.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td>");
          table += "<td style='text-align: right;'>".concat(jeAccount.account_action === "credit" ? parseFloat(jeAccount.amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "", "</td></tr>");
        });
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);

        if (value !== 'date') {
          this.setProperties({
            fromDateFilter: null,
            toDateFilter: null
          });
        }

        this.set('dateFilter', value === 'date');
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      duplicateJournalEntry: function duplicateJournalEntry() {
        var _this4 = this;

        var journalEntry = this.get('journalEntry');
        journalEntry.set('duplicate', true);

        var _this = this;

        var data = JSON.stringify({
          id: journalEntry.id,
          journalEntry: journalEntry
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/journal_entries/"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            _this4.send('refreshModel');

            _this.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Journal Entry Duplicated</span></div>'
            });

            journalEntry.set('duplicate', false);

            _this.set('confirmDuplicate', false);
          },
          errorCallback: function errorCallback(response) {
            journalEntry.set('duplicate', false);

            _this.set('confirmDuplicate', false);

            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            }

            _this.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(response.payload.errors || errors, "</span></div>")
            });

            journalEntry.rollbackAttributes();
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('journal-entry', id, {
            backgroundReload: false
          }).then(function (journalEntry) {
            self.set('journalEntry', journalEntry);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      toggleConfirmDuplicate: function toggleConfirmDuplicate(id) {
        if (this.get('isGold')) {
          if (id) {
            this.set('journalEntry', this.store.peekRecord('journal-entry', id));
          }

          this.toggleProperty('confirmDuplicate');
        }
      },
      deleteJournalEntry: function deleteJournalEntry() {
        var _this5 = this;

        this.journalEntry.destroyRecord().then(function () {
          _this5.set('filteredJournalEntries', _this5.store.peekAll('journal-entry').filter(function (journalEntry) {
            return !journalEntry.get('isDeleted');
          }));

          _this5.setProperties({
            pagedJournalEntries: (0, _pagedArray.default)('filteredJournalEntries', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            journalEntry: {},
            confirmDelete: false
          });

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Journal Entry Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this5.set('confirmDelete', false);

          _this5.journalEntry.rollbackAttributes();

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedJournalEntries').loadNextPage();
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); //   let w = window.open();
        //   w.document.write("<div id='print-container'></div>");
        //   w.document.getElementById('print-container').innerHTML += table;
        //  w.print();
        //  w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateCSV();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#journalEntriesTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_journal_entries_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#journalEntriesTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredJournalEntries', this.get('filteredJournalEntries').sortBy(property));
        } else {
          this.set('filteredJournalEntries', this.get('filteredJournalEntries').sortBy(property).reverse());
        }

        this.set('pagedJournalEntries', (0, _pagedArray.default)('filteredJournalEntries', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      }
    }
  });

  _exports.default = _default;
});