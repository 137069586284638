define("account-berry-frontend/companies/show/users/controller", ["exports", "jquery", "account-berry-frontend/config/environment", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _environment, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment*/
  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service('ajax'),
    confirmDelete: false,
    sortAscending: false,
    editRole: false,
    filter: "user_name",
    filters: [{
      name: "NAME",
      value: "user_name"
    }, {
      name: "EMAIL",
      value: "email"
    }, {
      name: "PHONE NUMBER",
      value: "phone"
    }],
    pagedUsers: (0, _pagedArray.default)('filteredUsers', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    totalUsers: Ember.computed('filteredUsers', function () {
      return this.get('filteredUsers.length');
    }),
    modelObserver: Ember.observer('model', function () {
      try {
        this.set('pagedUsers', (0, _pagedArray.default)('filteredUsers', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {}
    }),
    roles: Ember.computed(function () {
      return this.get('session.data.authenticated.roles');
    }),
    filteredUsers: Ember.computed('model', function () {
      var filteredUsers = this.model.toArray();
      var company = this.store.peekRecord('company', this.companyId);
      company.get('invites').forEach(function (invite) {
        filteredUsers.push(invite);
      }); // filteredUsers.push(company.)

      return filteredUsers;
    }),
    filterUsers: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      var filter = this.filter;

      if (search === "" || search.trim().length === 0) {
        this.set('filteredUsers', this.model);
      } else {
        this.set('filteredUsers', this.model.filter(function (user) {
          var userValue = user.get(filter);

          if (userValue) {
            if (isNaN(userValue)) {
              userValue = userValue.toLowerCase();
            }

            if (userValue.includes(search)) {
              return user;
            }
          }
        }));
      }

      this.set('pagedUsers', (0, _pagedArray.default)('filteredUsers', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Users</h3><table id='usersTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>NAME</th>");
      table += "<th style='text-align: left;'>EMAIL</th>";
      table += "<th style='text-align: left;'>PHONE NUMBER</th>";
      table += "<th style='text-align: left;'>ROLE</th></tr></thead>";
      table += "<tbody>";
      this.get('filteredUsers').forEach(function (userRole) {
        var isInstance = Ember.typeOf(userRole) === "instance";
        var userName = isInstance ? userRole.get('user_name') : userRole.user_name || "";
        var email = isInstance ? userRole.get('user.email') : userRole.email || "";
        var phone = isInstance ? userRole.get('user.phone') || "" : userRole.phone || "";
        var roleName = isInstance ? userRole.get('role_name') : userRole.role_name || "PENDING";
        var data = [userName, email, phone, roleName];
        table += "<tr>";
        data.forEach(function (attr) {
          table += "<td>".concat(attr, "</td>");
        });
        table += "</tr>";
      });
      table += "</tbody></table></div>";
      return table;
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermission: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleEditRole: function toggleEditRole(userRole) {
        if (userRole.editRole) {
          userRole.toggleProperty('editRole');
        } else {
          userRole.set('editRole', true);
        }
      },
      updateRoleValue: function updateRoleValue(user, index) {
        var _this2 = this;

        var value = (0, _jquery.default)("#updateRoleSelect-".concat(index))[0].value;
        var data = {
          user_role: {
            role_id: value
          }
        };

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/user_roles/").concat(user.get('id')), {
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify(data),
          headers: headers
        }).then(function (response) {
          _this2.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Role Updated</span></div>'
          });

          user.set('role_name', response.user_role.role_name);
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this2.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      resendInvite: function resendInvite(id) {
        var _this3 = this;

        var _this$get2 = this.get('session.data.authenticated'),
            email = _this$get2.email,
            token = _this$get2.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/invites/").concat(id, "/resend"), {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            company_id: this.get('companyId')
          }),
          headers: headers
        }).then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Invite re-sent</span></div>'
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      deleteInvite: function deleteInvite(id) {
        var _this4 = this;

        var _this$get3 = this.get('session.data.authenticated'),
            email = _this$get3.email,
            token = _this$get3.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        this.get('ajax').request("".concat(_environment.default.apiURL, "/invites/").concat(id), {
          method: 'DELETE',
          contentType: 'application/json',
          data: JSON.stringify({
            company_id: this.get('companyId')
          }),
          headers: headers
        }).then(function () {
          _this4.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Invite Deleted</span></div>'
          });

          _this4.store.findRecord('company', _this4.get('companyId')).then(function () {
            _this4.send('refreshModel');

            ;
          });

          _this4.set('pagedUsers', (0, _pagedArray.default)('filteredUsers', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this4.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var _this = this;

          this.store.findRecord('user-role', id, {
            backgroundReload: false
          }).then(function (user) {
            _this.set('user', user);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      removeUser: function removeUser() {
        this.user.destroyRecord();
        this.get('filteredUsers').splice(this.get('filteredUsers').indexOf(this.user), 1);
        this.set('filteredUsers', this.get('filteredUsers'));
        this.setProperties({
          pagedUsers: (0, _pagedArray.default)('filteredUsers', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }),
          user: {},
          confirmDelete: false
        });
        this.get('notify').alert({
          html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>User Removed</span></div>"
        });
      },
      loadNext: function loadNext() {
        this.get('pagedItems').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredUsers', this.get('filteredUsers').sortBy(property));
        } else {
          this.set('filteredUsers', this.get('filteredUsers').sortBy(property).reverse());
        }

        this.set('pagedUsers', (0, _pagedArray.default)('filteredUsers', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#usersTable').table2CSV({
          // delivery: 'value'
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_users_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#usersTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});