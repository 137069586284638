define("account-berry-frontend/companies/show/reports/vacation-sickness/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    init: function init() {
      var _this2 = this;

      _jquery.default.initialize('.vacation-sickness-table', function () {
        _this2.setTable();
      });
    },
    setTable: function setTable() {
      var printableTable = (0, _jquery.default)('.vacation-sickness-report table').clone();
      printableTable.css('font-size', '1vw');
      this.set('printableTable', printableTable);
      (0, _jquery.default)('.vacation-sickness-table').tableHeadFixer({
        // fix table header
        head: true,
        // fix table footer
        foot: false,
        // fix x left columns
        left: 0,
        // fix x right columns
        right: 0,
        // z-index
        'z-index': 0
      });
    },
    filterVacationSicknessData: Ember.observer('search', function () {
      var search = this.search;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredVacationSicknessData', this.get('vacationSicknessData.data'));
      } else {
        this.set('filteredVacationSicknessData', this.get('vacationSicknessData.data').filter(function (data) {
          var employeeValue = data.employee_name;

          if (employeeValue) {
            if (isNaN(employeeValue)) {
              employeeValue = employeeValue.toLowerCase();
            }

            if (employeeValue.includes(search)) {
              return data;
            }
          }
        }));
      }
    }),
    actions: {
      getVacationSicknessData: function getVacationSicknessData() {
        var _this3 = this;

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'vacation_sickness'
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/reports"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback() {
            _this3.get('applicationController').set('showLoading', true);
          },
          errorCallback: function errorCallback() {
            _this3.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
            });
          }
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "VacationSickness" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-VacationSickness');

            _this.get('ajaxCall').request(ajaxOptions);
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('vacationSicknessData', data.report);

              _this3.set('filteredVacationSicknessData', data.report.data);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-VacationSickness disconnected");
          }
        });
      },
      print: function print() {
        this.printableTable.printThis({
          pageTitle: "".concat(this.get('company.name'), " - Vacation Sickness Report"),
          header: "<h4 style='text-align: center;'>From ".concat(moment(this.get('fromDate')).format('MMMM D, YYYY'), " To ").concat(moment(this.get('toDate')).format('MMMM D, YYYY'), "</h4>")
        });
      },
      csvExport: function csvExport() {
        (0, _jquery.default)('.vacation-sickness-table').table2CSV({
          delivery: 'download',
          filename: 'vacation_sickness_report.csv'
        });
      } // print() {
      //   Ember.$('#uncollectedBills').printThis();
      // },
      // csvExport() {
      //   let table = "<table id='uncollectedBillsTable' style='width:100%; font-size: 14px'><thead>";
      //   table += "<tr><th></th><th>TYPE</th><th>DATE</th><th>NUMBER</th>";
      //   table +="<th>DUE DATE</th><th>AGING</th><th>OPEN BALANCE</th></thead>";
      //   table += "<tbody>";
      //   this.get('vacationSicknessData.customers').forEach((customer) => {
      //     table += `<tr><td>${customer.name}</td><td></td><td></td><td></td><td></td><td></td></tr>`;
      //     customer.transactions.forEach((transaction) => {
      //       table += `<tr><td>${transaction.type}</td>`;
      //       table += `<td>${transaction.date}</td>`;
      //       table += `<td>${transaction.number}</td>`;
      //       table += `<td>${transaction.due_date ? transaction.due_date : ""}</td>`;
      //       table += `<td>${transaction.aging ? transaction.aging : ""}</td>`;
      //       table += `<td>${parseFloat(transaction.open_balance).toFixed(2)}</td></tr>`;
      //     });
      //     table += `<tr><td>Total ${customer.name}</td><td></td><td></td><td></td><td></td>`;
      //     table += `<td>${parseFloat(customer.total).toFixed(2)}</td></tr>`;
      //   });
      //   table += "<tr><td>Total</td><td></td><td></td><td></td><td></td>";
      //   table += `<td>${parseFloat(this.get('vacationSicknessData.total')).toFixed(2)}</td></tr>`;
      //   table += "</tbody></table>";
      //   Ember.$('#csvExport').show();
      //   document.getElementById('csvExport').innerHTML += table;
      //   Ember.$('#uncollectedBillsTable').table2CSV({
      //     delivery: 'download',
      //     filename: 'UncollectedBillsTable'
      //   });
      //   // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;
      //   Ember.$('#uncollectedBillsTable').remove();
      //   Ember.$('#csvExport').hide();
      // }

    }
  });

  _exports.default = _default;
});