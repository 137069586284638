define("account-berry-frontend/components/paginated-transactions/component", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["general-ledger-transactions-container"],
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)("#".concat(this.elementId)).scroll(function () {
        if ((0, _jquery.default)(this).scrollTop() + (0, _jquery.default)(this).innerHeight() >= (0, _jquery.default)(this)[0].scrollHeight - 1) {
          _this.send('loadNext');
        }
      });
    },
    dataObserver: Ember.observer('data', function () {
      this.set('paginatedData', (0, _pagedArray.default)('data', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    paginatedData: (0, _pagedArray.default)('data', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    actions: {
      loadNext: function loadNext() {
        this.get('paginatedData').loadNextPage();
      }
    }
  });

  _exports.default = _default;
});