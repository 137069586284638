define("account-berry-frontend/companies/show/vendors/bills/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    newBill: false,
    billForm: false,
    confirmDelete: false,
    search: "",
    sortAscending: false,
    pagedBills: (0, _pagedArray.default)('filteredBills', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredBills', this.store.peekAll('bill').filter(function (bill) {
          return !bill.get('isDeleted');
        }));
        this.set('pagedBills', (0, _pagedArray.default)('filteredBills', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    totalBills: Ember.computed('filteredBills', function () {
      return this.get('filteredBills.length');
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this = this;

      var channel = this.get('session.pusher').subscribe("bill-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          _this.updateBillList(data.bill);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var billId = data.bill.id;
          delete data.bill.id;

          var bill = _this.store.peekRecord('bill', billId);

          bill.setProperties(data.bill);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this.get('session.data.authenticated.id').toString()) {
          var bill = _this.store.peekRecord('bill', data.bill_id);

          bill.deleteRecord();

          _this.set('filteredBills', _this.store.peekAll('bill').filter(function (bill) {
            return !bill.get('isDeleted');
          }));

          _this.set('pagedBills', (0, _pagedArray.default)('filteredBills', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateBillList: function updateBillList(bill) {
      this.store.push({
        data: {
          id: bill.id,
          type: 'bill',
          attributes: bill
        }
      });
      this.set('filteredBills', this.store.peekAll('bill').filter(function (bill) {
        return !bill.get('isDeleted');
      }));
      this.set('pagedBills', (0, _pagedArray.default)('filteredBills', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    filter: 'ref_number',
    filters: [{
      name: "NUMBER",
      value: "ref_number"
    }, {
      name: "BILL DATE",
      value: "bill_date"
    }, {
      name: "BILL DUE DATE",
      value: "bill_due_date"
    }, {
      name: "AMOUNT",
      value: "amount"
    }, {
      name: "VENDOR",
      value: "vendor"
    }],
    filteredBills: Ember.computed('model', function () {
      return this.model;
    }),
    filterBills: Ember.observer('search', 'filterLabelIds', function () {
      var _this2 = this;

      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredBills', this.model);
      } else {
        this.set('filteredBills', this.model.filter(function (bill) {
          var billValue = bill.get(filter);

          if (billValue) {
            if (isNaN(billValue)) {
              billValue = billValue.toLowerCase();
            }

            if (billValue.toString().includes(search)) {
              return bill;
            }
          }
        }));
      }

      if (this.get('filterLabelIds') && this.get('filterLabelIds').length > 0) {
        var hasLabel;
        this.set('filteredBills', this.get('filteredBills').filter(function (po) {
          hasLabel = false;

          var _iterator = _createForOfIteratorHelper(_this2.get('filterLabelIds')),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var label = _step.value;
              hasLabel = po.get('labels').find(function (l) {
                return l.id.toString() === label.toString();
              });

              if (!hasLabel) {
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (hasLabel) {
            return po;
          }
        }));
      }

      this.set('pagedBills', (0, _pagedArray.default)('filteredBills', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['vendors'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana;'><h3 style='text-align: center;'>".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "</h3><h3 style='text-align: center'>Bills & Credits</h3><table id='billsTable' style='width:100%; font-size: 14px'><thead><tr>");
      table += "<th style='text-align: left;'>NUMBER</th>";
      table += "<th style='text-align: left;'>BILL DATE</th>";
      table += "<th style='text-align: left;'>BILL DUE DATE</th>";
      table += "<th style='text-align: left;'>VENDOR</th>";
      table += "<th style='text-align: right'>AMOUNT</th></tr></thead><tbody>";
      this.get('filteredBills').forEach(function (bill) {
        table += "<td>".concat(bill.get('ref_number') || "", "</td>");
        table += "<td>".concat(moment(bill.get('bill_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(moment(bill.get('bill_due_date')).format('MM/DD/YYYY'), "</td>");
        table += "<td>".concat(bill.get('vendor'), "</td>");
        table += "<td style='text-align: right'>".concat(parseFloat(bill.get('amount')).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "</td></tr>");
      });
      table += "</tbody></table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleNewBill: function toggleNewBill() {
        this.set('bill', {});
        this.toggleProperty('newBill');
        this.toggleProperty('billForm');
      },
      toggleEditBill: function toggleEditBill(bill) {
        this.set('bill', bill);
        this.toggleProperty('billForm');
      },
      cancelEdit: function cancelEdit() {
        this.bill.rollbackAttributes();
        this.toggleProperty('billForm');
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var self = this;
          this.store.findRecord('bill', id, {
            backgroundReload: false
          }).then(function (bill) {
            self.set('bill', bill);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      create: function create() {
        var _this3 = this;

        this.set('bill.company_id', this.companyId);
        var newBill = this.store.createRecord('bill', this.bill);
        newBill.save().then(function () {
          _this3.get('notify').success({
            html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Bill Created</span></div>'
          });

          _this3.send('toggleNewBill');

          _this3.model.pushObject(newBill);
        }, function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          newBill.rollbackAttributes();

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      update: function update() {
        var _this4 = this;

        this.store.findRecord('bill', this.bill.id, {
          backgroundReload: false
        }).then(function (bill) {
          bill.setProperties(_this4.bill);
          bill.save().then(function () {
            _this4.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>Bill Updated</span></div>'
            });

            _this4.send('toggleEditBill');
          }, function (response) {
            var errors = "An Unkown Error Occured.";

            if (response.errors && response.errors[0].status !== "500") {
              errors = response.errors.map(function (error) {
                return error;
              });
            } else if (response.payload.errors) {
              errors = response.payload.errors.map(function (error) {
                return error;
              });
            }

            _this4.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
            });
          });
        });
      },
      deleteBill: function deleteBill() {
        var _this5 = this;

        this.bill.destroyRecord().then(function () {
          _this5.set('filteredBills', _this5.store.peekAll('bill').filter(function (bill) {
            return !bill.get('isDeleted');
          }));

          _this5.setProperties({
            pagedBills: (0, _pagedArray.default)('filteredBills', {
              infinite: "unpaged",
              page: 1,
              perPage: 100
            }),
            bill: {},
            confirmDelete: false
          });

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Bill Deleted</span></div>"
          });
        }).catch(function (response) {
          var errors = "An Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          }

          _this5.bill.rollbackAttributes();

          _this5.set('confirmDelete', false);

          _this5.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      setFilterLabels: function setFilterLabels(labels) {
        this.set('filterLabelIds', labels);
      },
      loadNext: function loadNext() {
        this.get('pagedBills').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredBills', this.get('filteredBills').sortBy(property));
        } else {
          this.set('filteredBills', this.get('filteredBills').sortBy(property).reverse());
        }

        this.set('pagedBills', (0, _pagedArray.default)('filteredBills', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#billsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_bills_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#billsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});