define("account-berry-frontend/components/label-tag/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    didInsertElement: function didInsertElement() {
      this._super();

      (0, _jquery.default)(".label-tag.".concat(this.get('label.id'))).css({
        "background-color": this.get('label.color'),
        "color": this.get('label.text_color')
      });
    },
    tagUpdater: Ember.observer('label.color', 'label.text_color', function () {
      if (this.get('label.id')) {
        (0, _jquery.default)(".label-tag.".concat(this.get('label.id'))).css({
          "background-color": this.get('label.color'),
          "color": this.get('label.text_color')
        });
      } else {
        (0, _jquery.default)(".label-tag.preview").css({
          "background-color": this.get('label.color'),
          "color": this.get('label.text_color')
        });
      }
    })
  });

  _exports.default = _default;
});