define("account-berry-frontend/plaid-link-transaction/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    authorized_date: _emberData.default.attr('string'),
    category_id: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    specific_category: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    date: _emberData.default.attr('string'),
    imported: _emberData.default.attr('boolean'),
    merchant_name: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    pending: _emberData.default.attr('boolean'),
    plaid_link_id: _emberData.default.attr('string'),
    transaction_id: _emberData.default.attr('string'),
    transaction_type: _emberData.default.attr('string'),
    subscription_active: _emberData.default.attr('boolean'),
    account_id: _emberData.default.attr(),
    payee_id: _emberData.default.attr(),
    payee_type: _emberData.default.attr()
  });

  _exports.default = _default;
});