define("account-berry-frontend/utils/tax-group-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = taxcodes;

  function taxcodes() {
    return [{
      code: "100",
      name: "Cash On Hand",
      category: "Assets"
    }, {
      code: "101",
      name: "Cash In Banks",
      category: "Assets"
    }, {
      code: "102",
      name: "Accounts Receivable",
      category: "Assets"
    }, {
      code: "103",
      name: "Reserve for Bad debts",
      category: "Assets"
    }, {
      code: "104",
      name: "Notes Receivable",
      category: "Assets"
    }, {
      code: "105",
      name: "Manuf. Invent. - Raw Materials",
      category: "Assets"
    }, {
      code: "106",
      name: "Manuf. Invent. - In Process",
      category: "Assets"
    }, {
      code: "107",
      name: "Manuf. Invent. - Finished Goods",
      category: "Assets"
    }, {
      code: "108",
      name: "Manuf. Invent. - Materials & Supplies",
      category: "Assets"
    }, {
      code: "109",
      name: "Merch. Invent. - For Sale",
      category: "Assets"
    }, {
      code: "110",
      name: "Merch. Invent. - On Consignment to Others",
      category: "Assets"
    }, {
      code: "111",
      name: "Merch. Invent. - New Cars For Sale",
      category: "Assets"
    }, {
      code: "112",
      name: "Merch. Invent. - Materials and Supplies",
      category: "Assets"
    }, {
      code: "113",
      name: "Merch. Invent. - Others",
      category: "Assets"
    }, {
      code: "114",
      name: "Invest. - Stocks and Bonds In Foreign Corps",
      category: "Assets"
    }, {
      code: "115",
      name: "Invest. - Bonds of Foreign Governments",
      category: "Assets"
    }, {
      code: "116",
      name: "Invest. - Stock of Domestic Corporations",
      category: "Assets"
    }, {
      code: "117",
      name: "Invest. - US Gov. And PR Public Corp. & Gov. Bonds",
      category: "Assets"
    }, {
      code: "118",
      name: "Deferred Charges - Prepaid Insurance",
      category: "Assets"
    }, {
      code: "119",
      name: "Deferred Charges - Prepaid Taxes",
      category: "Assets"
    }, {
      code: "120",
      name: "Deferred Charges - Prepaid Interests",
      category: "Assets"
    }, {
      code: "121",
      name: "Deferred Charges - Other Deferred And Prepaid Exp.",
      category: "Assets"
    }, {
      code: "122",
      name: "Office Materials, Supplies, advertisements",
      category: "Assets"
    }, {
      code: "123",
      name: "Trade Marks, Franchise",
      category: "Assets"
    }, {
      code: "124",
      name: "Goodwill",
      category: "Assets"
    }, {
      code: "125",
      name: "Redemption Value of Insurance Policies",
      category: "Assets"
    }, {
      code: "126",
      name: "Other Miscellaneous Assets",
      category: "Assets"
    }, {
      code: "127",
      name: "Land",
      category: "Assets"
    }, {
      code: "128",
      name: "Buildings",
      category: "Assets"
    }, {
      code: "129",
      name: "Accum. Dep. - Buildings",
      category: "Assets"
    }, {
      code: "130",
      name: "Construction in Process - Real",
      category: "Assets"
    }, {
      code: "131",
      name: "Construction in Process - Personal",
      category: "Assets"
    }, {
      code: "132",
      name: "Furniture And Fixtures",
      category: "Assets"
    }, {
      code: "133",
      name: "Accum. Dep. - Furniture And Fixtures",
      category: "Assets"
    }, {
      code: "134",
      name: "M&E - Real",
      category: "Assets"
    }, {
      code: "135",
      name: "Accum. Dep. - M&E - Real",
      category: "Assets"
    }, {
      code: "136",
      name: "Movable M&E",
      category: "Assets"
    }, {
      code: "137",
      name: "Accum. Dep. - Movable M&E",
      category: "Assets"
    }, {
      code: "138",
      name: "M&E - Leased to Others",
      category: "Assets"
    }, {
      code: "139",
      name: "Accum. Dep. - M&E - Leased to Others",
      category: "Assets"
    }, {
      code: "140",
      name: "Motor Vehicles with Licenses",
      category: "Assets"
    }, {
      code: "141",
      name: "Accum. Dep. - Motor Vehicles with Licenses",
      category: "Assets"
    }, {
      code: "142",
      name: "Motor Vehicles Not Licensed",
      category: "Assets"
    }, {
      code: "143",
      name: "Accum. Dep. - Motor Vehicles Not Licensed",
      category: "Assets"
    }, {
      code: "144",
      name: "Boats",
      category: "Assets"
    }, {
      code: "145",
      name: "Accum. Dep. - Boats",
      category: "Assets"
    }, {
      code: "146",
      name: "Horses and Others",
      category: "Assets"
    }, {
      code: "147",
      name: "Containers",
      category: "Assets"
    }, {
      code: "148",
      name: "Wood and Metal Boxes",
      category: "Assets"
    }, {
      code: "149",
      name: "Tools and Implements - Net",
      category: "Assets"
    }, {
      code: "150",
      name: "Bovine Cattle",
      category: "Assets"
    }, {
      code: "151",
      name: "Other Current Assets",
      category: "Assets"
    }, {
      code: "152",
      name: "Loans Receivable Shareholders or Related Entities",
      category: "Assets"
    }, {
      code: "200",
      name: "Accounts Payable",
      category: "Liabilities"
    }, {
      code: "201",
      name: "Notes Payable",
      category: "Liabilities"
    }, {
      code: "202",
      name: "Bonds & Mortgages Payables",
      category: "Liabilities"
    }, {
      code: "203",
      name: "Other Liabilities",
      category: "Liabilities"
    }, {
      code: "204",
      name: "Accrued Expenses - Income & Property taxes",
      category: "Liabilities"
    }, {
      code: "205",
      name: "Accrued Expenses - Payroll Taxes",
      category: "Liabilities"
    }, {
      code: "206",
      name: "Accrued Expenses - Interest",
      category: "Liabilities"
    }, {
      code: "207",
      name: "Accrued Expenses - Other",
      category: "Liabilities"
    }, {
      code: "208",
      name: "Allowances & Other Deferred Credits",
      category: "Liabilities"
    }, {
      code: "209",
      name: "Capital Stock - Preferred",
      category: "Equity"
    }, {
      code: "210",
      name: "Capital Stock - Common",
      category: "Equity"
    }, {
      code: "211",
      name: "Additional Paid-In Capital",
      category: "Equity"
    }, {
      code: "212",
      name: "Retained Earnings - Earned Surplus",
      category: "Equity"
    }, {
      code: "213",
      name: "Retained Earnings - Surplus Reserves",
      category: "Equity"
    }, {
      code: "214",
      name: "Other Current Liabilities",
      category: "Liabilities"
    }, {
      code: "215",
      name: "Accounts Payable to Shareholders or Related Entities",
      category: "Liabilities"
    }, {
      code: "216",
      name: "Membership Certificates",
      category: "Equity"
    }, {
      code: "217",
      name: "Earned Surplus and Undivided Profits",
      category: "Equity"
    }, {
      code: "300",
      name: "Net Sales",
      category: "Income"
    }, {
      code: "301",
      name: "Manufacturing Income",
      category: "Income"
    }, {
      code: "400",
      name: "Purchase of Materials",
      category: "Costs of goods sold"
    }, {
      code: "401",
      name: "Direct Wages",
      category: "Costs of goods sold"
    }, {
      code: "402",
      name: "OD-Salaries, wages",
      category: "Costs of goods sold"
    }, {
      code: "403",
      name: "OD-Social Security",
      category: "Costs of goods sold"
    }, {
      code: "404",
      name: "OD-Unemployment Taxes",
      category: "Costs of goods sold"
    }, {
      code: "405",
      name: "OD-State Insurance Fund Taxes",
      category: "Costs of goods sold"
    }, {
      code: "406",
      name: "OD-Medical and/or hospitalization insurance",
      category: "Costs of goods sold"
    }, {
      code: "407",
      name: "OD-Other Insurance",
      category: "Costs of goods sold"
    }, {
      code: "408",
      name: "OD-Excise Taxes",
      category: "Costs of goods sold"
    }, {
      code: "409",
      name: "OD-Repairs",
      category: "Costs of goods sold"
    }, {
      code: "410",
      name: "OD-Utilities",
      category: "Costs of goods sold"
    }, {
      code: "411",
      name: "OD-Flexible Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "412",
      name: "OD-Curent Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "413",
      name: "OD-Other Expense",
      category: "Costs of goods sold"
    }, {
      code: "414",
      name: "OD-Accelerated Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "415",
      name: "OD-Cost Sharing Allocation",
      category: "Costs of goods sold"
    }, {
      code: "416",
      name: "OD-Rent",
      category: "Costs of goods sold"
    }, {
      code: "417",
      name: "OD-Amortization Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "418",
      name: "OD-Automobiles Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "419",
      name: "OD-Vehicles Under Financial Lease Depreciation",
      category: "Costs of goods sold"
    }, {
      code: "420",
      name: "OD-Sales and use tax on imports",
      category: "Costs of goods sold"
    }, {
      code: "421",
      name: "OD-Cleaning, maintenance and picked up waste",
      category: "Costs of goods sold"
    }, {
      code: "422",
      name: "OD-Expenditure on product packaging",
      category: "Costs of goods sold"
    }, {
      code: "423",
      name: "OD-Food expenses paid to production employees",
      category: "Costs of goods sold"
    }, {
      code: "424",
      name: "OD-Electrical Energy",
      category: "Costs of goods sold"
    }, {
      code: "425",
      name: "OD-Water and Sewerage",
      category: "Costs of goods sold"
    }, {
      code: "426",
      name: "OD-Computer Systems",
      category: "Costs of goods sold"
    }, {
      code: "427",
      name: "OD-Ground Transportation Equipment, Except Vehicles",
      category: "Costs of goods sold"
    }, {
      code: "428",
      name: "OD-Machinery and Equipment, Furniture and Fixtures and Any Other Fixed Assets to be Used in Industry or Business",
      category: "Costs of goods sold"
    }, {
      code: "500",
      name: "Capital gain or losses - Sch D (Reg Corp Only)",
      category: "Income"
    }, {
      code: "501",
      name: "Gain (Loss) from property (Other than capital assets) - Sch D",
      category: "Income"
    }, {
      code: "502",
      name: "Rent",
      category: "Income"
    }, {
      code: "503",
      name: "Interest - Others",
      category: "Income"
    }, {
      code: "504",
      name: "Commissions",
      category: "Income"
    }, {
      code: "505",
      name: "Dividends from Corps and Dist. from Partnerships - Subject to 100% deduction (Totally Exempt)",
      category: "Income"
    }, {
      code: "506",
      name: "Profit  (losses) from Special Partnerships",
      category: "Income"
    }, {
      code: "507",
      name: "Freight and Passenger Fares",
      category: "Income"
    }, {
      code: "508",
      name: "Royalties and Annuities",
      category: "Income"
    }, {
      code: "509",
      name: "Miscellaneous Income",
      category: "Income"
    }, {
      code: "510",
      name: "Taxable Benefit from Farming",
      category: "Income"
    }, {
      code: "511",
      name: "Designated Service Industry Income - Provided Directly by the Corporation",
      category: "Income"
    }, {
      code: "512",
      name: "Casinos Income",
      category: "Income"
    }, {
      code: "513",
      name: "Exempt Amount Under Act 135-2014",
      category: "Income"
    }, {
      code: "514",
      name: "Distributable share on net income subject to preferential rates from partnerships and special partnerships",
      category: "Income"
    }, {
      code: "515",
      name: "Net income from operations of an international financial institution that operates as a unit of a bank",
      category: "Income"
    }, {
      code: "516",
      name: "Debts Discharge",
      category: "Income"
    }, {
      code: "517",
      name: "Public Shows",
      category: "Income"
    }, {
      code: "518",
      name: "Other Payments Reported on Form 480.6A or 480.6B",
      category: "Income"
    }, {
      code: "519",
      name: "Gain (Loss) short term capital assets - Sch D",
      category: "Income"
    }, {
      code: "520",
      name: "Gain (Loss) long term capital assets - Sch D",
      category: "Income"
    }, {
      code: "521",
      name: "Gain (Loss) on sale or exchange of assets (Act 78-1993) - Sch D (Pass-Thru Only)",
      category: "Income"
    }, {
      code: "522",
      name: "Gains (or Loss) from property (Taxable as long-term capital assets) - Sch D (Pass-Thru Only)",
      category: "Income"
    }, {
      code: "523",
      name: "Interest - Subject to the preferential rate of 10%",
      category: "Income"
    }, {
      code: "524",
      name: "Dividends from Corps and Dist. from Partnerships - Domestic - Subject to deduction of 85%",
      category: "Income"
    }, {
      code: "525",
      name: "Dividends from Corps and Dist. from Partnerships - Domestic - Subject to the deduction of 77.5% - Law 57 of 1963",
      category: "Income"
    }, {
      code: "526",
      name: "Dividends from Corps and Dist. from Partnerships - Domestic - Foreign",
      category: "Income"
    }, {
      code: "527",
      name: "Designated Service Industry Income - Accrued through Special Partnerships and Partnerships",
      category: "Income"
    }, {
      code: "600",
      name: "Compensation to officers",
      category: "Expenses"
    }, {
      code: "601",
      name: "Salaries",
      category: "Expenses"
    }, {
      code: "602",
      name: "Commissions to Other Businesses",
      category: "Expenses"
    }, {
      code: "603",
      name: "Social Security",
      category: "Expenses"
    }, {
      code: "604",
      name: "Unemployment Taxes",
      category: "Expenses"
    }, {
      code: "605",
      name: "State Insurance Fund Taxes",
      category: "Expenses"
    }, {
      code: "606",
      name: "Medical and/or hospitalization insurance",
      category: "Expenses"
    }, {
      code: "607",
      name: "Insurance Expenses",
      category: "Expenses"
    }, {
      code: "608",
      name: "Interest",
      category: "Expenses"
    }, {
      code: "609",
      name: "Rent of tangible personal property",
      category: "Expenses"
    }, {
      code: "610",
      name: "Property Tax - Personal",
      category: "Expenses"
    }, {
      code: "611",
      name: "Other Taxes - Others",
      category: "Expenses"
    }, {
      code: "612",
      name: "Losses from fire, storm",
      category: "Expenses"
    }, {
      code: "613",
      name: "Motor vehicle expenses",
      category: "Expenses"
    }, {
      code: "614",
      name: "Meals & Entertainment",
      category: "Expenses"
    }, {
      code: "615",
      name: "Travel Expenses",
      category: "Expenses"
    }, {
      code: "616",
      name: "Professional Fees",
      category: "Expenses"
    }, {
      code: "617",
      name: "Pension, profit sharing, expenses",
      category: "Expenses"
    }, {
      code: "618",
      name: "Flexible depreciation",
      category: "Expenses"
    }, {
      code: "619",
      name: "Current Depreciation",
      category: "Expenses"
    }, {
      code: "620",
      name: "Bad Debts",
      category: "Expenses"
    }, {
      code: "621",
      name: "Charitable Contribution",
      category: "Expenses"
    }, {
      code: "622",
      name: "Repairs/Organization and Sindication",
      category: "Expenses"
    }, {
      code: "623",
      name: "Deduction  to employers who hire handicapped",
      category: "Expenses"
    }, {
      code: "624",
      name: "Accelerated Depreciation",
      category: "Expenses"
    }, {
      code: "625",
      name: "Contributions to Deffered Income",
      category: "Expenses"
    }, {
      code: "626",
      name: "Utilities",
      category: "Expenses"
    }, {
      code: "627",
      name: "Advertising",
      category: "Expenses"
    }, {
      code: "628",
      name: "Materials and Supplies",
      category: "Expenses"
    }, {
      code: "629",
      name: "Maintenance",
      category: "Expenses"
    }, {
      code: "630",
      name: "Federal self employment tax",
      category: "Expenses"
    }, {
      code: "631",
      name: "Contributions to Educational Contribution Acct",
      category: "Expenses"
    }, {
      code: "632",
      name: "Amortization (i.e. Goodwill)",
      category: "Expenses"
    }, {
      code: "633",
      name: "Automobiles",
      category: "Expenses"
    }, {
      code: "635",
      name: "Vehicles Expenses - Mileage",
      category: "Expenses"
    }, {
      code: "636",
      name: "Vehicles under financial lease",
      category: "Expenses"
    }, {
      code: "640",
      name: "Royalties",
      category: "Expenses"
    }, {
      code: "641",
      name: "Management Fees",
      category: "Expenses"
    }, {
      code: "642",
      name: "Expenses in property leased to the Puerto Rico Industrial Development Company or Warehouse of the Puerto Rico",
      category: "Expenses"
    }, {
      code: "643",
      name: "Additional Tax on Gross Income Paid on or Before the Filing of the Return",
      category: "Expenses"
    }, {
      code: "644",
      name: "Compensation to Directors",
      category: "Expenses"
    }, {
      code: "645",
      name: "Interest Paid on Car Leasing",
      category: "Expenses"
    }, {
      code: "646",
      name: "Mortgage Interest",
      category: "Expenses"
    }, {
      code: "647",
      name: "Rent of real property",
      category: "Expenses"
    }, {
      code: "648",
      name: "Sales Tax and Use",
      category: "Expenses"
    }, {
      code: "649",
      name: "Incurred or Paid to Shareholders, Related Persons or Entities outside of Puerto Rico",
      category: "Expenses"
    }, {
      code: "650",
      name: "Deduction for Expenses Incurred or Paid to Shareholders, Individuals or Related Entities, Fully Deductible",
      category: "Expenses"
    }, {
      code: "651",
      name: "Bank Charges",
      category: "Expenses"
    }, {
      code: "652",
      name: "Seminars, Training and Continuing Education Expenses for Employees",
      category: "Expenses"
    }, {
      code: "653",
      name: "Security Services",
      category: "Expenses"
    }, {
      code: "654",
      name: "Bill Collection Services",
      category: "Expenses"
    }, {
      code: "655",
      name: "Outsourced Services",
      category: "Expenses"
    }, {
      code: "656",
      name: "Incurred or Paid for Services Received from Persons not Engaged in Trade or Business in Puerto Rico Expenses",
      category: "Expenses"
    }, {
      code: "657",
      name: "Expenses for Fees, Subscriptions and Memberships",
      category: "Expenses"
    }, {
      code: "658",
      name: "Related Software, and Licensing Expenses not Capitalized",
      category: "Expenses"
    }, {
      code: "659",
      name: "Deduction under Act 185-2014",
      category: "Expenses"
    }, {
      code: "660",
      name: "Expenses incurred or paid for qualified payment for disaster assistance to employees ",
      category: "Expenses"
    }, {
      code: "661",
      name: "Salaries Paid to University Students",
      category: "Expenses"
    }, {
      code: "662",
      name: "Internship Program of the Department of the Treasury",
      category: "Expenses"
    }, {
      code: "663",
      name: "Telecommunications Services",
      category: "Expenses"
    }, {
      code: "664",
      name: "Internet and Cable or Satellite Television Services",
      category: "Expenses"
    }, {
      code: "665",
      name: "Electrical Energy",
      category: "Expenses"
    }, {
      code: "666",
      name: "Special Contribution for Professional and Advisory Services under Act 48-2013",
      category: "Expenses"
    }, {
      code: "667",
      name: "Maintenance Fees Paid to Resident or Condominium Associations",
      category: "Expenses"
    }, {
      code: "668",
      name: "Interest on Business Debts",
      category: "Expenses"
    }, {
      code: "669",
      name: "Materials Used Directly in Industry or Business",
      category: "Expenses"
    }, {
      code: "670",
      name: "Stamps, Vouchers and Fees",
      category: "Expenses"
    }, {
      code: "671",
      name: "Shipping and Postage Charges",
      category: "Expenses"
    }, {
      code: "672",
      name: "Uniforms",
      category: "Expenses"
    }, {
      code: "673",
      name: "Parking and Tolls",
      category: "Expenses"
    }, {
      code: "674",
      name: "Office Expenses",
      category: "Expenses"
    }, {
      code: "675",
      name: "Computer Systems",
      category: "Expenses"
    }, {
      code: "676",
      name: "Ground Transportation Equipment, Except Vehicles",
      category: "Expenses"
    }, {
      code: "677",
      name: "Machinery and Equipment, Furniture and Fixtures and Any Other Fixed Assets to be Used in Industry or Business",
      category: "Expenses"
    }, {
      code: "678",
      name: "Planting Insurance",
      category: "Expenses"
    }, {
      code: "679",
      name: "Property Tax - Real",
      category: "Expenses"
    }, {
      code: "680",
      name: "Other Taxes - Patents",
      category: "Expenses"
    }, {
      code: "681",
      name: "Other Taxes - Licenses",
      category: "Expenses"
    }, {
      code: "682",
      name: "Professional Associations fees paid on behalf of employees",
      category: "Expenses"
    }, {
      code: "683",
      name: "Certain Other Expenses",
      category: "Expenses"
    }, {
      code: "684",
      name: "Payments for Services Rendered Outside of Puerto Rico",
      category: "Expenses"
    }, {
      code: "685",
      name: "Bundles",
      category: "Expenses"
    }, {
      code: "686",
      name: "Payments for Judicial or Extrajudicial Indemnification",
      category: "Expenses"
    }, {
      code: "687",
      name: "Payments for Virtual and Technological Tools and Other Subscriptions",
      category: "Expenses"
    }, {
      code: "688",
      name: "Deduction for Eligible Private Capital Investment",
      category: "Expenses"
    }, {
      code: "689",
      name: "Deduction to employers for Breastfeeding period",
      category: "Expenses"
    }, {
      code: "700",
      name: "Other deductions",
      category: "Expenses"
    }, {
      code: "750",
      name: "Provision for income Tax",
      category: "Expenses"
    }];
  }
});