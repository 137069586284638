define("account-berry-frontend/companies/show/reconciliations/controller", ["exports", "jquery", "ember-cli-pagination/computed/paged-array"], function (_exports, _jquery, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    confirmDelete: false,
    search: "",
    sortAscending: false,
    applicationController: Ember.inject.controller('application'),
    pagedReconciliations: (0, _pagedArray.default)('filteredReconciliations', {
      infinite: "unpaged",
      page: 1,
      perPage: 100
    }),
    modelObserver: Ember.observer('model', function () {
      if (!this.get('subscribedToChannel')) {
        this.subscribeToChannels();
      }

      try {
        this.set('filteredReconciliations', this.store.peekAll('reconciliation').filter(function (reconciliation) {
          return !reconciliation.get('isDeleted');
        }));
        this.set('pagedReconciliations', (0, _pagedArray.default)('filteredReconciliations', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      } catch (err) {
        Ember.Logger.error(err);
      }
    }),
    filteredReconciliations: Ember.computed('model', function () {
      return this.model;
    }),
    totalReconciliations: Ember.computed('filteredReconciliations', function () {
      return this.get('filteredReconciliations.length');
    }),
    filter: "cash_account",
    filterReconciliations: Ember.observer('search', function () {
      var search = this.search;
      var filter = this.filter;

      if (isNaN(search)) {
        search = search.toLowerCase();
      }

      if (search === "" || search.trim().length === 0) {
        this.set('filteredReconciliations', this.model);
      } else {
        this.set('filteredReconciliations', this.model.filter(function (reconciliation) {
          var reconciliationValue = reconciliation.get(filter);

          if (reconciliationValue) {
            if (isNaN(reconciliationValue)) {
              reconciliationValue = reconciliationValue.toLowerCase();
            }

            if (reconciliationValue.includes(search)) {
              return reconciliation;
            }
          }
        }));
      }

      this.set('pagedReconciliations', (0, _pagedArray.default)('filteredReconciliations', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    }),
    subscribeToChannels: function subscribeToChannels() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("reconciliation-channel-".concat(this.companyId));
      channel.bind('created', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          _this2.updateReconciliationList(data.reconciliation);
        }
      });
      channel.bind('updated', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var reconciliationId = data.reconciliation.id;
          delete data.reconciliation.id;

          var reconciliation = _this2.store.peekRecord('reconciliation', reconciliationId);

          reconciliation.setProperties(data.reconciliation);
        }
      });
      channel.bind('deleted', function (data) {
        if (data.current_user_id.toString() !== _this2.get('session.data.authenticated.id').toString()) {
          var reconciliation = _this2.store.peekRecord('reconciliation', data.reconciliation_id);

          reconciliation.deleteRecord();

          _this2.set('filteredReconciliations', _this2.store.peekAll('reconciliation').filter(function (reconciliation) {
            return !reconciliation.get('isDeleted');
          }));

          _this2.set('pagedReconciliations', (0, _pagedArray.default)('filteredReconciliations', {
            infinite: "unpaged",
            page: 1,
            perPage: 100
          }));
        }
      });
      this.set('subscribedToChannel', true);
    },
    updateReconciliationList: function updateReconciliationList(reconciliation) {
      this.store.push({
        data: {
          id: reconciliation.id,
          type: 'reconciliation',
          attributes: reconciliation
        }
      });
      this.set('filteredReconciliations', this.store.peekAll('reconciliation').filter(function (reconciliation) {
        return !reconciliation.get('isDeleted');
      }));
      this.set('pagedReconciliations', (0, _pagedArray.default)('filteredReconciliations', {
        infinite: "unpaged",
        page: 1,
        perPage: 100
      }));
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.companyId);
    }),
    userPermissions: Ember.computed('company', function () {
      return this.get('company.permissions')['company'];
    }),
    generateTable: function generateTable() {
      var table = "<div style='font-family: Verdana'><h3 style='text-align: center;'>".concat(this.get('company.name'), "</h3><h3 style='text-align:center;'>Reconciliations</h3><table id='reconciliationsTable' style='width:100%; font-size: 14px'><thead><tr><th style='text-align: left;'>DATE</th>");
      table += "<th style='text-align: right;'>BANK ACCOUNT</th>";
      table += "<th style='text-align: right;'>BEGINNING BALANCE</th>";
      table += "<th style='text-align: center;'>ENDING BALANCE</th>";
      table += "<th style='text-align: center;'>CLEARED BALANCE</th>";
      table += "<th style='text-align: right;'>RECONCILED</th></tr></thead>";
      this.get('filteredReconciliations').forEach(function (reconciliation) {
        table += "<tbody><td>".concat(reconciliation.get('date'), "</td>");
        table += "<td style='text-align: right;'>".concat(reconciliation.get('cash_account'), "</td>");
        table += "<td style='text-align: right;'>".concat(reconciliation.get('beginning_balance'), "</td>");
        table += "<td style='text-align: right;'>".concat(reconciliation.get('ending_balance'), "</td>");
        table += "<td style='text-align: right;'>".concat(reconciliation.get('cleared_balance'), "</td>");
        table += "<td style='text-align: center;'>".concat(reconciliation.get('reconciled') ? "YES" : "NO", "</td></tr></tbody>");
      });
      table += "</table></div>";
      return table;
    },
    actions: {
      changeFilter: function changeFilter(value) {
        this.set('filter', value);
      },
      toggleConfirmDelete: function toggleConfirmDelete(id) {
        if (id) {
          var _this = this;

          this.store.findRecord('reconciliation', id, {
            backgroundReload: false
          }).then(function (reconciliation) {
            _this.set('reconciliation', reconciliation);
          });
        }

        this.toggleProperty('confirmDelete');
      },
      deleteReconciliation: function deleteReconciliation() {
        var _this3 = this;

        this.reconciliation.destroyRecord().then(function () {
          _this3.get('applicationController').set('showLoading', true);

          var channel = _this3.get('session.pusher').subscribe("reconcile-channel-".concat(_this3.get('session.data.authenticated.id')));

          channel.bind('destroy', function (response) {
            _this3.get('applicationController').set('showLoading', false);

            if (response.errors) {
              var errors = "An Unkown Error Occured.";

              if (response.errors && response.errors[0].status !== "500") {
                errors = response.errors.map(function (error) {
                  return error;
                });
              } else if (response.payload.errors) {
                errors = response.payload.errors.map(function (error) {
                  return error;
                });
              }

              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
              });
            } else {
              _this3.set('filteredReconciliations', _this3.store.peekAll('reconciliation').filter(function (reconciliation) {
                return !reconciliation.get('isDeleted');
              }));

              _this3.setProperties({
                pagedReconciliations: (0, _pagedArray.default)('filteredReconciliations', {
                  infinite: "unpaged",
                  page: 1,
                  perPage: 100
                }),
                reconciliation: {},
                confirmDelete: false
              });

              _this3.store.findAll('reconciliation');

              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>Reconciliation Deleted</span></div>"
              });
            }
          });
        }).catch(function (response) {
          var errors = "An Unkown Error Occured.";

          if (response.errors && response.errors[0].status !== "500") {
            errors = response.errors.map(function (error) {
              return error;
            });
          } else if (response.payload.errors) {
            errors = response.payload.errors.map(function (error) {
              return error;
            });
          }

          _this3.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>".concat(errors, "</span></div>")
          });
        });
      },
      loadNext: function loadNext() {
        this.get('pagedReconciliations').loadNextPage();
      },
      sortBy: function sortBy(property) {
        this.toggleProperty('sortAscending');

        if (this.sortAscending) {
          this.set('filteredReconciliations', this.get('filteredReconciliations').sortBy(property));
        } else {
          this.set('filteredReconciliations', this.get('filteredReconciliations').sortBy(property).reverse());
        }

        this.set('pagedReconciliations', (0, _pagedArray.default)('filteredReconciliations', {
          infinite: "unpaged",
          page: 1,
          perPage: 100
        }));
      },
      print: function print() {
        var table = this.generateTable();
        (0, _jquery.default)('.print-container').empty();
        (0, _jquery.default)('.print-container').append(table);
        (0, _jquery.default)('.print-container').printThis({
          importCSS: false
        }); // let w = window.open();
        // w.document.write("<div id='print-container'></div>");
        // w.document.getElementById('print-container').innerHTML += table;
        // w.print();
        // w.close();
      },
      csvExport: function csvExport() {
        var table = this.generateTable();
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#reconciliationsTable').table2CSV({
          delivery: 'download',
          filename: "".concat(this.store.peekRecord('company', this.get('companyId')).get('name'), "_reconciliations_table_").concat(moment(new Date()).format('MMDDYYYY'), ".csv")
        }); // window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`;

        (0, _jquery.default)('#reconciliationsTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});