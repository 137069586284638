define("account-berry-frontend/companies/show/reports/profit-loss/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    applicationController: Ember.inject.controller('application'),
    cable: Ember.inject.service(),
    hideZeros: false,
    includeUnassigned: false,
    reportType: 'labels',
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    comparativeYears: Ember.computed(function () {
      return "1";
    }),
    init: function init() {
      var _this2 = this;

      _jquery.default.initialize('#profit-loss-table', function () {
        _this2.setTable();
      });
    },
    setTable: function setTable() {
      var printableTable = (0, _jquery.default)('#profit-loss-table').clone(); // printableTable.css('font-size', '1vw');

      this.set('printableTable', printableTable); // Ember.$('#profit-loss-table').tableHeadFixer({
      //   // fix table header
      //   head: false,
      //   // fix table footer
      //   foot: false,
      //   // fix x left columns
      //   left: 1,
      //   // fix x right columns
      //   right: 0,
      //   // z-index
      //   'z-index': 0
      // });
    },
    AddReportSubData: function AddReportSubData(account, table) {
      if (account.is_title) {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td></tr>");
        account.sub_accounts.forEach(function (subAccount) {
          table += "<tr><td>".concat(subAccount.number ? subAccount.number : "", " ").concat(subAccount.name, "</td>");
          subAccount.balance.forEach(function (balance) {
            table += "<td>".concat(parseFloat(balance).toFixed(2), "</td>");
          });
        });
        table += "</tr>";
        table += "<tr><td>Total ".concat(account.name, "</td>");
        account.total_balance.forEach(function (totalBalance) {
          table += "<td>".concat(parseFloat(totalBalance).toFixed(2), "</td>");
        });
        table += "</tr>";
      } else {
        table += "<tr><td>".concat(account.number ? account.number : "", " ").concat(account.name, "</td>");
        account.balance.forEach(function (balance) {
          table += "<td>".concat(parseFloat(balance).toFixed(2), "</td>");
        });
        table += "</tr>";
      }

      return table;
    },
    actions: {
      setComparativeYears: function setComparativeYears(years) {
        this.set('comparativeYears', years);
      },
      setLabels: function setLabels(labels) {
        this.set('labelIds', labels);
      },
      setReportType: function setReportType(type) {
        this.set('reportType', type);
        this.set('labelsReport', type === "labels");
        this.set('comparativeMonths', type === "months");
        this.setProperties({
          profitLossData: null,
          fromDate: null,
          toDate: null,
          accountTransactions: false
        });
      },
      getProfitLoss: function getProfitLoss() {
        var _this3 = this;

        var labelIds = this.get('labelIds');

        if (this.get('includeUnassigned') && labelIds && !labelIds.includes('unassigned')) {
          labelIds.push('unassigned');
        }

        var data = JSON.stringify({
          report: {
            from_date: this.fromDate,
            to_date: this.toDate
          },
          report_type: 'profit_loss',
          comparative_years: !this.labelsReport ? this.get('comparativeYears') : "1",
          comparative_months: this.get('comparativeMonths') ? true : null,
          labels: labelIds
        });

        var _this$get = this.get('session.data.authenticated'),
            email = _this$get.email,
            token = _this$get.token;

        var headers = {
          Authorization: "Token token=\"".concat(token, "\", email=\"").concat(email, "\"")
        };
        var consumer = this.get('cable').createConsumer("".concat(_environment.default['apiURL'], "/cable"));

        var _this = this;

        consumer.createSubscription({
          channel: "ReportsChannel",
          room: "ProfitLoss" + this.get('session.data.authenticated.id')
        }, {
          connected: function connected() {
            console.log('Connected to ReportsChannel-ProfitLoss');

            _this.get('ajax').request("".concat(_environment.default.apiURL, "/companies/").concat(_this.companyId, "/reports"), {
              method: 'POST',
              contentType: 'application/json',
              data: data,
              headers: headers
            }).then(function () {
              _this.get('applicationController').set('showLoading', true);
            }).catch(function () {
              _this.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>An error occured</span></div>"
              });
            });
          },
          received: function received(data) {
            if (data.error) {
              _this3.get('notify').alert({
                html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><strong>Error:</strong>&nbsp<span>An error occurred, please try again.</span></div>"
              });
            } else {
              _this3.set('profitLossData', data.report);

              consumer.disconnect();

              _this3.get('applicationController').set('showLoading', false);
            }
          },
          disconnected: function disconnected() {
            console.log("ReportsChannel-ProfitLoss disconnected");
          }
        });
      },
      showAccountTransactions: function showAccountTransactions(id) {
        var _this4 = this;

        this.store.findRecord('account', id).then(function (account) {
          _this4.set('account', account); // this.transactionsActionCable();


          _this4.set('accountTransactions', true);
        });
      },
      cancelAccountTransactions: function cancelAccountTransactions() {
        this.set('accountTransactions', false);
      },
      print: function print() {
        // let divToPrint = this.get('printableTable')
        // let divToPrint = document.getElementById("profitLoss");
        // let newWin = window.open("");
        // newWin.document.write(divToPrint.outerHTML);
        // newWin.print();
        // newWin.close();
        (0, _jquery.default)('#profitLoss').printThis(); // this.printableTable.printThis({
        //   pageTitle: `${this.get('company.name')} - Profit & Loss`,
        //   header: `
        //     <h4 style='text-align: center;'>${this.get('company.name')} - Profit & Loss</h4>
        //     <h4 style='text-align: center;'>From ${moment(this.get('fromDate')).format('MMMM D, YYYY')} To ${moment(this.get('toDate')).format('MMMM D, YYYY')}</h4>
        //   `
        // });
      },
      csvExport: function csvExport() {
        var _this5 = this;

        var table = "<table id='profitLossTable' style='width:100%; font-size: 14px'><tbody>";

        if (this.get('comparativeYears') === "1" && !this.get('comparativeMonths')) {
          table += "<tr><td>Profit And Loss ".concat(moment(this.get('fromDate')).format('YYYY-MM-DD'), " - ").concat(moment(this.get('toDate')).format('YYYY-MM-DD'), "</td></tr>");
        } else {
          table += "<tr><td>Profit and Loss</td></tr>";
          table += "<tr>";
          table += "<td></td>";
          this.get('profitLossData.months_years').forEach(function (monthYear) {
            table += "<td>".concat(monthYear, "</td>");
          });
          table += "</tr>";
        }

        table += "<tr><td>Income</td></tr>";
        this.get('profitLossData.income').forEach(function (account) {
          table = _this5.AddReportSubData(account, table);
        });
        table += "<tr><td>Total Income</td>";
        this.get('profitLossData.total_income').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Cost of Goods Sold</td></tr>";
        this.get('profitLossData.cost_of_goods_sold').forEach(function (account) {
          table = _this5.AddReportSubData(account, table);
        });
        table += "<tr><td>Total COGS</td>";
        this.get('profitLossData.total_cost_of_goods_sold').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Gross Profit</td>";
        this.get('profitLossData.gross_profit').forEach(function (grossProfit) {
          table += "<td>".concat(parseFloat(grossProfit).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Expense</td></tr>";
        this.get('profitLossData.expense').forEach(function (account) {
          table = _this5.AddReportSubData(account, table);
        });
        table += "<tr><td>Total Expense</td>";
        this.get('profitLossData.total_expenses').forEach(function (total) {
          table += "<td>".concat(parseFloat(total).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "<tr><td>Net Income</td>";
        this.get('profitLossData.net_income').forEach(function (netIncome) {
          table += "<td>".concat(parseFloat(netIncome).toFixed(2), "</td>");
        });
        table += "</tr>";
        table += "</tbody></table>";
        (0, _jquery.default)('#csvExport').show();
        document.getElementById('csvExport').innerHTML += table;
        (0, _jquery.default)('#profitLossTable').table2CSV({
          delivery: 'download',
          filename: 'ProfitLossTable.csv'
        });
        (0, _jquery.default)('#profitLossTable').remove();
        (0, _jquery.default)('#csvExport').hide();
      }
    }
  });

  _exports.default = _default;
});