define("account-berry-frontend/components/quick-add/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dropdownClosed: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)(window).on("click", function (event) {
        if ((0, _jquery.default)('.quick-add-dropdown').has(event.target).length == 0 && !_this.isDestroyed) {
          _this.set('dropdownClosed', true);
        }
      });
    },
    options: Ember.computed(function () {
      return [{
        name: "Company"
      }, {
        name: "Item",
        route: "companies.show.items.new"
      }, {
        name: "Account",
        route: "companies.show.accounts.new"
      }, {
        name: "Check",
        route: "companies.show.checks.new"
      }, {
        name: "Deposit",
        route: "companies.show.deposits.new"
      }, {
        name: "Journal Entry",
        route: "companies.show.journal-entries.new"
      }, {
        name: "Reconciliation",
        route: "companies.show.reconciliations.new"
      }, {
        name: "Import Batch",
        route: "companies.show.batches.new"
      }, {
        name: "Customers"
      }, {
        name: "Customer",
        route: "companies.show.customers.customers-table.new"
      }, {
        name: "Representative",
        route: "companies.show.customers.representatives.new"
      }, {
        name: "Tax",
        route: "companies.show.customers.taxes.new"
      }, {
        name: "Sales Order",
        route: "companies.show.customers.sales-orders.new"
      }, {
        name: "Invoice",
        route: "companies.show.customers.invoices.new"
      }, {
        name: "Sale Return",
        route: "companies.show.customers.returns.new"
      }, {
        name: "Vendors"
      }, {
        name: "Vendor",
        route: "companies.show.vendors.vendors-table.new"
      }, {
        name: "Purchase Order",
        route: "companies.show.vendors.purchase-orders.new"
      }, {
        name: "Receiving & Bill",
        route: "companies.show.vendors.receiving-and-bills.new"
      }, {
        name: "Bills & Credits",
        route: "companies.show.vendors.bills.new"
      }, {
        name: "Purchase Return",
        route: "companies.show.vendors.returns.new"
      }, {
        name: "Employees"
      }, {
        name: "Employee",
        route: "companies.show.employees.employees-table.new"
      }, {
        name: "Payroll",
        route: "companies.show.employees.payrolls.new"
      }];
    }),
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('dropdownClosed');
      }
    }
  });

  _exports.default = _default;
});