define("account-berry-frontend/companies/show/employees/w2/controller", ["exports", "jquery", "account-berry-frontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ajaxCall: Ember.inject.service(),
    session: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    search: "",
    sortAscending: false,
    init: function init() {
      var _this2 = this;

      var channel = this.get('session.pusher').subscribe("w2-channel-".concat(this.get('session.data.authenticated.id')));
      this.set('channel', channel); // channel.bind('w2', (response) => {
      //   this.store.findRecord('json-storage', response.json_storage_id).then((jsonStorage) => {
      //     this.set('w2Data', jsonStorage.get('stored_json.w2'));
      //   });
      // });
      // Electronic Filing File generation open channel

      channel.bind('electronicFiling', function (response) {
        _this2.get('applicationController').set('showLoading', true);

        _this2.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
          var element = document.createElement('a');
          var content = '';
          jsonStorage.get('stored_json.electronic_record_data').forEach(function (electronic_data) {
            content += electronic_data + '\n';
          });
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
          element.setAttribute('download', 'w2_electronic_filing.txt');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          _this2.get('applicationController').set('showLoading', false);
        });
      });
    },
    filteredEmployees: Ember.computed('w2Data', 'search', 'sortAscending', function () {
      var _this3 = this;

      var filteredEmployees = [];

      for (var key in this.get('w2Data')) {
        if (key !== "electronicFillingNumber" && this.get("w2Data.".concat(key, ".general_info"))) {
          var employeeData = this.get("w2Data.".concat(key));

          if (this.get('search') === "") {
            filteredEmployees.push(employeeData);
          } else if ("".concat(employeeData.general_info.first_name || '', " ").concat(employeeData.general_info.last_name || '').toLowerCase().includes(this.get('search').toLowerCase())) {
            filteredEmployees.push(employeeData);
          }
        }
      }

      if (this.get('sortBy')) {
        filteredEmployees = filteredEmployees.sort(function (a, b) {
          return a.general_info[_this3.get('sortBy')] > b.general_info[_this3.get('sortBy')];
        });
      }

      return this.get('sortAscending') ? filteredEmployees.reverse() : filteredEmployees;
    }),
    accounts: Ember.computed(function () {
      return this.store.findAll('account');
    }),
    healthCoverageAccounts: Ember.computed(function () {
      return [undefined];
    }),
    charitableContributionsAccounts: Ember.computed(function () {
      return [undefined];
    }),
    governmentRetirementFundAccounts: Ember.computed(function () {
      return [undefined];
    }),
    codaPlansAccounts: Ember.computed(function () {
      return [undefined];
    }),
    saveAndDoubleMoneyProgramAccounts: Ember.computed(function () {
      return [undefined];
    }),
    inputAccounts: Ember.computed('accounts', 'healthCoverageAccounts.@each', 'charitableContributionsAccounts.@each', 'governmentRetirementFundAccounts.@each', 'codaPlansAccounts.@each', 'saveAndDoubleMoneyProgramAccounts.@each', function () {
      var _this4 = this;

      var inputAccounts = [];
      var accountArrays = ['healthCoverageAccounts', 'charitableContributionsAccounts', 'governmentRetirementFundAccounts', 'codaPlansAccounts', 'saveAndDoubleMoneyProgramAccounts'];
      accountArrays.forEach(function (arrName) {
        inputAccounts = inputAccounts.concat(_this4.get(arrName));
      });
      return inputAccounts;
    }),
    codeOptions: Ember.computed(function () {
      return [{
        value: "A",
        description: "A. Salario de empleados públicos por concepto de horas extras trabajadas en situaciones de emergencia bajo la Ley 324-2004"
      }, {
        value: "B",
        description: "B. Salarios exentos pagados a miembros de la Policía de Puerto Rico bajo la Sección 1031.02(a)(34) del Código"
      }, {
        value: "C",
        description: "C. Estipendios recibidos por ciertos médicos durante el período de internado bajo la Sección 1031.02(a)(9) del Código"
      }, {
        value: "D",
        description: "D. Compensación pagada a un investigador o científico elegible por servicios prestados bajo la Sección 1031.02(a)(26) del Código o bajo la Sección 2022.05 del Código de Incentivos de Puerto Rico, Ley 60-2019 (Código de Incentivos)"
      }, {
        value: "E",
        description: "E. Salario hasta $40,000 anuales bajo la Sección 1031.02(a)(36) del Código"
      }, {
        value: "F",
        description: "F. Liquidación del pago de licencias por vacaciones y enfermedad a empleados públicos bajo la Ley 211-2015"
      }, {
        value: "G",
        description: "G. Pagos por desastre declarados exentos por el Gobernador de Puerto Rico o el Presidente de los Estados Unidos"
      }, {
        value: "H",
        description: "H. Pagos a empleados públicos bajo el Programa de Transición Voluntaria, según las Órdenes Administrativas vigentes emitidas por AAFAF"
      }, {
        value: "I",
        description: "I. Compensación o indemnización pagada a un empleado por razón de despido bajo la Sección 1031.01(b)(15) del Código"
      }];
    }),
    w2PdfCoordinates: function w2PdfCoordinates(employeeData) {
      var employee = employeeData.general_info;

      if (employee) {
        var birthDate = employee.birth_date ? employee.birth_date.split("-") : null;
        var birthDay = birthDate ? birthDate[2] : "";
        var birthMonth = birthDate ? birthDate[1] : "";
        var birthYear = birthDate ? birthDate[0] : "";
        var terminationDate = this.get('company.termination_date');
        var terminationDay = terminationDate ? this.get('company.termination_date').split("-")[1] : "";
        var terminationMonth = terminationDate ? this.get('company.termination_date').split("-")[2] : "";
        var terminationYear = terminationDate ? this.get('company.termination_date').split("-")[0] : "";
        var excemption = employeeData._16Value ? parseFloat(employeeData._16Value).toFixed(2) : 0;
        var tipsExcemption = employeeData.tipsExcemption ? parseFloat(employeeData.tipsExcemption).toFixed(2) : 0;

        var _wagesExcemption = employeeData.wagesExcemption ? parseFloat(employeeData.wagesExcemption).toFixed(2) : 0;

        var wages = parseFloat(employeeData.wages) !== 0 ? (parseFloat(employeeData.wages || 0) - _wagesExcemption).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var commissions = parseFloat(employeeData.commissions) !== 0 ? parseFloat(employeeData.commissions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var allowances = parseFloat(employeeData.allowances) !== 0 ? parseFloat(employeeData.allowances).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var tips = parseFloat(employeeData.tips) !== 0 ? (parseFloat(employeeData.tips) - tipsExcemption).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var reimbursementsExpenses = employeeData.reimbursements && parseFloat(employeeData.reimbursements) !== 0 ? parseFloat(employeeData.reimbursements).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var taxWithheld = employeeData.tax_withheld && parseFloat(employeeData.tax_withheld) !== 0 ? parseFloat(employeeData.tax_withheld).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var codaPlans = employeeData.coda_plans_fund && parseFloat(employeeData.coda_plans_fund) !== 0 ? parseFloat(employeeData.coda_plans_fund).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var ssWages = employeeData.ss_wages && parseFloat(employeeData.ss_wages) !== 0 ? parseFloat(employeeData.ss_wages).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var ssTaxWithheld = employeeData.ss_tax_withheld && parseFloat(employeeData.ss_tax_withheld) !== 0 ? parseFloat(employeeData.ss_tax_withheld).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var medicareWagesTips = employeeData.medicare_wages_and_tips && parseFloat(employeeData.medicare_wages_and_tips) !== 0 ? parseFloat(employeeData.medicare_wages_and_tips).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var medicareTaxWithheld = employeeData.medicare_tax_withheld && parseFloat(employeeData.medicare_tax_withheld) !== 0 ? parseFloat(employeeData.medicare_tax_withheld).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var ssTips = employeeData.tips && parseFloat(employeeData.tips) !== 0 ? parseFloat(employeeData.tips).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var uncollectedSsTipsTax = employeeData.uncollected_ss_tips_tax && parseFloat(employeeData.uncollected_ss_tips_tax) !== 0 ? parseFloat(employeeData.uncollected_ss_tips_tax).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
        var uncollectedmedicareTipsTax = employeeData.uncollected_medicare_tips_tax && parseFloat(employeeData.uncollected_medicare_tips_tax) !== 0 ? parseFloat(employeeData.uncollected_medicare_tips_tax).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";

        var _21 = ((employeeData.ss_tax_withheld && parseFloat(employeeData.ss_tax_withheld) !== 0 ? parseFloat(employeeData.ss_tax_withheld) : 0) + (employeeData.ss_tips && parseFloat(employeeData.ss_tips) !== 0 ? parseFloat(employeeData.ss_tips) : 0)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

        var total = (parseFloat(employeeData.wages) !== 0 ? parseFloat(employeeData.wages || 0) - _wagesExcemption : 0) + (employeeData.commissions ? parseFloat(employeeData.commissions) : 0) + (employeeData.allowances ? parseFloat(employeeData.allowances) : 0) + (employeeData.tips ? parseFloat(employeeData.tips) - tipsExcemption : 0);
        total = parseFloat(total) !== 0 ? parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "0";
        var employerA = null,
            employerB = null,
            employerC = null,
            employerD = null,
            employerE = null,
            employerF = null,
            employerG = null,
            employerOther = null; // if (this.get('year') === "2019") {
        //   employerA =  { value: employeeData.employerA ? "X" : "", x: 250, y: 543, size: 10 }
        //   employerB =  { value: employeeData.employerB ? "X" : "", x: 250, y: 509, size: 10 }
        //   employerC =  { value: employeeData.employerC ? "X" : "", x: 250, y: 495, size: 10 }
        //   employerD =  { value: employeeData.employerD ? "X" : "", x: 250, y: 473, size: 10 }
        //   employerE =  { value: employeeData.employerE ? "X" : "", x: 250, y: 435, size: 10 }
        //   employerF =  { value: employeeData.employerF ? "X" : "", x: 250, y: 435, size: 10 }
        //   employerOther = { 
        //     value: employeeData.employerOther ? `${employeeData.employerOther.substring(0, 15)}${employeeData.employerOther.length > 15 ? "..." : ""}` : "",
        //     x: 291, y: 435, size: 6
        //   };

        if (this.get('year') === "2021") {
          employerA = {
            value: employeeData.employerA ? "X" : "",
            x: 250,
            y: 560,
            size: 10
          };
          employerB = {
            value: employeeData.employerB ? "X" : "",
            x: 250,
            y: 538,
            size: 10
          };
          employerC = {
            value: employeeData.employerC ? "X" : "",
            x: 250,
            y: 515,
            size: 10
          };
          employerD = {
            value: employeeData.employerD ? "X" : "",
            x: 250,
            y: 495,
            size: 10
          };
          employerE = {
            value: employeeData.employerE ? "X" : "",
            x: 250,
            y: 465,
            size: 10
          };
          employerF = {
            value: employeeData.employerF ? "X" : "",
            x: 250,
            y: 435,
            size: 10
          };
          employerOther = {
            value: employeeData.employerOther ? "".concat(employeeData.employerOther.substring(0, 15)).concat(employeeData.employerOther.length > 15 ? "..." : "") : "",
            x: 298,
            y: 440,
            size: 6
          };
        } else {
          employerA = {
            value: employeeData.employerA ? "X" : "",
            x: 250,
            y: 560,
            size: 10
          };
          employerB = {
            value: employeeData.employerB ? "X" : "",
            x: 250,
            y: 542,
            size: 10
          };
          employerC = {
            value: employeeData.employerC ? "X" : "",
            x: 250,
            y: 522,
            size: 10
          };
          employerD = {
            value: employeeData.employerD ? "X" : "",
            x: 250,
            y: 502,
            size: 10
          };
          employerE = {
            value: employeeData.employerE ? "X" : "",
            x: 250,
            y: 475,
            size: 10
          };
          employerF = {
            value: employeeData.employerF ? "X" : "",
            x: 250,
            y: 455,
            size: 10
          };
          employerG = {
            value: employeeData.employerG ? "X" : "",
            x: 250,
            y: 415,
            size: 10
          };
          employerOther = {
            value: employeeData.employerOther ? "".concat(employeeData.employerOther.substring(0, 15)).concat(employeeData.employerOther.length > 15 ? "..." : "") : "",
            x: 298,
            y: 415,
            size: 6
          };
        } // } else {
        // employerA = { value: employeeData.employerA ? "X" : "", x: 250, y: 560, size: 10 };
        // employerB = { value: employeeData.employerB ? "X" : "", x: 250, y: 538, size: 10 };
        // employerC = { value: employeeData.employerC ? "X" : "", x: 250, y: 515, size: 10 };
        // employerD = { value: employeeData.employerD ? "X" : "", x: 250, y: 495, size: 10 };
        // employerE = { value: employeeData.employerE ? "X" : "", x: 250, y: 465, size: 10 };
        // employerF = { value: employeeData.employerF ? "X" : "", x: 250, y: 435, size: 10 };
        // employerOther = {
        //   value: employeeData.employerOther ? `${employeeData.employerOther.substring(0, 15)}${employeeData.employerOther.length > 15 ? "..." : ""}` : "",
        //   x: 298, y: 440, size: 6
        // };
        // }


        var firstName = {
          value: employee.first_name || "",
          x: 44,
          y: 703,
          size: 10
        };
        var lastName = {
          value: employee.last_name || "",
          x: 44,
          y: 667,
          size: 10
        };
        var employeePostalAddressLineOne = {
          value: employee.address_line_one || "",
          x: 44,
          y: 636,
          size: 8
        };
        var employeePostalAddressLineTwo = {
          value: employee.address_line_two || "",
          x: 44,
          y: 625,
          size: 8
        };
        var employeePostalAddressLineThree = {
          value: "".concat(employee.city || "", " ").concat(employee.state || "", " ").concat(employee.country || "", " ").concat(employee.zip_code || ""),
          x: 44,
          y: 615,
          size: 8
        };
        var birthDayData = {
          value: birthDay || "",
          x: 128,
          y: 596,
          size: 10
        };
        var birthMonthData = {
          value: birthMonth || "",
          x: 178,
          y: 596,
          size: 10
        };
        var birthYearData = {
          value: birthYear || "",
          x: 211,
          y: 596,
          size: 10
        };
        var employerName = {
          value: this.get('company.name') || "",
          x: 44,
          y: 563,
          size: 8
        };
        var employerPostalAddressLineOne = {
          value: this.get('company.postal_address_line_one') || "",
          x: 44,
          y: 554,
          size: 8
        };
        var employerPostalAddressLineTwo = {
          value: this.get('company.postal_address_line_two') || "",
          x: 44,
          y: 543,
          size: 8
        };
        var employerPostalAddressLineThree = {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 44,
          y: 535,
          size: 8
        };
        var employerPhoneNumber = {
          value: this.get('company.phone') || "",
          x: 137,
          y: 516,
          size: 10
        };
        var employerEmail = {
          value: "",
          x: 137,
          y: 515,
          size: 10
        };
        var ceaseOpeationsDay = {
          value: terminationDay,
          x: 137,
          y: 495,
          size: 10
        };
        var ceaseOpeationsMonth = {
          value: terminationMonth,
          x: 178,
          y: 495,
          size: 10
        };
        var ceaseOpeationsYear = {
          value: terminationYear,
          x: 178,
          y: 495,
          size: 10
        };
        var electronicFilingNumber = {
          value: this.get('w2Data.electronicFilingNumber') || "",
          x: 44,
          y: 461,
          size: 10
        };
        var controlNumber = {
          value: employeeData.control_number || "",
          x: 44,
          y: 433,
          size: 10
        };

        if (this.get('year') === "2021") {
          firstName = {
            value: employee.first_name || "",
            x: 44,
            y: 710,
            size: 10
          };
          lastName = {
            value: employee.last_name || "",
            x: 44,
            y: 685,
            size: 10
          };
          employeePostalAddressLineOne = {
            value: employee.address_line_one || "",
            x: 44,
            y: 650,
            size: 8
          };
          employeePostalAddressLineTwo = {
            value: employee.address_line_two || "",
            x: 44,
            y: 640,
            size: 8
          };
          employeePostalAddressLineThree = {
            value: "".concat(employee.city || "", " ").concat(employee.state || "", " ").concat(employee.country || "", " ").concat(employee.zip_code || ""),
            x: 44,
            y: 630,
            size: 8
          };
          birthDayData = {
            value: birthDay || "",
            x: 128,
            y: 610,
            size: 10
          };
          birthMonthData = {
            value: birthMonth || "",
            x: 178,
            y: 610,
            size: 10
          };
          birthYearData = {
            value: birthYear || "",
            x: 211,
            y: 610,
            size: 10
          };
          employerName = {
            value: this.get('company.name') || "",
            x: 44,
            y: 578,
            size: 7.5
          };
          employerPostalAddressLineOne = {
            value: this.get('company.postal_address_line_one') || "",
            x: 44,
            y: 568,
            size: 7.5
          };
          employerPostalAddressLineTwo = {
            value: this.get('company.postal_address_line_two') || "",
            x: 44,
            y: 558,
            size: 7.5
          };
          employerPostalAddressLineThree = {
            value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
            x: 44,
            y: 548,
            size: 7.5
          };
          employerPhoneNumber = {
            value: this.get('company.phone') || "",
            x: 137,
            y: 530,
            size: 10
          };
          employerEmail = {
            value: this.get('company.email') || "",
            x: 127,
            y: 511,
            size: 7
          };
          ceaseOpeationsDay = {
            value: terminationDay,
            x: 137,
            y: 505,
            size: 10
          };
          ceaseOpeationsMonth = {
            value: terminationMonth,
            x: 178,
            y: 505,
            size: 10
          };
          ceaseOpeationsYear = {
            value: terminationYear,
            x: 178,
            y: 505,
            size: 10
          };
          electronicFilingNumber = {
            value: this.get('w2Data.electronicFilingNumber') || "",
            x: 44,
            y: 452,
            size: 10
          };
          controlNumber = {
            value: employeeData.control_number || "",
            x: 44,
            y: 427,
            size: 10
          };
        } else {
          firstName = {
            value: employee.first_name || "",
            x: 44,
            y: 715,
            size: 10
          };
          lastName = {
            value: employee.last_name || "",
            x: 44,
            y: 685,
            size: 10
          };
          employeePostalAddressLineOne = {
            value: employee.address_line_one || "",
            x: 44,
            y: 660,
            size: 8
          };
          employeePostalAddressLineTwo = {
            value: employee.address_line_two || "",
            x: 44,
            y: 650,
            size: 8
          };
          employeePostalAddressLineThree = {
            value: "".concat(employee.city || "", " ").concat(employee.state || "", " ").concat(employee.country || "", " ").concat(employee.zip_code || ""),
            x: 44,
            y: 640,
            size: 8
          };
          birthDayData = {
            value: birthDay || "",
            x: 128,
            y: 613,
            size: 10
          };
          birthMonthData = {
            value: birthMonth || "",
            x: 178,
            y: 613,
            size: 10
          };
          birthYearData = {
            value: birthYear || "",
            x: 211,
            y: 613,
            size: 10
          };
          employerName = {
            value: this.get('company.name') || "",
            x: 44,
            y: 580,
            size: 7
          };
          employerPostalAddressLineOne = {
            value: this.get('company.postal_address_line_one') || "",
            x: 44,
            y: 572,
            size: 7
          };
          employerPostalAddressLineTwo = {
            value: this.get('company.postal_address_line_two') || "",
            x: 44,
            y: 565,
            size: 7
          };
          employerPostalAddressLineThree = {
            value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
            x: 44,
            y: 557,
            size: 7
          };
          employerPhoneNumber = {
            value: this.get('company.phone') || "",
            x: 137,
            y: 535,
            size: 10
          };
          employerEmail = {
            value: this.get('company.email') || "",
            x: 127,
            y: 513,
            size: 7
          };
          ceaseOpeationsDay = {
            value: terminationDay,
            x: 137,
            y: 507,
            size: 10
          };
          ceaseOpeationsMonth = {
            value: terminationMonth,
            x: 178,
            y: 507,
            size: 10
          };
          ceaseOpeationsYear = {
            value: terminationYear,
            x: 178,
            y: 507,
            size: 10
          };
          electronicFilingNumber = {
            value: this.get('w2Data.electronicFilingNumber') || "",
            x: 44,
            y: 457,
            size: 10
          };
          controlNumber = {
            value: employeeData.control_number || "",
            x: 44,
            y: 433,
            size: 10
          };
        }

        return {
          firstName: firstName,
          lastName: lastName,
          employeePostalAddressLineOne: employeePostalAddressLineOne,
          employeePostalAddressLineTwo: employeePostalAddressLineTwo,
          employeePostalAddressLineThree: employeePostalAddressLineThree,
          birthDay: birthDayData,
          birthMonth: birthMonthData,
          birthYear: birthYearData,
          employerName: employerName,
          employerPostalAddressLineOne: employerPostalAddressLineOne,
          employerPostalAddressLineTwo: employerPostalAddressLineTwo,
          employerPostalAddressLineThree: employerPostalAddressLineThree,
          employerPhoneNumber: employerPhoneNumber,
          employerEmail: employerEmail,
          ceaseOpeationsDay: ceaseOpeationsDay,
          ceaseOpeationsMonth: ceaseOpeationsMonth,
          ceaseOpeationsYear: ceaseOpeationsYear,
          electronicFilingNumber: electronicFilingNumber,
          controlNumber: controlNumber,
          ssNumber: {
            value: employee.social_security || "",
            x: 249,
            y: 706,
            size: 10
          },
          ein: {
            value: this.get('company.ein') || "",
            x: 249,
            y: 672,
            size: 10
          },
          costEmployerHealthCoverage: {
            value: employeeData.health_coverage_cost || "",
            x: 249,
            y: 635,
            size: 10
          },
          charitableContributions: {
            value: employeeData.charitable_contributions || "",
            x: 249,
            y: 601,
            size: 10
          },
          employerA: employerA,
          employerB: employerB,
          employerC: employerC,
          employerD: employerD,
          employerE: employerE,
          employerF: employerF,
          employerG: employerG,
          employerOther: employerOther,
          wages: {
            value: wages,
            x: 364,
            y: 727,
            size: 10
          },
          commissions: {
            value: commissions,
            x: 364,
            y: 706,
            size: 10
          },
          allowances: {
            value: allowances,
            x: 364,
            y: 685,
            size: 10
          },
          tips: {
            value: tips,
            x: 364,
            y: 665,
            size: 10
          },
          total: {
            value: total,
            x: 364,
            y: 645,
            size: 10
          },
          reimbursementsExpenses: {
            value: reimbursementsExpenses,
            x: 364,
            y: 614,
            size: 10
          },
          taxWithheld: {
            value: taxWithheld,
            x: 364,
            y: 590,
            size: 10
          },
          codaPlans: {
            value: codaPlans,
            x: 364,
            y: 532,
            size: 10
          },
          _16Code: {
            value: employeeData._16Code || "",
            x: 376,
            y: 495,
            size: 10
          },
          _16Value: {
            value: employeeData._16Value ? parseFloat(employeeData._16Value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
            x: 400,
            y: 495,
            size: 10
          },
          _17Code: {
            value: employeeData._17Code || "",
            x: 376,
            y: 474,
            size: 10
          },
          _17Value: {
            value: employeeData._17Value ? parseFloat(employeeData._17Value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
            x: 400,
            y: 474,
            size: 10
          },
          _18Code: {
            value: employeeData._18Code || "",
            x: 376,
            y: 453,
            size: 10
          },
          _18Value: {
            value: employeeData._18Value ? parseFloat(employeeData._18Value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "",
            x: 400,
            y: 453,
            size: 6
          },
          _19: {
            value: employeeData._19 || "",
            x: 375,
            y: 413,
            size: 10
          },
          ssWages: {
            value: ssWages,
            x: 485,
            y: 702,
            size: 10
          },
          ssTaxWithheld: {
            value: _21,
            x: 485,
            y: 654,
            size: 10
          },
          // ssTaxWithheld: { value: ssTaxWithheld, x: 485, y: 654, size: 10 },
          medicareWagesTips: {
            value: medicareWagesTips,
            x: 485,
            y: 609,
            size: 10
          },
          medicareTaxWithheld: {
            value: medicareTaxWithheld,
            x: 485,
            y: 561,
            size: 10
          },
          ssTips: {
            value: ssTips,
            x: 485,
            y: 513,
            size: 10
          },
          uncollectedSsTipsTax: {
            value: uncollectedSsTipsTax,
            x: 485,
            y: 465,
            size: 10
          },
          uncollectedmedicareTipsTax: {
            value: uncollectedmedicareTipsTax,
            x: 485,
            y: 417,
            size: 10
          }
        };
      }
    },
    w3PdfCoordinates: function w3PdfCoordinates() {
      // let w3Data = this.get('w2Data.totals');
      // let w2Data = this.get('w2Data')
      // let total = ((wages ? parseFloat(wages) : 0) +
      //             (commissions ? parseFloat(commissions) : 0) +
      //             (allowances ? parseFloat(allowances) : 0) +
      //             (tips ? parseFloat(tips) : 0)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      // wages = parseFloat(wages) !== 0 ? parseFloat(wages).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // commissions = parseFloat(commissions) !== 0 ? parseFloat(commissions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // allowances = parseFloat(allowances) !== 0 ? parseFloat(allowances).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // tips = parseFloat(tips) !== 0 ? parseFloat(tips).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // reimbursements = reimbursements && parseFloat(reimbursements) !== 0 ? parseFloat(reimbursements).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // taxWithheld = tax_withheld && parseFloat(tax_withheld) !== 0 ? parseFloat(tax_withheld).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // codaPlans = coda_plans_fund && parseFloat(coda_plans_fund) !== 0 ? parseFloat(coda_plans_fund).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // ssWages = ss_wages && parseFloat(ss_wages) !== 0 ? parseFloat(ss_wages).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // ssTaxWithheld = ssTaxWithheld !== 0 ? ssTaxWithheld.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // retirementFund = retirementFund !== 0 ? retirementFund.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // medicareWagesTips = medicareWagesTips !== 0 ? medicareWagesTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // medicareTaxWithheld = medicareTaxWithheld !== 0 ? medicareTaxWithheld.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // ssTips = ssTips !== 0 ? ssTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // uncollectedSsTips = uncollectedSsTips !== 0 ? uncollectedSsTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // uncollectedMedicareTips = uncollectedMedicareTips !== 0 ? uncollectedMedicareTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      return {
        _941pr: {
          value: this.get('w2Data.a') === "941pr" ? "X" : "",
          x: 95,
          y: 698,
          size: 10
        },
        _943pr: {
          value: this.get('w2Data.a') === "943pr" ? "X" : "",
          x: 123,
          y: 698,
          size: 10
        },
        _944sp: {
          value: this.get('w2Data.a') === "944sp" ? "X" : "",
          x: 153,
          y: 698,
          size: 10
        },
        domestico: {
          value: this.get('w2Data.kind_of_payer') === "domestico" ? "X" : "",
          x: 110,
          y: 650,
          size: 10
        },
        compPorEnfermedad: {
          value: this.get('w2Data.kind_of_payer') === "compPorEnfermedad" ? "X" : "",
          x: 153,
          y: 650,
          size: 10
        },
        soloEmpleadosMedicare: {
          value: this.get('w2Data.kind_of_payer') === "soloEmpleadosMedicare" ? "X" : "",
          x: 132,
          y: 615,
          size: 10
        },
        ein: {
          value: this.get('company.ein') || "",
          x: 65,
          y: 580,
          size: 10
        },
        nombre: {
          value: this.get('company.name') || "",
          x: 65,
          y: 545,
          size: 8
        },
        addressLineOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 65,
          y: 508,
          size: 8
        },
        addressLineTwo: {
          value: this.get('company.postal_Saddress_line_two') || "",
          x: 65,
          y: 495,
          size: 8
        },
        addressLineThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 65,
          y: 483,
          size: 10
        },
        contactPerson: {
          value: this.get('company.contact_name') || "",
          x: 54,
          y: 378,
          size: 8
        },
        phone: {
          value: this.get('company.phone') || "",
          x: 180,
          y: 378,
          size: 8
        },
        email: {
          value: this.get('company.email') || "",
          x: 435,
          y: 378,
          size: 8
        },
        w2Count: {
          value: this.get('w2Count').toString(),
          x: 249,
          y: 633,
          size: 10
        },
        wages: {
          value: this.get('wages') || "",
          x: 345,
          y: 687,
          size: 10
        },
        commissions: {
          value: this.get('commissions') || "",
          x: 345,
          y: 655,
          size: 10
        },
        allowances: {
          value: this.get('allowances') || "",
          x: 345,
          y: 620,
          size: 10
        },
        tips: {
          value: this.get('tips') || "",
          x: 345,
          y: 580,
          size: 10
        },
        total: {
          value: this.get('total') || "",
          x: 345,
          y: 548,
          size: 10
        },
        reimbursements: {
          value: this.get('reimbursements') || "",
          x: 345,
          y: 510,
          size: 10
        },
        taxWithheld: {
          value: this.get('taxWithheld') || "",
          x: 345,
          y: 475,
          size: 10
        },
        retirementFund: {
          value: this.get('retirementFund') || "",
          x: 355,
          y: 435,
          size: 10
        },
        codaPlans: {
          value: this.get('codaPlans') || "",
          x: 345,
          y: 407,
          size: 10
        },
        ssWages: {
          value: this.get('ssWages') || "",
          x: 458,
          y: 685,
          size: 10
        },
        ssTaxWithheld: {
          value: this.get('_11') || "",
          x: 458,
          y: 650,
          size: 10
        },
        // ssTaxWithheld: { value: this.get('ssTaxWithheld') || "", x: 458, y: 650, size: 10 },
        medicareWagesTips: {
          value: this.get('medicareWagesTips') || "",
          x: 458,
          y: 615,
          size: 10
        },
        medicareTaxWithheld: {
          value: this.get('medicareTaxWithheld') || "",
          x: 458,
          y: 535,
          size: 10
        },
        ssTips: {
          value: this.get('ssTips') || "",
          x: 458,
          y: 500,
          size: 10
        },
        // ssTips: { value: this.get('ssTips') || "", x: 458, y: 500, size: 10 },
        uncollectedSsTips: {
          value: this.get('uncollectedSsTips') || "",
          x: 458,
          y: 455,
          size: 10
        },
        uncollectedMedicareTips: {
          value: this.get('uncollectedMedicareTips') || "",
          x: 458,
          y: 410,
          size: 10
        },
        _12a: {
          value: this.get('total') || "X",
          x: 458,
          y: 620,
          size: 10
        }
      };
    },
    _499rPdfCoordinates: function _499rPdfCoordinates() {
      // let w3Data = this.get('w2Data.totals');
      // let w2Data = this.get('w2Data')
      // let w2Count = 0
      // let totalExcemptSalaries = 0
      // for (let employeeId in this.get('w2Data')) {
      //   if (employeeId !== "id" && employeeId !== "electronicFilingNumber" && employeeId !== "totals" && (w2Data[employeeId].wages && parseFloat(w2Data[employeeId].wages) !== 0)) {
      //     w2Count += 1
      //     totalExcemptSalaries += parseFloat(w2Data[employeeId]["_16Value"] || 0) + parseFloat(w2Data[employeeId]["_17Value"] || 0) + parseFloat(w2Data[employeeId]["_18Value"] || 0)
      //   }
      // }
      // let wages = parseFloat(w3Data.wages) !== 0 ? parseFloat(w3Data.wages).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let commissions = parseFloat(w3Data.commissions) !== 0 ? parseFloat(w3Data.commissions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let allowances = parseFloat(w3Data.allowances) !== 0 ? parseFloat(w3Data.allowances).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let tips = parseFloat(w3Data.tips) !== 0 ? parseFloat(w3Data.tips).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let reimbursements = w3Data.reimbursements && parseFloat(w3Data.reimbursements) !== 0 ? parseFloat(w3Data.reimbursements).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let taxWithheld = w3Data.tax_withheld && parseFloat(w3Data.tax_withheld) !== 0 ? parseFloat(w3Data.tax_withheld).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let codaPlans = w3Data.coda_plans_fund && parseFloat(w3Data.coda_plans_fund) !== 0 ? parseFloat(w3Data.coda_plans_fund).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : ""
      // let retirementFund = w3Data.government_retirement_fund && parseFloat(w3Data.government_retirement_fund) !== 0 ? parseFloat(w3Data.government_retirement_fund).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // totalExcemptSalaries = totalExcemptSalaries.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      // let saveAndDoubleMoneyProgram = w3Data.save_and_double_money_program && parseFloat(w3Data.save_and_double_money_program) !== 0 ? parseFloat(w3Data.save_and_double_money_program).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      // let total = ((w3Data.wages ? parseFloat(w3Data.wages) : 0) +
      //             (w3Data.commissions ? parseFloat(w3Data.commissions) : 0) +
      //             (w3Data.allowances ? parseFloat(w3Data.allowances) : 0) +
      //             (w3Data.tips ? parseFloat(w3Data.tips) : 0)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return _defineProperty({
        ein: {
          value: this.get('company.ein') || "",
          x: 30,
          y: 630,
          size: 10
        },
        companyName: {
          value: this.get('company.name') || "",
          x: 30,
          y: 588,
          size: 10
        },
        contactName: {
          value: this.get('company.contact_name') || "",
          x: 30,
          y: 539,
          size: 10
        },
        postalAddressLineOne: {
          value: this.get('company.postal_address_line_one') || "",
          x: 30,
          y: 509,
          size: 10
        },
        postalAddressLineTwo: {
          value: this.get('company.postal_address_line_two') || "",
          x: 30,
          y: 499,
          size: 10
        },
        postalAddressLineThree: {
          value: "".concat(this.get('company.postal_address_city') || "", " ").concat(this.get('company.postal_address_state') || "", " ").concat(this.get('company.postal_address_country') || "", " ").concat(this.get('company.postal_address_zip') || ""),
          x: 30,
          y: 489,
          size: 10
        },
        physicalAddressLineOne: {
          value: this.get('company.physical_address_line_one') || "",
          x: 30,
          y: 459,
          size: 10
        },
        physicalAddressLineTwo: {
          value: this.get('company.physical_address_line_two') || "",
          x: 30,
          y: 449,
          size: 10
        },
        physicalAddressLineThree: {
          value: "".concat(this.get('company.physical_address_city') || "", " ").concat(this.get('company.physical_address_state') || "", " ").concat(this.get('company.physical_address_country') || "", " ").concat(this.get('company.physical_address_zip') || ""),
          x: 30,
          y: 439,
          size: 10
        },
        comienzoFechaDia: {
          value: "01",
          x: 214,
          y: 682,
          size: 8
        },
        comienzoFechaMes: {
          value: "01",
          x: 242,
          y: 682,
          size: 8
        },
        comienzoFechaAno: {
          value: "19",
          x: 281,
          y: 682,
          size: 8
        },
        terminaFechaDia: {
          value: "31",
          x: 328,
          y: 682,
          size: 8
        },
        terminaFechaMes: {
          value: "12",
          x: 356,
          y: 682,
          size: 8
        },
        terminaFechaAno: {
          value: "19",
          x: 395,
          y: 682,
          size: 8
        },
        phone: {
          value: this.get('company.phone') || "",
          x: 303,
          y: 627,
          size: 10
        },
        operationBeginDate: {
          value: this.get('company.incorporation_date') || "",
          x: 327,
          y: 582,
          size: 10
        },
        operationTerminationDate: {
          value: this.get('company.termination_date'),
          x: 327,
          y: 537,
          size: 10
        },
        w2Count: {
          value: this.get('w2Count').toString() || "",
          x: 322,
          y: 449,
          size: 10
        },
        numConfirmacionElectronica: {
          value: this.get('w2Data.electronicFilingNumber') || "",
          x: 425,
          y: 705,
          size: 10
        },
        // wages: { value: this.get('wages') || "", x: 482, y: 411, size: 10 },
        wages: {
          value: this.get('wages_minus_excempt') || "",
          x: 482,
          y: 411,
          size: 10
        },
        commissions: {
          value: this.get('commissions') || "",
          x: 482,
          y: 386,
          size: 10
        },
        allowances: {
          value: this.get('allowances') || "",
          x: 482,
          y: 365,
          size: 10
        },
        tips: {
          value: this.get('tips') || "",
          x: 482,
          y: 340,
          size: 10
        },
        total: {
          value: this.get('total'),
          x: 482,
          y: 315,
          size: 10
        },
        reimbursements: {
          value: this.get('reimbursements') || "",
          x: 482,
          y: 294,
          size: 10
        },
        taxWithheld: {
          value: this.get('taxWithheld') || "",
          x: 482,
          y: 269,
          size: 10
        },
        retirementFund: {
          value: this.get('retirementFund') || "",
          x: 355,
          y: 248,
          size: 10
        },
        codaPlans: {
          value: this.get('codaPlans') || "",
          x: 482,
          y: 223,
          size: 10
        },
        totalExcemptSalaries: {
          value: this.get('totalExcemptSalaries') || "",
          x: 482,
          y: 202,
          size: 10
        },
        saveDoubleMoney: {
          value: this.get('saveAndDoubleMoneyProgram') || "",
          x: 482,
          y: 181,
          size: 10
        }
      }, "total", {
        value: this.get('total') || "X",
        x: 482,
        y: 320,
        size: 10
      });
    },
    calculateSummaryTotals: function calculateSummaryTotals() {
      var w2Data = this.get('w2Data');
      var w2Count = 0;
      var wages = 0,
          commissions = 0,
          allowances = 0,
          tips = 0,
          reimbursements = 0,
          taxWithheld = 0,
          codaPlans = 0,
          ssWages = 0,
          ssTaxWithheld = 0,
          retirementFund = 0,
          medicareWagesTips = 0,
          medicareTaxWithheld = 0,
          ssTips = 0,
          uncollectedSsTips = 0,
          uncollectedMedicareTips = 0,
          totalExcemptSalaries = 0,
          saveAndDoubleMoneyProgram = 0,
          wages_minus_excempt = 0,
          _11 = 0;

      for (var employeeId in w2Data) {
        if (employeeId !== "id" && employeeId !== "electronicFilingNumber" && employeeId !== "totals" && w2Data[employeeId].wages && parseFloat(w2Data[employeeId].wages) !== 0) {
          w2Count += 1;
          var employee = w2Data[employeeId];
          wages += parseFloat(employee.wages);
          commissions += parseFloat(employee.commissions);
          allowances += parseFloat(employee.allowances);
          tips += parseFloat(employee.tips);
          reimbursements += employee.reimbursements ? parseFloat(employee.reimbursements) : 0;
          taxWithheld += employee.tax_withheld ? parseFloat(employee.tax_withheld) : 0;
          codaPlans += employee.coda_plans ? parseFloat(employee.coda_plans) : 0;
          ssWages += employee.ss_wages ? parseFloat(employee.ss_wages) : 0;
          ssTaxWithheld += employee.ss_tax_withheld ? parseFloat(employee.ss_tax_withheld) : 0;
          retirementFund += employee.government_retirement_fund ? parseFloat(employee.government_retirement_fund) : 0;
          medicareWagesTips += employee.medicare_wages_tips ? parseFloat(employee.medicare_wages_tips) : 0;
          medicareTaxWithheld += employee.medicare_tax_withheld ? parseFloat(employee.medicare_tax_withheld) : 0;
          ssTips += employee.ss_tips ? parseFloat(employee.ss_tips) : 0;
          uncollectedSsTips += employee.uncollected_ss_tips ? parseFloat(employee.uncollected_ss_tips) : 0;
          uncollectedMedicareTips += employee.uncollected_medicare_tips ? parseFloat(employee.uncollected_medicare_tips) : 0;
          saveAndDoubleMoneyProgram += employee.save_and_double_money_program ? parseFloat(employee.save_and_double_money_program) : 0;
          totalExcemptSalaries += parseFloat(employee["_16Value"] || 0) + parseFloat(employee["_17Value"] || 0) + parseFloat(employee["_18Value"] || 0);
        }
      }

      var total = ((wages ? parseFloat(wages) : 0) + (commissions ? parseFloat(commissions) : 0) + (allowances ? parseFloat(allowances) : 0) + (tips ? parseFloat(tips) : 0) // - (totalExcemptSalaries ? parseFloat(totalExcemptSalaries) : 0) Esto fue un CLIENT QUICK FIX.
      ).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      _11 = ((ssTaxWithheld !== 0 ? ssTaxWithheld : 0) + (ssTips !== 0 ? ssTips : 0)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      wages_minus_excempt = ((wages ? parseFloat(wages) : 0) - (totalExcemptSalaries ? parseFloat(totalExcemptSalaries) : 0)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      wages = parseFloat(wages) !== 0 ? parseFloat(wages).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      commissions = parseFloat(commissions) !== 0 ? parseFloat(commissions).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      allowances = parseFloat(allowances) !== 0 ? parseFloat(allowances).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      tips = parseFloat(tips) !== 0 ? parseFloat(tips).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      reimbursements = reimbursements && parseFloat(reimbursements) !== 0 ? parseFloat(reimbursements).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      taxWithheld = taxWithheld !== 0 ? taxWithheld.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      codaPlans = codaPlans !== 0 ? codaPlans.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      ssWages = ssWages !== 0 ? ssWages.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      ssTaxWithheld = ssTaxWithheld !== 0 ? ssTaxWithheld.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      retirementFund = retirementFund !== 0 ? retirementFund.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      medicareWagesTips = medicareWagesTips !== 0 ? medicareWagesTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      medicareTaxWithheld = medicareTaxWithheld !== 0 ? medicareTaxWithheld.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      ssTips = ssTips !== 0 ? ssTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      uncollectedSsTips = uncollectedSsTips !== 0 ? uncollectedSsTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      uncollectedMedicareTips = uncollectedMedicareTips !== 0 ? uncollectedMedicareTips.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      totalExcemptSalaries = totalExcemptSalaries.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      saveAndDoubleMoneyProgram += saveAndDoubleMoneyProgram !== 0 ? saveAndDoubleMoneyProgram.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : "";
      this.setProperties({
        'w2Count': w2Count,
        'wages': wages,
        'commissions': commissions,
        'allowances': allowances,
        'tips': tips,
        'reimbursements': reimbursements,
        'taxWithheld': taxWithheld,
        'codaPlans': codaPlans,
        'ssWages': ssWages,
        'ssTaxWithheld': ssTaxWithheld,
        'retirementFund': retirementFund,
        'medicareWagesTips': medicareWagesTips,
        'medicareTaxWithheld': medicareTaxWithheld,
        'ssTips': ssTips,
        'uncollectedSsTips': uncollectedSsTips,
        'uncollectedMedicareTips': uncollectedMedicareTips,
        'totalExcemptSalaries': totalExcemptSalaries,
        'saveAndDoubleMoneyProgram': saveAndDoubleMoneyProgram,
        'wages_minus_excempt': wages_minus_excempt,
        'total': total,
        '_11': _11
      });
    },
    company: Ember.computed('companyId', function () {
      return this.store.peekRecord('company', this.get('companyId'));
    }),
    w2pdfProcess: function w2pdfProcess(existingPdfDocBytes, _employeeId) {
      var _this5 = this;

      var _this = this;

      this.employeeSetup();
      var pdfDoc = PDFLib.PDFDocumentFactory.load(existingPdfDocBytes);

      var _pdfDoc$embedStandard = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard2 = _slicedToArray(_pdfDoc$embedStandard, 2),
          helveticaRef = _pdfDoc$embedStandard2[0],
          helveticaFont = _pdfDoc$embedStandard2[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var firstPage = pages[0];
      pages[0].addFontDictionary('Helvetica', helveticaRef);
      var w2Data = this.get('w2Data'); // Copy first page

      var copyPage = function copyPage(page) {
        var copiedPage = PDFLib.PDFPage.fromDict(PDFLib.PDFDictionary.from(new Map(page.map), page.index));

        if (page.getMaybe('Contents')) {
          page.normalizeContents();
          var originalContents = page.Contents;
          var copiedContents = PDFLib.PDFArray.fromArray(originalContents.array.slice(), page.index);
          copiedPage.set('Contents', copiedContents);
        }

        return copiedPage;
      };

      var newPageIndex = 0;

      if (_employeeId) {
        var newPage = copyPage(firstPage);
        pdfDoc.insertPage(newPageIndex, newPage);

        var _pages = pdfDoc.getPages();

        newPage = _pages[newPageIndex];
        var pdfData = this.w2PdfCoordinates(w2Data[_employeeId]);
        var pageKeys = Object.keys(pdfData);
        var contentStream;
        pageKeys.forEach(function (k) {
          contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(pdfData["".concat(k)].value ? pdfData["".concat(k)].value.toString() : ""), {
            x: pdfData["".concat(k)].x,
            y: pdfData["".concat(k)].y,
            size: pdfData["".concat(k)].size,
            font: 'Helvetica',
            colorRgb: pdfData["".concat(k)].color ? pdfData["".concat(k)].color : [0, 0, 1]
          }));
          newPage.addContentStreams(pdfDoc.register(contentStream));
        });
      } else {
        var _loop = function _loop() {
          if (employeeId !== "id" && employeeId !== "electronicFilingNumber" && employeeId !== "totals" && w2Data[employeeId].wages && parseFloat(w2Data[employeeId].wages) !== 0) {
            var _newPage = copyPage(firstPage);

            pdfDoc.insertPage(newPageIndex, _newPage);

            var _pages2 = pdfDoc.getPages();

            _newPage = _pages2[newPageIndex];

            var _pdfData = _this5.w2PdfCoordinates(w2Data[employeeId]);

            var _pageKeys = Object.keys(_pdfData);

            var _contentStream;

            _pageKeys.forEach(function (k) {
              _contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_pdfData["".concat(k)].value ? _pdfData["".concat(k)].value.toString() : ""), {
                x: _pdfData["".concat(k)].x,
                y: _pdfData["".concat(k)].y,
                size: _pdfData["".concat(k)].size,
                font: 'Helvetica',
                colorRgb: _pdfData["".concat(k)].color ? _pdfData["".concat(k)].color : [0, 0, 1]
              }));

              _newPage.addContentStreams(pdfDoc.register(_contentStream));
            });
          }
        };

        for (var employeeId in w2Data) {
          _loop();
        }
      }

      pdfDoc.removePage(pdfDoc.getPages().length - 5); //remove empty w2 page

      pdfDoc.removePage(pdfDoc.getPages().length - 1); //remove repeated w2 information pages 

      pdfDoc.removePage(pdfDoc.getPages().length - 2); //remove repeated w2 information pages 

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    w3pdfProcess: function w3pdfProcess(pdfDoc) {
      var _pdfDoc$embedStandard3 = pdfDoc.embedStandardFont(PDFLib.StandardFonts.Helvetica),
          _pdfDoc$embedStandard4 = _slicedToArray(_pdfDoc$embedStandard3, 2),
          helveticaRef = _pdfDoc$embedStandard4[0],
          helveticaFont = _pdfDoc$embedStandard4[1];

      this.set('helveticaRef', helveticaRef);
      this.set('helveticaFont', helveticaFont);
      var pages = pdfDoc.getPages();
      var secondPage = pages[1];
      var fourthPage = pages[3];
      pages[1].addFontDictionary('Helvetica', helveticaRef);
      pages[3].addFontDictionary('Helvetica', helveticaRef);
      this.calculateSummaryTotals();
      var w3pdfData = this.w3PdfCoordinates();
      var w3pageKeys = Object.keys(w3pdfData);
      var contentStream;
      w3pageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(w3pdfData["".concat(k)].value ? w3pdfData["".concat(k)].value.toString() : ""), {
          x: w3pdfData["".concat(k)].x,
          y: w3pdfData["".concat(k)].y,
          size: w3pdfData["".concat(k)].size,
          font: 'Helvetica',
          colorRgb: w3pdfData["".concat(k)].color ? w3pdfData["".concat(k)].color : [0, 0, 1]
        }));
        secondPage.addContentStreams(pdfDoc.register(contentStream));
      });

      var _499rPdfData = this._499rPdfCoordinates();

      var _499rPageKeys = Object.keys(_499rPdfData);

      _499rPageKeys.forEach(function (k) {
        contentStream = pdfDoc.createContentStream(PDFLib.drawText(helveticaFont.encodeText(_499rPdfData["".concat(k)].value ? _499rPdfData["".concat(k)].value.toString() : ""), {
          x: _499rPdfData["".concat(k)].x,
          y: _499rPdfData["".concat(k)].y,
          size: _499rPdfData["".concat(k)].size,
          font: 'Helvetica',
          colorRgb: _499rPdfData["".concat(k)].color ? _499rPdfData["".concat(k)].color : [0, 0, 1]
        }));
        fourthPage.addContentStreams(pdfDoc.register(contentStream));
      });

      this.get('applicationController').set('showLoading', false);
      var pdfBytes = PDFLib.PDFDocumentWriter.saveToBytes(pdfDoc);
      this.downloadPdf(pdfBytes);
    },
    employeeSetup: function employeeSetup() {
      var employees = this.get('w2Data');
      var count = 1;

      for (var employeeId in employees) {
        var employee = employees[employeeId];

        if (employee.general_info) {
          var birthYear = employee.general_info.birth_date ? employee.general_info.birth_date.split("-")[0] : null;
          var age = birthYear ? parseFloat(this.get('year')) - parseFloat(birthYear) : null;
          var countLength = count.toString().length;
          var controlNumber = this.get("w2Data.".concat(employeeId, ".control_number")) || "0".repeat(9 - countLength) + count;
          this.set("w2Data.".concat(employeeId, ".control_number"), controlNumber);

          if (age && age <= 26 && this.get("w2Data.".concat(employeeId, "._16Code")) != "E") {
            var wages = parseFloat(employee.wages || 0);
            var tips = parseFloat(employee.tips || 0);
            var value = 0;

            if (wages + tips > 40000) {
              value = 40000;

              if (wages > 40000) {
                value = 40000;
                var _wagesExcemption2 = value;
                this.set("w2Data.".concat(employeeId, ".wagesExcemption"), _wagesExcemption2);
              } else {
                var tipsExcemption = 40000 - wages;
                tips = wages + tips - 40000;
                this.set("w2Data.".concat(employeeId, ".tipsExcemption"), tipsExcemption);
                this.set("w2Data.".concat(employeeId, ".wagesExcemption"), wagesExcemption);
              }
            } else {
              this.set("w2Data.".concat(employeeId, ".tipsExcemption"), tips);
              this.set("w2Data.".concat(employeeId, ".wagesExcemption"), wages);
              value = wages + tips;
            }

            this.set("w2Data.".concat(employeeId, "._16Code"), "E");
            this.set("w2Data.".concat(employeeId, "._16Value"), value);
            this.set("w2Data.".concat(employeeId, ".tips"), tips);
          }

          count += 1;
        }
      }
    },
    electronicFiling: function electronicFiling(irs) {
      var _this6 = this;

      var data = JSON.stringify({
        w2: {
          w2_id: this.get('w2').id,
          company_id: this.companyId,
          irs: irs
        }
      });
      var ajaxOptions = {
        url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/w2s/electronic_filing"),
        method: 'POST',
        contentType: 'application/json',
        data: data,
        successCallback: function successCallback() {// this.get('applicationController').set('showLoading', true);
        },
        errorCallback: function errorCallback(e) {
          _this6.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
          });
        }
      };
      this.get('ajaxCall').request(ajaxOptions);
    },
    downloadPdf: function downloadPdf(data) {
      var blob = new Blob([data], {
        type: "application/pdf"
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob); // link.download = "myFileName.pdf";
      // link.click();

      window.open(link.href);
    },
    // getW2PDF: observer('w2Data', function() {
    getW2PDF: function getW2PDF(employeeId) {
      var _this7 = this;

      if (this.get('w2Data')) {
        fetch("https://trimestrales.s3.amazonaws.com/w2-".concat(this.get('year'), ".pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            _this7.w2pdfProcess(new Uint8Array(f), employeeId);
          });
        });
      }
    },
    getW3PDF: function getW3PDF() {
      var _this8 = this;

      if (this.get('w2Data')) {
        fetch("https://trimestrales.s3.amazonaws.com/fw3pr_".concat(this.get('year'), ".pdf")).then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var pdfDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));

            _this8.add499RPage(pdfDoc);
          });
        });
      }
    },
    add499RPage: function add499RPage(pdfDoc) {
      var _this9 = this;

      if (this.get('w2Data')) {
        fetch('https://trimestrales.s3.amazonaws.com/499_r-3.pdf').then(function (res) {
          var file = res.arrayBuffer();
          file.then(function (f) {
            var newDoc = PDFLib.PDFDocumentFactory.load(new Uint8Array(f));
            pdfDoc.addPage(newDoc.getPages()[0]);

            _this9.w3pdfProcess(pdfDoc);
          });
        });
      }
    },
    actions: {
      setYear: function setYear(year) {
        this.set('year', year);
        var w2 = this.get('model').findBy('year', year);

        if (w2) {
          this.set('w2Data', w2.get('json_data'));
          this.set('w2Data.id', w2.id);
          this.set('w2Data.electronicFillingNumber', w2.electronic_filing_number);
        }
      },
      setEditEmployee: function setEditEmployee(employeeId) {
        this.set('editEmployee', employeeId ? this.get("w2Data.".concat(employeeId)) : null);
      },
      saveW2s: function saveW2s(electronicFiling, irs) {
        var _this10 = this;

        var w2 = this.get('model').findBy('year', this.get('year'));

        if (w2) {
          this.store.findRecord('w2', w2.id).then(function (w2) {
            w2.setProperties({
              bso_user_id: _this10.get('w2Data.bso_user_id'),
              year: _this10.get('year'),
              electronic_filing_number: _this10.get('w2Data.electronicFilingNumber'),
              json_data: _this10.get('w2Data')
            });
            w2.save().then(function () {
              _this10.get('notify').success({
                html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>W2s Saved</span></div>'
              });

              _this10.set('w2', w2);

              if (electronicFiling) {
                _this10.electronicFiling(irs);
              }
            });
          });
        } else {
          var newW2 = this.store.createRecord('w2', {
            company_id: this.get('companyId'),
            bso_user_id: this.get('w2Data.bso_user_id'),
            electronic_filing_number: this.get('w2Data.electronicFilingNumber'),
            year: this.get('year'),
            json_data: this.get('w2Data')
          });
          newW2.save().then(function (w2) {
            _this10.get('notify').success({
              html: '<i class="icon-checkmark lg"></i> <div><strong>Success:</strong>&nbsp<span>W2s Saved</span></div>'
            });

            _this10.set('w2', w2);

            if (electronicFiling) {
              _this10.electronicFiling(irs);
            }
          });
        }
      },
      delete: function _delete() {
        var _this11 = this;

        var w2 = this.get('model').findBy('year', this.get('year'));
        w2.destroyRecord().then(function () {
          _this11.notify.alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(_this11.get('year'), " W2s Deleted</span></div>")
          });

          _this11.set('w2Data', null);

          _this11.set('year', null);

          _this11.send('refreshModel');

          (0, _jquery.default)('#w2YearSelect option')[0].selected = true;
        });
      },
      printEmployeeW2: function printEmployeeW2(employeeId) {
        this.getW2PDF(employeeId);
      },
      setHealthCoverageAccount: function setHealthCoverageAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#healthCoverageAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('healthCoverageAccounts').replace(index, 1, [accountId]);

          if (!this.get('healthCoverageAccounts').includes(undefined)) {
            this.get('healthCoverageAccounts').pushObject(undefined);
            (0, _jquery.default)("#healthCoverageAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeHealthCoverageAccount: function removeHealthCoverageAccount(index) {
        if (this.get('healthCoverageAccounts').length === 1) {
          this.get('healthCoverageAccounts').replace(index, 1, [undefined]);
          (0, _jquery.default)("#healthCoverageAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('healthCoverageAccounts').replace(index, 1, []);
        }
      },
      setCharitableContributionsAccount: function setCharitableContributionsAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#charitableContributionsAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('charitableContributionsAccounts').replace(index, 1, [accountId]);

          if (!this.get('charitableContributionsAccounts').includes(undefined)) {
            this.get('charitableContributionsAccounts').pushObject(undefined);
            (0, _jquery.default)("#charitableContributionsAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeCharitableContributionsAccount: function removeCharitableContributionsAccount(index) {
        if (this.get('charitableContributionsAccounts').length === 1) {
          this.get('charitableContributionsAccounts').replace(index, 1, [undefined]);
          (0, _jquery.default)("#charitableContributionsAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('charitableContributionsAccounts').replace(index, 1, []);
        }
      },
      setGovernmentRetirementFundAccount: function setGovernmentRetirementFundAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#governmentRetirementFundAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('governmentRetirementFundAccounts').replace(index, 1, [accountId]);

          if (!this.get('governmentRetirementFundAccounts').includes(undefined)) {
            this.get('governmentRetirementFundAccounts').pushObject(undefined);
            (0, _jquery.default)("#governmentRetirementFundAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeGovernmentRetirementFundAccount: function removeGovernmentRetirementFundAccount(index) {
        if (this.get('governmentRetirementFundAccounts').length === 1) {
          this.get('governmentRetirementFundAccounts').replace(index, 1, [undefined]);
          (0, _jquery.default)("#governmentRetirementFundAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('governmentRetirementFundAccounts').replace(index, 1, []);
        }
      },
      setCodaPlansAccount: function setCodaPlansAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#codaPlansAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('codaPlansAccounts').replace(index, 1, [accountId]);

          if (!this.get('codaPlansAccounts').includes(undefined)) {
            this.get('codaPlansAccounts').pushObject(undefined);
            (0, _jquery.default)("#codaPlansAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeCodaPlansAccount: function removeCodaPlansAccount(index) {
        if (this.get('codaPlansAccounts').length === 1) {
          this.get('codaPlansAccounts').replace(index, 1, [undefined]);
          (0, _jquery.default)("#codaPlansAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('codaPlansAccounts').replace(index, 1, []);
        }
      },
      setSaveAndDoubleMoneyProgramAccount: function setSaveAndDoubleMoneyProgramAccount(accountId, index) {
        if (this.get('inputAccounts').includes(accountId)) {
          this.get('notify').alert({
            html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>This Account was already selected, please choose a different one</span></div>"
          });
          (0, _jquery.default)("#saveAndDoubleMoneyProgramAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('saveAndDoubleMoneyProgramAccounts').replace(index, 1, [accountId]);

          if (!this.get('saveAndDoubleMoneyProgramAccounts').includes(undefined)) {
            this.get('saveAndDoubleMoneyProgramAccounts').pushObject(undefined);
            (0, _jquery.default)("#saveAndDoubleMoneyProgramAccount-".concat(index, " option"))[0].selected = true;
          }
        }
      },
      removeSaveAndDoubleMoneyProgramAccount: function removeSaveAndDoubleMoneyProgramAccount(index) {
        if (this.get('saveAndDoubleMoneyProgramAccounts').length === 1) {
          this.get('saveAndDoubleMoneyProgramAccounts').replace(index, 1, [undefined]);
          (0, _jquery.default)("#saveAndDoubleMoneyProgramAccount-".concat(index, " option"))[0].selected = true;
        } else {
          this.get('saveAndDoubleMoneyProgramAccounts').replace(index, 1, []);
        }
      },
      setCode: function setCode(employeeId, valueKey) {
        var value = (0, _jquery.default)("#code".concat(valueKey, "select-").concat(employeeId))[0].value;
        this.set("w2Data.".concat(employeeId, "._").concat(valueKey, "Code"), value);
      },
      calculate: function calculate() {
        var _this12 = this;

        var inputAccounts = {
          health_coverage_accounts: this.get('healthCoverageAccounts').filter(function (a) {
            return a;
          }),
          charitable_contributions_accounts: this.get('charitableContributionsAccounts').filter(function (a) {
            return a;
          }),
          government_retirement_fund_accounts: this.get('governmentRetirementFundAccounts').filter(function (a) {
            return a;
          }),
          coda_plans_accounts: this.get('codaPlansAccounts').filter(function (a) {
            return a;
          }),
          save_and_double_money_program_accounts: this.get('saveAndDoubleMoneyProgramAccounts').filter(function (a) {
            return a;
          })
        };
        var data = JSON.stringify({
          w2: {
            year: this.get('year'),
            input_accounts: inputAccounts
          }
        });
        var ajaxOptions = {
          url: "".concat(_environment.default.apiURL, "/companies/").concat(this.companyId, "/w2s/calculate"),
          method: 'POST',
          contentType: 'application/json',
          data: data,
          successCallback: function successCallback(response) {
            _this12.get('applicationController').set('showLoading', true); // let channel = this.get('session.pusher').subscribe(`w2-channel-${this.get('session.data.authenticated.id')}`);


            _this12.get('channel').bind('calculate', function (response) {
              _this12.store.findRecord('json-storage', response.json_storage_id).then(function (jsonStorage) {
                _this12.set('w2Data', jsonStorage.get('stored_json.w2'));

                _this12.employeeSetup();

                _this12.get('applicationController').set('showLoading', false);
              });
            }); // this.set('w2Data', response);
            // this.employeeSetup();

          },
          errorCallback: function errorCallback(e) {
            _this12.get('notify').alert({
              html: "<i class=\"icon-warning lg\"></i> <div class=\"message-text\"><span>".concat(e.payload.errors, "</span></div>")
            });
          }
        };
        this.get('ajaxCall').request(ajaxOptions);
      },
      generateW2: function generateW2() {
        this.send('saveW2s');
        this.getW2PDF();
      },
      generateW3: function generateW3() {
        this.send('saveW2s');
        this.getW3PDF();
      },
      sortEmployees: function sortEmployees(sortBy) {
        this.toggleProperty('sortAscending');
        this.set('sortBy', sortBy);
      }
    }
  });

  _exports.default = _default;
});